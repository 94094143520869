import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";
import { useIntl } from "react-intl";

import Select from "../../shared/InputElements/Select";

const SelectHouseholdSize = ({
  id = "household-size",
  label = "Household Size",
  ...rest
}) => {
  const userPrefs = useContext(UserPrefsContext);

  const intl = useIntl();

  return (
    <Select
      id={id}
      value={userPrefs.get("householdSize").toString()}
      label={label}
      optionNames={["1", "2", "3", "4", "5", "6", "7", "8"]}
      optionValues={[1, 2, 3, 4, 5, 6, 7, 8]}
      tooltip={
        intl.formatMessage
          ? intl.formatMessage({
              id: "incentives.householdIncomeTooltip",
              defaultMessage:
                "Include all members of your household (spouse, dependents, etc). Used to determine incentive eligibility. Few to no incentives mention households larger than 8 members, so select 8 if your family is any larger.",
            })
          : "Include all members of your household (spouse, dependents, etc). Used to determine incentive eligibility. Few to no incentives mention households larger than 8 members, so select 8 if your family is any larger."
      }
      handler={(e) => userPrefs.set({ householdSize: e.target.value })}
    />
  );
};

export default SelectHouseholdSize;

SelectHouseholdSize.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  tooltip: PropTypes.string
};
