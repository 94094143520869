import React, { useEffect, useContext, useState } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";
import Select from "../../shared/InputElements/Select";
import sortEVs from "../../../functions/vehicle/Sort/sortEVs"
import ToolTip from "../../shared/ToolTip/ToolTip";
import { useIntl} from 'react-intl';

const getVehicleInfoFromHandle = (vehicleHandle, electricVehicles, infoType, userSetVehicleMSRP, vehicleBoughtAs) => {
       if (vehicleHandle && electricVehicles) {
              const vehicle = electricVehicles.find(vehicle => vehicle.handle === vehicleHandle);
              if (vehicle) {
                     switch (infoType) {
                            case "manufacturer":
                                   return vehicle.make;
                            case "purchasePrice":
                                   if(vehicleBoughtAs === "used" && userSetVehicleMSRP) {
                                          return '$' + userSetVehicleMSRP.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                   } else if(vehicleBoughtAs === "new") {
                                          return '$' + vehicle.msrp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                   } else {
                                          return '$' + vehicle.msrp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                   }
                            default:
                                   return null;
                            }
           }
       }
       return null;
}

const arraysAreEqual = (array1, array2) => {
       if (array1.length !== array2.length) {
           return false;
       }
       for (let i = 0; i < array1.length; i++) {
           if (array1[i] !== array2[i]) {
               return false;
           }
       }
       return true;
};

const SelectVehicleIdForIncentives = ({
  electricVehicles,
  usedElectricVehicles,
  id = "select-vehicle-id-for-incentives",
  label = "Which vehicle do you plan to purchase?",
  disabled = false,
  isLarge = false,
  vehicleMSRPRef,
  userSetVehicleMSRP,
  ...rest
}) => {
       const intl = useIntl();
       const userPrefs = useContext(UserPrefsContext);
       const allVehicles = electricVehicles.concat(usedElectricVehicles);
       const [vehicles, setVehicles] = useState(sortEVs(allVehicles, "alphabetical", "asc"));
       const [vehicleYear, setVehicleYear] = useState(userPrefs.get("vehicleYearFilter") || new Date().getFullYear());
       const [vehicleManufacturer, setVehicleManufacturer] = useState(getVehicleInfoFromHandle(userPrefs.get("vehicleHandleForIncentives"), vehicles, "manufacturer"));
       const [vehicleModel, setVehicleModel] = useState(userPrefs.get("vehicleHandleForIncentives") || null);
       const [vehicleBoughtAs, setVehicleBoughtAs] = useState(userPrefs.get("vehicleBoughtAs") || "new");
       const [vehicleMSRP, setVehicleMSRP] = useState(getVehicleInfoFromHandle(userPrefs.get("vehicleHandleForIncentives"), vehicles, "purchasePrice", userSetVehicleMSRP, vehicleBoughtAs));
       const defaultModelYearOptionLabel = intl.formatMessage ? intl.formatMessage({ id: "select.year", defaultMessage: "Select Year" }) : "Select Year";
       const modelYearLabel = intl.formatMessage ? intl.formatMessage({ id: "model.year", defaultMessage: "Model Year" }) : "Model Year";
       const currentYear = new Date().getFullYear();
       const startYear = currentYear + 1;
       const endYear = currentYear - 7;
       const vehicleYearOptions = [];
       for (let year = startYear; year >= endYear; year--) {
           vehicleYearOptions.push(year);
       }

       useEffect(() => {
              if (vehicleYear) {
                  const modelYearVehicles = vehicles.filter(vehicle => vehicle.model_year === vehicleYear);
                  if (!arraysAreEqual(modelYearVehicles, vehicles)) {
                      setVehicles(modelYearVehicles);
                  }
              }
       }, [vehicles, vehicleYear, electricVehicles]);

       const changeYear = (e) => {
              const vehicleYearFilterVal = e.target.value;
              setVehicleYear(vehicleYearFilterVal);
              userPrefs.set({
                     vehicleYearFilter: vehicleYearFilterVal,
              });
       }

       const defaultManufacturerOptionLabel = intl.formatMessage ? intl.formatMessage({ id: "select.make", defaultMessage: "Select Make"}) : "Select Make";
       const manufacturerLabel = intl.formatMessage ? intl.formatMessage({ id: "vehicle.make", defaultMessage: "Vehicle Make"}) : "Vehicle Make";
       const manufacturers = [...new Set(vehicles.map(vehicle => vehicle.make))];
       const changeManufacturer = (e) => {
              setVehicleManufacturer(e.target.value);
       }

       const defaultModelOptionLabel = intl.formatMessage ? intl.formatMessage({ id: "select.model", defaultMessage: "Select Model"}) : "Select Model";
       const modelLabel = intl.formatMessage ? intl.formatMessage({ id: "vehicle.model", defaultMessage: "Vehicle Model"}) : "Vehicle Model";
       let vehicleModelLabels = [];
       let vehicleModelValues = [];
       if(vehicleManufacturer) {
              vehicles.filter(vehicle => vehicle.make === vehicleManufacturer)
              .forEach(vehicle => {
                     vehicleModelLabels.push(vehicle.model + " " + vehicle.trim);
                     vehicleModelValues.push(vehicle.handle);
              });
       }
       const changeModel = (e) => {
              const vehicleHandle = e.target.value;
              setVehicleModel(vehicleHandle);
              userPrefs.set({
                     vehicleHandleForIncentives: vehicleHandle,
              });
       }

       const boughtAsLabel = intl.formatMessage ? intl.formatMessage({ id: "vehicle.boughtas", defaultMessage: "Bought As"}) : "Bought As";
       const newLabel = intl.formatMessage ? intl.formatMessage({ id: "vehicle.new", defaultMessage: "New"}) : "New";
       const usedLabel = intl.formatMessage ? intl.formatMessage({ id: "vehicle.used", defaultMessage: "Used"}) : "Used";
       let vehicleBoughtAsOptions = ["new", "used"];
       let vehicleBoughtAsLabels = [newLabel, usedLabel];
       const changeBoughtAs = (e) => {
              setVehicleBoughtAs(e.target.value);
              userPrefs.set({
                     vehicleBoughtAs: e.target.value,
              });
       }

       const purchasePriceLabel = intl.formatMessage ? intl.formatMessage({ id: "purchase.price", defaultMessage: "Purchase Price"}) : "Purchase Price";
       const changePurchasePrice = (value) => {
              let purchasePrice = value.replace(/\D/g, '');
              let purchaseValue = 0;
              if (purchasePrice !== "") {
                     purchaseValue = parseInt(purchasePrice, 10);
                     purchasePrice = '$' + purchaseValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              }
              setVehicleMSRP(purchasePrice);
       }

       return (
              <>
                     <div className="form-container flex mb-0">
                            <Select
                                   id={"vehicle-year-select"}
                                   value={vehicleYear}
                                   label={modelYearLabel}
                                   optionNames={[defaultModelYearOptionLabel, ...vehicleYearOptions]}
                                   optionValues={[null, ...vehicleYearOptions]}
                                   handler={changeYear}
                            />

                            <Select
                                   id={"vehicle-manufacture-select"}
                                   value={vehicleManufacturer}
                                   label={manufacturerLabel}
                                   optionNames={[defaultManufacturerOptionLabel, ...manufacturers]}
                                   optionValues={[null, ...manufacturers]}
                                   handler={changeManufacturer}
                            />

                            <Select
                                   id={"vehicle-model-select"}
                                   value={vehicleModel}
                                   label={modelLabel}
                                   optionNames={[defaultModelOptionLabel, ...vehicleModelLabels]}
                                   optionValues={[null, ...vehicleModelValues]}
                                   handler={changeModel}
                            />
                     </div>
                     <div className="form-container flex mb-0 input-col-2">
                            <Select
                                   id={"vehicle-boughtas-select"}
                                   value={vehicleBoughtAs}
                                   label={boughtAsLabel}
                                   optionNames={[...vehicleBoughtAsLabels]}
                                   optionValues={[...vehicleBoughtAsOptions]}
                                   handler={changeBoughtAs}
                            />

                            <div className="form-group spaced">
                                   <label>{purchasePriceLabel} <ToolTip
                                   message={intl.formatMessage({ id: "vehicle.purchasePriceTooltip", defaultMessage: "Expected purchase price should include all dealer costs or fees not required by law. It doesn't include costs or fees required by law, such as taxes or title and registration fees. Used to determine incentive eligibility of used vehicles."})}
                                   id="vehicle_purchase_price_tooltip"
                                   /></label>
                                   <div className="input-group">
                                          <input
                                                 ref={vehicleMSRPRef}
                                                 type="text"
                                                 id="vehicle-msrp-value"
                                                 value={vehicleMSRP}
                                                 onChange={(e) => changePurchasePrice(e.target.value)}
                                          />
                                   </div>
                            </div>
                     </div>
              </>
       );
};

export default SelectVehicleIdForIncentives;

SelectVehicleIdForIncentives.propTypes = {
  electricVehicles: PropTypes.array,
  id: PropTypes.string,
  description: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  isLarge: PropTypes.bool
};
