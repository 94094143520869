import React, {useState} from "react";
import PropTypes from "prop-types";
import Select from "../../shared/InputElements/Select";
import { useIntl} from 'react-intl';

const SelectVehicleYearFilter = ({
  id = "select-vehicle-age-filter",
  vehicleSelected,
  electricVehicles,
  car,
  setUsedEv,
  ...rest
}) => {
  const intl = useIntl();
const selectedVehicle = electricVehicles.find(ev => ev.handle === vehicleSelected);
const vehicleYearOptions = selectedVehicle.model_year;
  const label = intl.formatMessage ? intl.formatMessage({ id: "model.year", defaultMessage: "Model Year"}) : "Model Year"
  const [modelYear, setModelYear] = useState(selectedVehicle ? selectedVehicle.model_year : 2024);

  const setVehicleYear = (e) => {
       setModelYear(e.target.value);
  }

  return (
    <Select
      id={id}
      value={modelYear}
      label={label}
      optionNames={[vehicleYearOptions]}
      optionValues={[vehicleYearOptions]}
      handler={setVehicleYear}
    />
  );
};

export default SelectVehicleYearFilter;

SelectVehicleYearFilter.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
};
