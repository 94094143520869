const getClunkerIncentiveNames = (incentives, intl) => {
  if (!incentives && !incentives[0].hasOwnProperty("eligibility_factors") ) return null;
  
  let clunkerIncentives = [];
  try{
  clunkerIncentives = incentives.filter(incentive => {
    return incentive.eligibility_factors
      .map(factor => {
        return factor === "turn_in_clunker" ? true : null;
      })
      .includes(true);
  });

}finally{ 
  if (clunkerIncentives.length === 0) return (
       intl.formatMessage({ id: "certainIncentives", defaultMessage: "Certain incentives compensate you for retiring an existing vehicle, also known as a clunker."})
  );
  
  const clunkerIncentiveNames = clunkerIncentives.map(
    incentive => "'" + incentive.name + "'"
    );

  const incentiveNames = [
    clunkerIncentiveNames.slice(0, -1).join(", "),
    clunkerIncentiveNames.slice(-1)[0]
  ].join(clunkerIncentiveNames.length < 2 ? "" : " or ");
  return (
       intl.formatMessage({ id: "certainIncentivesExistingStart", defaultMessage: "Certain incentives compensate you for retiring an existing vehicle, also known as a clunker. Specifically, the "}) +
    incentiveNames + 
    intl.formatMessage({ id: "certainIncentivesExistingEnd", defaultMessage: " are available in your region. Eligibility requirements vary by incentive; please check program details."})
  );
  }
};

export default getClunkerIncentiveNames;
