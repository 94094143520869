import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext"

import Select from "../../shared/InputElements/Select"
import {useIntl} from 'react-intl';

const SelectVehicleAgeFilter = ({
  id = "select-vehicle-age-filter",
  vehicles,
  ...rest
}) => {
  const intl = useIntl()
  const userPrefs = useContext(UserPrefsContext);
  const label = intl.formatMessage ? intl.formatMessage({ id: "boughtAs", defaultMessage: "Bought As"}) : "Bought As"

  return (
    <Select
      id={id}
      value={userPrefs.get("vehicleAgeFilter")}
      label={label}
      optionNames={[
       intl.formatMessage ? intl.formatMessage({ id: "new", defaultMessage: "New"}) : "New",
       intl.formatMessage ? intl.formatMessage({ id: "used", defaultMessage: "Used"}) : "Used",
      ]}
      optionValues={["New", "Used"]}
      handler={(e) => userPrefs.set({ vehicleAgeFilter: e.target.value })}
    />
  );
};

export default SelectVehicleAgeFilter;

SelectVehicleAgeFilter.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
};
