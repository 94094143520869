const DEALERS = [
  {
    id: 1,
    access_code: "level-2",
    state: "HI",
    station_name: "Honolulu Buick GMC Cadillac",
    street_address: "2945 N Nimitz Hwy",
    station_phone: "808-800-4338",
    city: "Honolulu",
    zip: 96819,
    latitude: 21.33504,
    longitude: -157.90526,
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "1",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: 2,
    access_code: "level-2",
    state: "HI",
    station_name: "Honolulu Volkswagen",
    station_phone: "808-369-9016",
    street_address: "2881 N NIMITZ Hwy",
    city: "Honlulu",
    zip: 96819,
    latitude: 21.335159,
    longitude: -157.903152,
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "2",
    contact: "John Le",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "3",
    access_code: "level-2",
    state: "HI",
    station_name: "Honolulu Ford",
    station_phone: "808-824-3981",
    street_address: "1370 N King St.",
    city: "Honlulu",
    zip: 96819,
    latitude: 21.32893,
    longitude: -157.87057,
    num_level_1: "0",
    num_level_2: "1",
    contact: "John Le",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: 4,
    access_code: "level-2",
    state: "AK",
    station_name: "Anchorage BMW Mini",
    station_phone: "877-212-6352",
    street_address: "730 E. 5th Ave.",
    city: "Anchorage",
    zip: 99501,
    latitude: 61.21722,
    longitude: -149.86948,
    num_level_1: "0",
    num_level_2: "0",
    contact: "Winston Lloyd",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: 5,
    access_code: "level-2",
    state: "AK",
    station_name: "Anchorage Chevrolet",
    station_phone: "907-205-4776",
    street_address: "9100 Old Seward Hwy",
    city: "Anchorage",
    zip: 99515,
    latitude: 61.138047,
    longitude: -149.864216,
    num_level_1: "0",
    num_level_2: "1",
    contact: "Kenneth Walton",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: 6,
    access_code: "level-2",
    state: "AK",
    station_name: "Wasilla CJD",
    station_phone: "907-205-4755",
    street_address: "2891 E. Sun Mountain Ave.",
    city: "Wasilla",
    zip: 99654,
    latitude: 61.57217236,
    longitude: -149.37529,
    num_level_1: "0",
    num_level_2: "0",
    contact: "Matthew Knee",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: 7,
    access_code: "level-2",
    state: "AK",
    station_name: "Fairbanks Chev Buick GMC",
    station_phone: "907-202-7398",
    street_address: "3300 S Cushman St.",
    city: "Fairbanks",
    zip: 99701,
    latitude: 64.815505,
    longitude: -147.71075,
    num_level_1: "0",
    num_level_2: "0",
    contact: "Robert Kramer",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: 8,
    access_code: "level-2",
    state: "WA",
    station_name: "Seattle BMW",
    station_phone: "206-829-5004",
    street_address: "1002 Airport Way S",
    city: "Seattle",
    zip: 98134,
    latitude: 47.593549,
    longitude: -122.3247676,
    num_level_1: "0",
    num_level_2: "4",
    num_level_3: "2",
    contact: "Shannon Travis",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: 9,
    access_code: "level-2",
    state: "WA",
    station_name: "Spokane CJD Fiat",
    station_phone: "509-381-7460",
    street_address: "10701 N Newport Hwy",
    city: "Spokane",
    zip: 99218,
    latitude: 47.754624,
    longitude: -117.397271,
    num_level_1: "1",
    num_level_2: "0",
    contact: "Robert Depoto",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: 10,
    access_code: "level-2",
    state: "WA",
    station_name: "Spokane Chevrolet Cadillac",
    station_phone: "509-381-2970",
    street_address: "101 E. Montgomery Ave",
    city: "Spokane",
    zip: 99207,
    latitude: 47.678298,
    longitude: -117.408728,
    num_level_1: "2",
    num_level_2: "0",
    contact: "David Mortlock",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: 11,
    access_code: "level-2",
    state: "WA",
    station_name: "Spokane BMW",
    station_phone: "509-368-7839",
    street_address: "215 E. Montgomery Ave",
    city: "Spokane",
    zip: 99207,
    latitude: 47.6783,
    longitude: -117.40735,
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "1",
    contact: "Karl Peterson",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: 12,
    access_code: "level-2",
    state: "WA",
    station_name: "Tri-Cities CJD",
    station_phone: "509-591-0629",
    street_address: "7171 W Canal Dr.",
    city: "Kennewick",
    zip: 99336,
    latitude: 46.226737,
    longitude: -119.217317,
    num_level_1: "0",
    num_level_2: "1",
    contact: "Donald Stephens",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: 13,
    access_code: "level-2",
    state: "OR",
    station_name: "Portland Mercedes Smart",
    station_phone: "971-340-4573",
    street_address: "1605 SW Naito Pkwy",
    city: "Portland",
    zip: 97201,
    latitude: 45.511436,
    longitude: -122.675949,
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    contact: "Brandon Godbey",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: 14,
    access_code: "level-2",
    state: "OR",
    station_name: "Portland BMW",
    station_phone: "971-340-4590",
    street_address: "2001 SW Jefferson St",
    city: "Portland",
    zip: 97201,
    latitude: 45.518858,
    longitude: -122.695993,
    num_level_1: "0",
    num_level_2: "1",
    contact: "Robert Rogers",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: 15,
    access_code: "level-2",
    state: "OR",
    station_name: "Portland Cadillac",
    station_phone: "971-285-4180",
    street_address: "9141 SW Canyon Rd",
    city: "Portland",
    zip: 97225,
    latitude: 45.496399,
    longitude: -122.77026,
    num_level_1: "2",
    num_level_2: "1",
    num_level_3: "1",
    contact: "Eric Trueblood",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: 16,
    access_code: "level-2",
    state: "OR",
    station_name: "Portland CJD",
    station_phone: "503-336-9549",
    street_address: "633 NE 12th Ave",
    city: "Portland",
    zip: 97232,
    latitude: 45.527435,
    longitude: -122.653587,
    num_level_1: "0",
    num_level_2: "0",
    contact: "Scott Michael",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: 17,
    access_code: "level-2",
    state: "OR",
    station_name: "Beaverton Mercedes",
    station_phone: "971-340-4589",
    street_address: "9275 SW Canyon Rd",
    city: "Portland",
    zip: 97225,
    latitude: 45.495432,
    longitude: -122.772079,
    num_level_1: "0",
    num_level_2: "5",
    contact: "Noel Preston",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: 18,
    access_code: "level-2",
    state: "OR",
    station_name: "Oregon City Subaru",
    station_phone: "503-344-1338",
    street_address: "1404 Main St",
    city: "Oregon City",
    zip: 97045,
    latitude: 45.362649,
    longitude: -122.601308,
    num_level_1: "0",
    num_level_2: "3",
    num_level_3: "0",
    contact: "William Grenier",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: 19,
    access_code: "level-2",
    state: "OR",
    station_name: "Salem BMW",
    station_phone: "971-701-2709",
    street_address: "1151 Van Ness Ave NE",
    city: "Salem",
    zip: 97301,
    latitude: 44.971442,
    longitude: -123.018337,
    num_level_1: "1",
    num_level_2: "3",
    contact: "Christopher Wright",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: 20,
    access_code: "level-2",
    state: "OR",
    station_name: "Salem Volkswagen",
    station_phone: "503-400-6307",
    street_address: "3335 Del Webb Ave NE",
    city: "Salem",
    zip: 97301,
    latitude: 44.97285,
    longitude: -123.017525,
    num_level_1: "0",
    num_level_2: "3",
    num_level_3: "2",
    contact: "Gregory Bell",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: 21,
    access_code: "level-2",
    state: "OR",
    station_name: "Eugene CJD Fiat",
    station_phone: "541-225-5431",
    street_address: "2121 Martin Luther King Jr Blvd",
    city: "Eugene",
    zip: 97401,
    latitude: 44.05958,
    longitude: -123.0786,
    num_level_1: "0",
    num_level_2: "0",
    contact: "Matthew Sidman",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: 22,
    access_code: "level-2",
    state: "OR",
    station_name: "Eugene Nissan",
    station_phone: "541-225-5411",
    street_address: "2060 Martin Luther King Jr. Blvd.",
    city: "Eugene",
    zip: 97401,
    latitude: 44.05958,
    longitude: -123.081363,
    num_level_1: "0",
    num_level_2: "2",
    contact: "Marc Babcock",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: 23,
    access_code: "level-2",
    state: "OR",
    station_name: "Springfield Toyota",
    station_phone: "541-225-5452",
    street_address: "163 S 9th St.",
    city: "Springfield",
    zip: 97477,
    latitude: 44.045388,
    longitude: -123.01398,
    num_level_1: "0",
    num_level_2: "2",
    contact: "Greg Ziolkowski",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: 24,
    access_code: "level-2",
    state: "OR",
    station_name: "Roseburg Ford Lincoln",
    station_phone: "541-537-4643",
    street_address: "1650 NE Stephens St",
    city: "Roseburg",
    zip: 97470,
    latitude: 43.230005,
    longitude: -123.350758,
    num_level_1: "0",
    num_level_2: "1",
    contact: "Robert Johnson",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: 25,
    access_code: "level-2",
    state: "OR",
    station_name: "Roseburg CJD",
    station_phone: "541-378-4945",
    street_address: "1600 NE Airport Rd",
    city: "Roseburg",
    zip: 97470,
    latitude: 43.229156,
    longitude: -123.354292,
    num_level_1: "1",
    num_level_2: "0",
    contact: "Andrew Kohlhoff",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: 27,
    access_code: "level-2",
    state: "OR",
    station_name: "Medford Toyota",
    station_phone: "541-930-3026",
    street_address: "1420 N Riverside Ave.",
    city: "Medford",
    zip: 97501,
    latitude: 42.339931,
    longitude: -122.876952,
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "1",
    contact: "Mark Grossman",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: 28,
    access_code: "level-2",
    state: "OR",
    station_name: "Medford Honda",
    station_phone: "541-636-9956",
    street_address: "4095 Crater Lake Hwy",
    city: "Medford",
    zip: 97504,
    latitude: 42.375901,
    longitude: -122.855944,
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "1",
    contact: "Stephen Ferris",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: 31,
    access_code: "level-2",
    state: "OR",
    station_name: "Medford Ford Mazda",
    station_phone: "541-887-6176",
    street_address: "2611 Biddle Rd",
    city: "Medford",
    zip: 97504,
    latitude: 42.355533,
    longitude: -122.875641,
    num_level_1: "2",
    num_level_2: "1",
    contact: "Ron Lindsey",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: 32,
    access_code: "level-2",
    state: "OR",
    station_name: "Klamath Falls CJD",
    station_phone: "541-205-4486",
    street_address: "2675 Washburn Way",
    city: "Klamath Falls",
    zip: 97603,
    latitude: 42.20326,
    longitude: -121.757157,
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    contact: "Matthew Snipes",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: 33,
    access_code: "level-2",
    state: "OR",
    station_name: "Klamath Falls Ford",
    station_phone: "541-205-4501",
    street_address: "2833 Washburn Way",
    city: "Klamath Falls",
    zip: "97603",
    latitude: "42.201075",
    longitude: "-121.757153",
    num_level_1: "0",
    num_level_2: "1",
    contact: "Chris Bird",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "34",
    access_code: "level-2",
    state: "CA",
    station_name: "Redding Chevrolet",
    station_phone: "530-410-6002",
    street_address: "200 E. Cypress Ave",
    city: "Redding",
    zip: "96002",
    latitude: "40.571087",
    longitude: "-122.36621",
    num_level_1: "2",
    num_level_2: "0",
    contact: "Mathew Goodwin",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "35",
    access_code: "level-2",
    state: "CA",
    station_name: "Redding Toyota",
    station_phone: "530-605-2938",
    street_address: "250 E. Cypress Ave",
    city: "Redding",
    zip: "96002",
    latitude: "40.57251627",
    longitude: "-122.3669399",
    num_level_1: "1",
    num_level_2: "0",
    contact: "Michael Nightingale",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "36",
    access_code: "level-2",
    state: "CA",
    station_name: "Eureka CJD",
    station_phone: "877-554-1442",
    street_address: "4320 Broadway St.",
    city: "Eureka",
    zip: "95503",
    latitude: "40.766435",
    longitude: "-124.191589",
    num_level_1: "0",
    num_level_2: "1",
    contact: "John Neely",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "37",
    access_code: "level-2",
    state: "CA",
    station_name: "Santa Rosa CJD",
    station_phone: "707-800-6184",
    street_address: "2727 Dowd Dr.",
    city: "Santa Rosa",
    zip: "95407",
    latitude: "38.410812",
    longitude: "-122.719479",
    num_level_1: "0",
    num_level_2: "0",
    contact: "David Crawford",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "38",
    access_code: "level-2",
    state: "CA",
    station_name: "Lodi Toyota",
    station_phone: "209-642-4022",
    street_address: "1020 S Beckman Rd.",
    city: "Lodi",
    zip: "95240",
    latitude: "38.121221",
    longitude: "-121.256759",
    num_level_1: "0",
    num_level_2: "0",
    contact: "Mohsen Yasavolian",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "39",
    access_code: "level-2",
    state: "CA",
    station_name: "Clovis Nissan",
    station_phone: "559-549-9892",
    street_address: "370 W. Herndon Ave.",
    city: "Clovis",
    zip: "93612",
    latitude: "36.837511",
    longitude: "-119.716988",
    num_level_1: "0",
    num_level_2: "2",
    contact: "Howard Robinson",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "40",
    access_code: "level-2",
    state: "CA",
    station_name: "Fresno Ford Lincoln",
    station_phone: "559-777-6952",
    street_address: "195 E Auto Center Dr.",
    city: "Fresno",
    zip: "93710",
    latitude: "36.821886",
    longitude: " -119.786972",
    num_level_1: "0",
    num_level_2: "1",
    contact: "Kevin Cantrell",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "41",
    access_code: "level-2",
    state: "CA",
    station_name: "Fresno Nissan",
    station_phone: "559-475-6617",
    street_address: "5580 N. Blackstone Ave.",
    city: "Fresno",
    zip: "93710",
    latitude: "36.81882",
    longitude: " -119.790301",
    num_level_1: "1",
    num_level_2: "0",
    contact: "Larry Kirby",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "42",
    access_code: "level-2",
    state: "CA",
    station_name: "Lithia Hyundai of Fresno",
    street_address: "5590 N. Blackstone Ave.",
    city: "Fresno",
    zip: "93710",
    latitude: "36.818797",
    longitude: "119.790302",
    num_level_1: "0",
    num_level_2: "1",
    contact: "Matt Calderone",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "43",
    access_code: "level-2",
    state: "CA",
    station_name: "Fresno Subaru",
    station_phone: "559-438-6200",
    street_address: "5200 N. Blackstone Ave.",
    city: "Fresno",
    zip: "93710",
    latitude: "36.812715",
    longitude: "-119.79034",
    num_level_1: "2",
    num_level_2: "1",
    contact: "John Deorona",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "44",
    access_code: "level-2",
    state: "CA",
    station_name: "Monterey BMW",
    station_phone: "831-583-4900",
    street_address: "1 Geary Plaza",
    city: "Seaside",
    zip: "93955",
    latitude: "36.614156",
    longitude: "-121.843944",
    num_level_1: "3",
    num_level_2: "2",
    contact: "Jeremy Carter",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "45",
    access_code: "level-2",
    state: "CA",
    station_name: "Monterey Porsche",
    station_phone: "831-899-8888",
    street_address: "1781 Del Monte Blvd",
    city: "Seaside",
    zip: "93955",
    latitude: "36.615532",
    longitude: "-121.844273",
    num_level_1: "1",
    num_level_2: "3",
    num_level_3: "2",
    contact: "Nicholas Newton",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "46",
    access_code: "level-2",
    state: "CA",
    station_name: "Oxnard Toyota",
    station_phone: "877-513-4146",
    street_address: "1631 Auto Center Dr",
    city: "Oxnard",
    zip: "93036",
    latitude: "34.228193",
    longitude: "-119.153262",
    num_level_1: "2",
    num_level_2: "0",
    contact: "Lars Bumann / Christopher H Case",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "47",
    access_code: "level-2",
    state: "CA",
    station_name: "Oxnard Honda",
    station_phone: "805-204-4787",
    street_address: "1500 Ventura Blvd.",
    city: "Oxnard",
    zip: "93036",
    latitude: "34.228086",
    longitude: "-119.15808",
    num_level_1: "0",
    num_level_2: "3",
    contact: "Brian Lewey",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "48",
    access_code: "level-2",
    state: "CA",
    station_name: "Oxnard Audi",
    station_phone: "805-288-7728",
    street_address: "1600 Ventura Blvd.",
    city: "Oxnard",
    zip: "93036",
    latitude: "34.22392",
    longitude: "-119.152402",
    num_level_1: "1",
    num_level_2: "2",
    num_level_3: "0",
    contact: "Cesar Rojas",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "49",
    access_code: "level-2",
    state: "CA",
    station_name: "Thousand Oaks Ford",
    station_phone: "805-409-3895",
    street_address: "3810 Thousand Oaks Blvd",
    city: "Westlake Village",
    zip: "91362",
    latitude: "34.173679",
    longitude: "-118.842678",
    num_level_1: "2",
    num_level_2: "0",
    contact: "Daneel Wilson",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "50",
    access_code: "level-2",
    state: "CA",
    station_name: "Calabasas Audi",
    station_phone: "818-574-3211",
    street_address: "24650 Calabasas Rd.",
    city: "Calabasas",
    zip: "91302",
    latitude: "34.149589",
    longitude: "-118.663016",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    contact: "Kenneth Frakes",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "51",
    access_code: "level-2",
    state: "CA",
    station_name: "Gardena Honda",
    station_phone: "866-901-6250",
    street_address: "15541 S Western Ave",
    city: "Gardena",
    zip: "90249",
    latitude: "33.889217",
    longitude: "-118.309171",
    num_level_1: "0",
    num_level_2: "1",
    contact: "Michelle Villanueva",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "52",
    access_code: "level-2",
    state: "CA",
    station_name: "Torrance Toyota",
    station_phone: "424-271-2340",
    street_address: "2909 CA-1",
    city: "Torrance",
    zip: "90505",
    latitude: "33.795597",
    longitude: "-118.337954",
    num_level_1: "0",
    num_level_2: "2",
    contact: "Clara Serna / Gary Suit",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "53",
    access_code: "level-2",
    state: "CA",
    station_name: "Riverside Subaru",
    station_phone: "951-428-2314",
    street_address: "8069 Indiana Ave.",
    city: "Riverside",
    zip: "92504",
    latitude: "33.929368",
    longitude: "-117.414652",
    num_level_1: "1",
    num_level_2: "1",
    contact: "Matt Thornton",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "54",
    access_code: "level-2",
    state: "CA",
    station_name: "Temecula Acura",
    station_phone: "951-428-2317",
    street_address: "26705 Ynez Rd",
    city: "Temecula",
    zip: "92591",
    latitude: "33.514722",
    longitude: "-117.155373",
    num_level_1: "1",
    num_level_2: "0",
    contact: "Chad Sobek",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "55",
    access_code: "level-2",
    state: "CA",
    station_name: "Temecula Honda",
    station_phone: "951-268-9197",
    street_address: "26755 Ynez Rd.",
    city: "Temecula",
    zip: "92591",
    latitude: "33.514339",
    longitude: "-117.155213",
    num_level_1: "0",
    num_level_2: "1",
    contact: "Chris Johnson",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "56",
    access_code: "level-2",
    state: "CA",
    station_name: "Temecula Chrysler Jeep Dodge",
    station_phone: "951-225-4541",
    street_address: "26845 Ynez Rd.",
    city: "Temecula",
    zip: "92591",
    latitude: "33.513304",
    longitude: "-117.154377",
    num_level_1: "1",
    num_level_2: "0",
    contact: "Miguel Zermeno",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "57",
    access_code: "level-2",
    state: "CA",
    station_name: "Mission Valley Honda",
    station_phone: "619-567-3498",
    street_address: "5812 Mission Gorge Rd",
    city: "San Diego",
    zip: "92120",
    latitude: "32.781274",
    longitude: "-117.101325",
    num_level_1: "2",
    num_level_2: "1",
    contact: "Scott Bada",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "58",
    access_code: "level-2",
    state: "CA",
    station_name: "Mission Viejo Jaguar Land Rover",
    station_phone: "949-359-5159",
    street_address: "28701 Marguerite Pkway",
    city: "Mission Viejo",
    zip: "92692",
    latitude: "33.54587",
    longitude: " -117.671463",
    num_level_1: "2",
    num_level_2: "1",
    contact: "Jason DeRosa",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "59",
    access_code: "level-2",
    state: "CA",
    station_name: "Downtown LA Mercedes-Benz",
    station_phone: "213-425-3100",
    street_address: "1801 S Figueroa St.",
    city: "Los Angeles",
    zip: "90015",
    latitude: "34.03543",
    longitude: "-118.271349",
    num_level_1: "3",
    num_level_2: "1",
    num_level_3: "1",
    contact: "Timothy Endo / Ricardo Cotwright",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "60",
    access_code: "level-2",
    state: "CA",
    station_name: "Downtown LA Volkswagen",
    station_phone: "213-814-1994",
    street_address: "1900 S. Figueroa St.",
    city: "Los Angeles",
    zip: "90007",
    latitude: "34.033916",
    longitude: "-118.272366",
    num_level_1: "2",
    num_level_2: "2",
    num_level_3: "0",
    contact: "Curtis Rost",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "61",
    access_code: "level-2",
    state: "CA",
    station_name: "Downtown LA Audi",
    station_phone: "213-462-1796",
    street_address: "1900 S. Figueroa St.",
    city: "Los Angeles",
    zip: "90007",
    latitude: "34.033916",
    longitude: "-118.272366",
    num_level_1: "3",
    num_level_2: "1",
    contact: "Luis Soto",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "62",
    access_code: "level-2",
    state: "CA",
    station_name: "Downtown LA Porsche",
    station_phone: "213-455-5158",
    street_address: "1900 S. Figueroa St.",
    city: "Los Angeles",
    zip: "90007",
    latitude: "34.033916",
    longitude: "-118.272366",
    num_level_1: "2",
    num_level_2: "2",
    contact: "Vicki Carbone",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "63",
    access_code: "level-2",
    state: "CA",
    station_name: "Downtown LA Toyota",
    station_phone: "877-702-5632",
    street_address: "1901 S. Figuero St.",
    city: "Los Angeles",
    zip: "90007",
    latitude: "34.033918",
    longitude: "-118.272364",
    num_level_1: "0",
    num_level_2: "3",
    contact: "Christopher Cokonis / James Lee",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "64",
    access_code: "level-2",
    state: "CA",
    station_name: "Downtown LA Nissan",
    station_phone: "888-602-8064",
    street_address: "635 W Washington Blvd.",
    city: "Los Angeles",
    zip: "90015",
    latitude: "34.03501",
    longitude: "-118.273416",
    num_level_1: "0",
    num_level_2: "1",
    contact: "Lance Sobczak",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "65",
    access_code: "level-2",
    state: "CA",
    station_name: "Carson Nissan",
    station_phone: "310-507-1526",
    street_address: "1505 E. 223rd St",
    city: "Carson",
    zip: "90745",
    latitude: "33.82431",
    longitude: "-118.245712",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    contact: "Denise Lewis / John Nguyen",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "66",
    access_code: "level-2",
    state: "NV",
    station_name: "Reno CJ",
    station_phone: "775-501-8593",
    street_address: "1050 E. Plumb Ln. #100",
    city: "Reno",
    zip: "89502",
    latitude: "39.505814",
    longitude: "-119.786356",
    num_level_1: "2",
    num_level_2: "4",
    contact: "Dana Silva",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "67",
    access_code: "level-2",
    state: "ID",
    station_name: "Boise Ford Lincoln",
    station_phone: "208-398-2106",
    street_address: "8853 W. Fairview Ave.",
    city: "Boise",
    zip: "83704",
    latitude: "43.61945",
    longitude: " -116.293187",
    num_level_1: "4",
    num_level_2: "0",
    contact: "Ted Wesler",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "68",
    access_code: "level-2",
    state: "ID",
    station_name: "Twin Falls Chevrolet",
    station_phone: "208-922-7174",
    street_address: "1654 Blue Lakes Blvd No",
    city: "Twin Falls",
    zip: "83301",
    latitude: "42.591012",
    longitude: "-114.460119",
    num_level_1: "0",
    num_level_2: "0",
    contact: "Roger Ogden",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "69",
    access_code: "level-2",
    state: "ID",
    station_name: "Twin Falls CJD",
    station_phone: "208-293-8571",
    street_address: "1310 Pole Line Rd. E",
    city: "Twin Falls",
    zip: "83301",
    latitude: "42.591500",
    longitude: "-114.456985",
    num_level_1: "0",
    num_level_2: "0",
    contact: "Richard Parker",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "70",
    access_code: "level-2",
    state: "ID",
    station_name: "Pocatello CJD",
    station_phone: "208-904-3369",
    street_address: "633 Bullock St.",
    city: "Pocatello",
    zip: "83201",
    latitude: "42.910361",
    longitude: "-112.460621",
    num_level_1: "1",
    num_level_2: "0",
    num_level_3: "0",
    contact: "Aaron Morey",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "71",
    access_code: "level-2",
    state: "ID",
    station_name: "Idaho Falls Ford",
    station_phone: "208-413-9578",
    street_address: "980 W. Broadway St.",
    city: "Idaho Falls",
    zip: "83402",
    latitude: "43.494946",
    longitude: "-112.049207",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    contact: "Spencer Bowen",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "72",
    access_code: "level-2",
    state: "MT",
    station_name: "Great Falls CJD",
    station_phone: "406-205-3430",
    street_address: "4025 10th  Ave. S",
    city: "Great Falls",
    zip: "59405",
    latitude: "47.494006",
    longitude: "-111.236417",
    num_level_1: "0",
    num_level_2: "2",
    contact: "William Riter",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "73",
    access_code: "level-2",
    state: "MT",
    station_name: "Great Falls Honda",
    station_phone: "406-564-1133",
    street_address: "4900 10th Ave S.",
    city: "Great Falls",
    zip: "59405",
    latitude: "47.493821",
    longitude: "-111.224989",
    num_level_1: "1",
    num_level_2: "0",
    contact: "Mack Nidros",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "74",
    access_code: "level-2",
    state: "MT",
    station_name: "Helena Chevrolet Buick GMC",
    station_phone: "406-558-2346",
    street_address: "1501 Cedar St.",
    city: "Helena",
    zip: "59601",
    latitude: "46.606756",
    longitude: "-112.014606",
    num_level_1: "0",
    num_level_2: "1",
    contact: "Horald Mitton",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "75",
    access_code: "level-2",
    state: "TX",
    station_name: "Odessa CJD",
    station_phone: "432-614-0801",
    street_address: "2510 E. 8th St.",
    city: "Odessa",
    zip: "79761",
    latitude: "31.86368",
    longitude: "-102.334061",
    num_level_1: "0",
    num_level_2: "0",
    contact: "Rocky Barnes",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "76",
    access_code: "level-2",
    state: "TX",
    station_name: "Odessa Chevrolet",
    station_phone: "432-614-1705",
    street_address: "5020 John Ben Shepperd Pkwy",
    city: "Odessa",
    zip: "79762",
    latitude: "31.899715",
    longitude: "-102.336027",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "1",
    contact: "Abel Anchondo / Vince Carnovale",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "77",
    access_code: "level-2",
    state: "TX",
    station_name: "Midland Chevrolet",
    station_phone: "432-253-3352",
    street_address: "4100 W. Wall St.",
    city: "Midland",
    zip: "79703",
    latitude: "31.97275",
    longitude: "-102.120177",
    num_level_1: "2",
    num_level_2: "1",
    num_level_3: "1",
    contact: "Brandon Rivas",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "78",
    access_code: "level-2",
    state: "TX",
    station_name: "San Angelo Chevrolet",
    station_phone: "325-939-2492",
    street_address: "203 Bryant Blvd. N",
    city: "San Angelo",
    zip: "76903",
    latitude: "31.46376",
    longitude: "-100.445287",
    num_level_1: "1",
    num_level_2: "0",
    contact: "Timothy McFadin",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "79",
    access_code: "level-2",
    state: "TX",
    station_name: "Killeen Chevrolet",
    station_phone: "254-213-5397",
    street_address: "1802 E. Central Texas Expy",
    city: "Killeen",
    zip: "76541",
    latitude: "31.092316",
    longitude: "-97.725264",
    num_level_1: "2",
    num_level_2: "0",
    num_level_3: "1",
    contact: "Jeffrey Lisenbe",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "80",
    access_code: "level-2",
    state: "TX",
    station_name: "Bryan CJD Fiat",
    station_phone: "979-213-5361",
    street_address: "301 N. Earl Rudder Fwy",
    city: "Bryan",
    zip: "77802",
    latitude: "30.655149",
    longitude: "-96.322064",
    num_level_1: "1",
    num_level_2: "0",
    contact: "Kalin Dupper",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "81",
    access_code: "level-2",
    state: "TX",
    station_name: "Corpus Christi CJD",
    station_phone: "361-288-2510",
    street_address: "4313 S. Staples St.",
    city: "Corpus Christi",
    zip: "78411",
    latitude: "27.72207",
    longitude: "-97.376242",
    num_level_1: "1",
    num_level_2: "1",
    contact: "Rory Ashurst",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "82",
    access_code: "level-2",
    state: "TX",
    station_name: "Corpus Christi Ford Lincoln",
    station_phone: "361-792-2819",
    street_address: "3680 US-77",
    city: "Corpus Christi",
    zip: "78410",
    latitude: "27.84678",
    longitude: " -97.631376",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    contact: "David Byrne",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "83",
    access_code: "level-2",
    state: "TX",
    station_name: "Calallen CJDR",
    station_phone: "361-248-1055",
    street_address: "3688 I-69 Access Rd",
    city: "Corpus Christi",
    zip: "78410",
    latitude: "27.860932",
    longitude: "-97.627868",
    num_level_1: "0",
    num_level_2: "2",
    contact: "Matthew Armentor (A)",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "84",
    access_code: "level-2",
    state: "IA",
    station_name: "Ames Honda Nissan",
    station_phone: "515-817-1715",
    street_address: "2901 S. Duff Ave.",
    city: "Ames",
    zip: "50010",
    latitude: "41.997327",
    longitude: "-93.610192",
    num_level_1: "0",
    num_level_2: "0",
    contact: "Melvin Gawley / Jeffrey Searcy",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "85",
    access_code: "level-2",
    state: "IA",
    station_name: "Des Moines Audi",
    station_phone: "515-446-3583",
    street_address: "5190 Merle Hay Rd.",
    city: "Johnston",
    zip: "50131",
    latitude: "41.654662",
    longitude: " -93.697749",
    num_level_1: "4",
    num_level_2: "2",
    num_level_3: "0",
    contact: "John Meyers",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "86",
    access_code: "level-2",
    state: "IA",
    station_name: "Des Moines Mercedes Sprinter Smart",
    station_phone: "515-446-3587",
    street_address: "9993 Hickman Rd.",
    city: "Urbandale",
    zip: "50322",
    latitude: "41.61564",
    longitude: "-93.755429",
    num_level_1: "1",
    num_level_2: "1",
    num_level_3: "1",
    contact: "Ryan Parmerlee",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "87",
    access_code: "level-2",
    state: "IA",
    station_name: "Des Moines BMW",
    station_phone: "515-446-3660",
    street_address: "9631 Hickman Rd.",
    city: "Urbandale",
    zip: "50322",
    latitude: "41.615192",
    longitude: " -93.75331",
    num_level_1: "1",
    num_level_2: "0",
    contact: "Kiera Harris",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "89",
    access_code: "level-2",
    state: "NY",
    station_name: "Albany CJDR",
    station_phone: "518-704-6897",
    street_address: "926 Central Ave.",
    city: "Albany",
    zip: "12205",
    latitude: "42.6813",
    longitude: "-73.79143",
    num_level_1: "1",
    num_level_2: "0",
    contact: "Thomas Harpster",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "90",
    access_code: "level-2",
    state: "NY",
    station_name: "Utica Ford",
    station_phone: "315-370-1009",
    street_address: "5712 Horatio St.",
    city: "Utica",
    zip: "13502",
    latitude: "43.138295",
    longitude: "-75.216872",
    num_level_1: "2",
    num_level_2: "0",
    contact: "Billy Melendez",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "91",
    access_code: "level-2",
    state: "NY",
    station_name: "Utica BMW",
    station_phone: "315-864-7200",
    street_address: "5708 Horatio St.",
    city: "Utica",
    zip: "13502",
    latitude: "43.138121",
    longitude: "-75.217017",
    num_level_1: "0",
    num_level_2: "0",
    contact: "Billy Melendez",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "92",
    access_code: "level-2",
    state: "NY",
    station_name: "Yorkville Subaru",
    station_phone: "315-864-7909",
    street_address: "5036 Commercial Dr.",
    city: "Yorkville",
    zip: "13495",
    latitude: "43.107642",
    longitude: " -75.295671",
    num_level_1: "1",
    num_level_2: "1",
    num_level_3: "0",
    contact: "Michael Memery",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "94",
    access_code: "level-2",
    state: "NY",
    station_name: "Yorkville CJD",
    station_phone: "315-864-7230",
    street_address: "5017 Commercial Dr.",
    city: "Yorkville",
    zip: "13495",
    latitude: "43.106885",
    longitude: "-75.296072",
    num_level_1: "1",
    num_level_2: "0",
    contact: "William Crafton",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "95",
    access_code: "level-2",
    state: "NY",
    station_name: "Yorkville Hyundai",
    station_phone: "315-736-8356",
    street_address: "4975 Commercial Dr.",
    city: "Yorkville",
    zip: "13495",
    latitude: "43.104089",
    longitude: "-75.297803",
    num_level_1: "0",
    num_level_2: "1",
    contact: "Christopher Myers",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "96",
    access_code: "level-2",
    state: "NY",
    station_name: "Troy Subaru",
    station_phone: "315-864-7730",
    street_address: "800 Hoosick Rd.",
    city: "Troy",
    zip: "12180",
    latitude: "42.748761",
    longitude: "-73.636237",
    num_level_1: "2",
    num_level_2: "1",
    contact: "Marc Rosebeck",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "97",
    access_code: "level-2",
    state: "NY",
    station_name: "Orchard Park Honda",
    station_phone: "716-778-2142",
    street_address: "3590 Southwestern Blvd",
    city: "Orchard Park",
    zip: "14127",
    latitude: "42.784023",
    longitude: "-78.765805",
    num_level_1: "0",
    num_level_2: "1",
    contact: "John Cahalan",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "98",
    access_code: "level-2",
    state: "NY",
    station_name: "Utica Body Shop",
    station_phone: "315-736-1275",
    street_address: "5718 Horatio St.",
    city: "Utica",
    zip: "13502",
    latitude: "43.138355",
    longitude: "-75.216822",
    num_level_1: "1",
    num_level_2: "0",
    num_level_3: "0",
    contact: "Michael Hughes",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "99",
    access_code: "level-2",
    state: "NY",
    station_name: "Nanuet Honda",
    station_phone: "845-367-4380",
    street_address: "10 NY-304",
    city: "Nanuet",
    zip: "10954",
    latitude: "41.095314",
    longitude: "-73.997878",
    num_level_1: "0",
    num_level_2: "0",
    contact: "Dominick Garretson",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "100",
    access_code: "level-2",
    state: "NY",
    station_name: "Wappingers Falls Toyota",
    station_phone: "845-206-0521",
    street_address: "1349 U.S. 9",
    city: "Wappinger Falls",
    zip: "12590",
    latitude: "41.580468",
    longitude: " -73.90899",
    num_level_1: "0",
    num_level_2: "2",
    contact: "Benjamin Carlson",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "102",
    access_code: "level-2",
    state: "NJ",
    station_name: "Paramus Mercedes-Benz",
    station_phone: "844-769-7934",
    street_address: "755 NJ-17",
    city: "Paramus",
    zip: "7652",
    latitude: "40.971982",
    longitude: "-74.080415",
    num_level_1: "2",
    num_level_2: "3",
    num_level_3: "1",
    contact: "Mark Mickens",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "103",
    access_code: "level-2",
    state: "NJ",
    station_name: "Ramsey Toyota",
    station_phone: "844-838-7075",
    street_address: "1096 NJ-17",
    city: "Ramsey",
    zip: "7446",
    latitude: "41.06757",
    longitude: " -74.133934",
    num_level_1: "1",
    num_level_2: "1",
    contact: "Robert Teunisen",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "104",
    access_code: "level-2",
    state: "NJ",
    station_name: "Ramsey BMW",
    station_phone: "844-743-8279",
    street_address: "985 NJ-17",
    city: "Ramsey",
    zip: "7446",
    latitude: "41.065751",
    longitude: "-74.131758",
    num_level_1: "0",
    num_level_2: "4",
    num_level_3: "0",
    contact: "Anthony Circelli / Anthony Candela",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "105",
    access_code: "level-2",
    state: "NJ",
    station_name: "Ramsey MINI",
    station_phone: "551-777-5700",
    street_address: "925 NJ-17",
    city: "Ramsey",
    zip: "7446",
    latitude: "41.065322",
    longitude: "-74.131012",
    num_level_1: "2",
    num_level_2: "2",
    num_level_3: "0",
    contact: "Mark Mozdziez",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "106",
    access_code: "level-2",
    state: "NJ",
    station_name: "Brunswick Toyota",
    station_phone: "732-798-7936",
    street_address: "1504 US-1",
    city: "North Brunswick Township",
    zip: "08902",
    latitude: "40.456455",
    longitude: "-74.477898",
    num_level_1: "3",
    num_level_2: "1",
    contact: "Richard Mootz",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "107",
    access_code: "level-2",
    state: "NJ",
    station_name: "Millburn Audi",
    station_phone: "973-762-8500",
    street_address: "2211 Millburn Ave.",
    city: "Maplewood",
    zip: "07040",
    latitude: "40.72251",
    longitude: "-74.284338",
    num_level_1: "0",
    num_level_2: "2",
    contact: "Alan Palma",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "108",
    access_code: "level-2",
    state: "NJ",
    station_name: "Paramus Honda",
    station_phone: "201-730-2511",
    street_address: "120 NJ-4",
    city: "Paramus",
    zip: "7652",
    latitude: "40.923212",
    longitude: "-74.081903",
    num_level_1: "0",
    num_level_2: "2",
    contact: "Michael De Angelis / Bruce Laga",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "109",
    access_code: "level-2",
    state: "NJ",
    station_name: "Kay Honda",
    station_phone: "732-440-3264",
    street_address: "200 NJ-36",
    city: "Eatontown",
    zip: "07724",
    latitude: "40.29134",
    longitude: "-74.0431",
    num_level_1: "2",
    num_level_2: "2",
    contact: "Kenneth Kallok",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "110",
    access_code: "level-2",
    state: "NJ",
    station_name: "Bloomfield BMW",
    station_phone: "973-233-5147",
    street_address: "425 Bloomfield Ave.",
    city: "Bloomfield",
    zip: "07003",
    latitude: "40.788876",
    longitude: " -74.195101",
    num_level_1: "1",
    num_level_2: "0",
    contact: "Richard Hwang / Antonio Martins",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "111",
    access_code: "level-2",
    state: "NJ",
    station_name: "Freehold BMW",
    station_phone: "732-298-9052",
    street_address: "4225 U.S. 9",
    city: "Freehold Township",
    zip: "07728",
    latitude: "40.275643",
    longitude: "-74.294083",
    num_level_1: "0",
    num_level_2: "0",
    contact: "Frank Ghusson",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "112",
    access_code: "level-2",
    state: "NJ",
    station_name: "Hamilton Honda",
    station_phone: "609-528-2600",
    street_address: "655 US-130",
    city: "Hamilton Township",
    zip: "08691",
    latitude: "40.191959",
    longitude: "-74.647789",
    num_level_1: "0",
    num_level_2: "0",
    contact: "Roree Prentzler / John Doyle / Jose Velez / Javad Muhammad",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "113",
    access_code: "level-2",
    state: "WV",
    station_name: "Morgantown Chrysler Jeep Dodge Fiat",
    station_phone: "681-285-3430",
    street_address: "1733 Mileground Rd.",
    city: "Morgantown",
    zip: "26505",
    latitude: "39.644943",
    longitude: " -79.926822",
    num_level_1: "0",
    num_level_2: "4",
    num_level_3: "0",
    contact: "",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "114",
    access_code: "level-2",
    state: "PA",
    station_name: "Wexford Honda",
    station_phone: "724-940-2005",
    street_address: "10430 Perry Hwy",
    city: "Wexford",
    zip: "15090",
    latitude: "40.60702499",
    longitude: "-80.05002678",
    num_level_1: "0",
    num_level_2: "0",
    contact: "William Coughenour Jr",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "115",
    access_code: "level-2",
    state: "PA",
    station_name: "Cranberry Township Toyota",
    station_phone: "724-741-1111",
    street_address: "19045 Perry Hwy",
    city: "Mars",
    zip: "16046",
    latitude: "40.671096",
    longitude: " -80.09795",
    num_level_1: "0",
    num_level_2: "0",
    contact: "Shawn Crow",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "116",
    access_code: "level-2",
    state: "PA",
    station_name: "Monroeville Ford",
    station_phone: "412-307-4722",
    street_address: "3696 William Penn Hwy",
    city: "Monroeville",
    zip: "15146",
    latitude: "40.434622",
    longitude: "-79.792",
    num_level_1: "1",
    num_level_2: "2",
    contact: "Shawn Patterson",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "117",
    access_code: "level-2",
    state: "PA",
    station_name: "South Hills Subaru",
    station_phone: "724-655-7001",
    street_address: "3260 Washington Rd.",
    city: "McMurray",
    zip: "15317",
    latitude: "40.289629",
    longitude: " -80.111976",
    num_level_1: "1",
    num_level_2: "1",
    contact: "Kenneth Nutter",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "118",
    access_code: "level-2",
    state: "PA",
    station_name: "Apollo Moon Township VW",
    station_phone: "412-262-2495",
    street_address: "5252 University Blvd.",
    city: "Moon",
    zip: "15108",
    latitude: "40.526429",
    longitude: " -80.209258",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    contact: "Francis Tusa",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "119",
    access_code: "level-2",
    state: "FL",
    station_name: "Wesley Chapel Honda",
    station_phone: "813-929-7200",
    street_address: "27750 Wesley Chapel Blvd",
    city: "Wesley Chapel",
    zip: "33544",
    latitude: "28.237283",
    longitude: "-82.357543",
    num_level_1: "0",
    num_level_2: "0",
    contact: "William James Iii",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "122",
    access_code: "level-2",
    state: "OR",
    station_name: "Grants Pass CJD",
    street_address: "1421 NE 6th St.",
    city: "Grants Pass",
    zip: "97526",
    latitude: "42.453620",
    longitude: "-123.321940",
    num_level_1: "1",
    num_level_2: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "124",
    access_code: "level-2",
    state: "NV",
    station_name: "Reno Volkswagen",
    street_address: "1050 E. Plumb Ln. #110",
    city: "Reno",
    zip: "89502",
    latitude: "39.505760",
    longitude: "-119.786490",
    num_level_1: "1",
    num_level_2: "0",
    num_level_3: "1",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "125",
    access_code: "level-2",
    state: "MT",
    station_name: "Missoula CJD",
    street_address: "5001 Grizzly Ct.",
    city: "Missoula",
    zip: "59808",
    latitude: "46.91850888209482",
    longitude: "-114.07041299748728",
    num_level_1: "0",
    num_level_2: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "127",
    access_code: "level-2",
    state: "AK",
    station_name: "Wasilla Chevrolet",
    street_address: "3700 E. Parks Hwy",
    city: "Wasilla",
    zip: "99654",
    latitude: "61.571630",
    longitude: "-149.381830",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "3",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "129",
    access_code: "level-2",
    state: "NM",
    station_name: "Santa Fe CJD",
    street_address: "7401 Cerrillos Rd.",
    city: "Santa Fe",
    zip: "87507",
    latitude: "35.623830",
    longitude: "-106.028360",
    num_level_1: "4",
    num_level_2: "4",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "130",
    access_code: "level-2",
    state: "MT",
    station_name: "Helena CJD",
    street_address: "3401 US-12 E",
    city: "Helena",
    zip: "59601",
    latitude: "46.91850888209482",
    longitude: "-114.07041299748728",
    num_level_1: "1",
    num_level_2: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "131",
    access_code: "level-2",
    state: "ND",
    station_name: "Grand Forks Ford Lincoln",
    street_address: "2273 32nd Ave S.",
    city: "Grand Forks",
    zip: "58201",
    latitude: "47.887620",
    longitude: "-97.057600",
    num_level_1: "1",
    num_level_2: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "132",
    access_code: "level-2",
    state: "ND",
    station_name: "Grand Forks CJD",
    street_address: "2373 32nd Ave. S.",
    city: "Grand Forks",
    zip: "58201",
    latitude: "47.887700",
    longitude: "-97.059200",
    num_level_1: "0",
    num_level_2: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "133",
    access_code: "level-2",
    state: "OR",
    station_name: "Bend Chevrolet Cadillac",
    street_address: "345 NE 3rd St.",
    city: "Bend",
    zip: "97701",
    latitude: "44.053698",
    longitude: "-121.302523",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "1",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "134",
    access_code: "level-2",
    state: "MT",
    station_name: "Great Falls Subaru",
    street_address: "800 Central Ave.",
    city: "Great Falls",
    zip: "59401",
    latitude: "47.504940",
    longitude: "-111.293490",
    num_level_1: "0",
    num_level_2: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "135",
    access_code: "level-2",
    state: "PA",
    station_name: "Wexford Chevrolet Cadillac",
    street_address: "10430 Perry Hwy",
    city: "Wexford",
    zip: "15090",
    latitude: "40.60675",
    longitude: "-80.05177",
    num_level_1: "0",
    num_level_2: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "136",
    access_code: "level-2",
    state: "PA",
    station_name: "Zelienople Ford",
    street_address: "540 S. Main St.",
    city: "Zelienople",
    zip: "16063",
    latitude: "40.786729",
    longitude: "-80.135193",
    num_level_1: "1",
    num_level_2: "1",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "137",
    access_code: "level-2",
    state: "WV",
    station_name: "Morgantown Ford Lincoln",
    street_address: "501 Mary Jane Wood Circle",
    city: "Morgantown",
    zip: "26501",
    latitude: "39.629757",
    longitude: "-79.955946",
    num_level_1: "2",
    num_level_2: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "138",
    access_code: "level-2",
    state: "FL",
    station_name: "Tampa Honda",
    street_address: "11000 N. Florida Ave.",
    city: "Tampa",
    zip: "33612",
    latitude: "28.050269",
    longitude: "-82.459397",
    num_level_1: "0",
    num_level_2: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "139",
    access_code: "level-2",
    state: "TX",
    station_name: "Houston Honda",
    street_address: "18787 Northwest Fwy",
    city: "Houston",
    zip: "77065",
    latitude: "29.899808",
    longitude: "-95.598375",
    num_level_1: "1",
    num_level_2: "2",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "140",
    access_code: "level-2",
    state: "TX",
    station_name: "Clear Lake Honda",
    street_address: "2205 Gulf Fwy S.",
    city: "League City",
    zip: "77573",
    latitude: "29.482135",
    longitude: "-95.102014",
    num_level_1: "0",
    num_level_2: "2",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "141",
    access_code: "level-2",
    state: "OR",
    station_name: "Bend CDJR",
    street_address: "1865 NE Hwy 20",
    city: "Bend",
    zip: "97701",
    latitude: "44.055471",
    longitude: "-121.27625",
    num_level_1: "0",
    num_level_2: "1",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "142",
    access_code: "level-2",
    state: "OR",
    station_name: "Bend Nissan",
    street_address: "1835 US 20",
    city: "Bend",
    zip: "97701",
    latitude: "44.068272",
    longitude: "-121.302527",
    num_level_1: "1",
    num_level_2: "0",
    num_level_3: "1",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "143",
    access_code: "level-2",
    state: "CA",
    station_name: "San Francisco BMW",
    street_address: "1675 Howard St.",
    city: "San Francisco",
    zip: "94103",
    latitude: "37.77101",
    longitude: "-122.417347",
    num_level_1: "0",
    num_level_2: "20",
    num_level_3: "2",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "144",
    access_code: "level-2",
    state: "NY",
    station_name: "Utica Buick Cadillac GMC",
    street_address: "5700 Horatio St.",
    city: "Utica",
    zip: "13502",
    latitude: "43.136971",
    longitude: "-75.217963",
    num_level_1: "0",
    num_level_2: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "145",
    access_code: "level-2",
    state: "AZ",
    station_name: "Phoenix Toyota (Bell Road Toyota)",
    street_address: "2020 Bell Rd.",
    city: "Phoenix",
    zip: "85023",
    latitude: "33.640176",
    longitude: "-112.102936",
    num_level_1: "0",
    num_level_2: "1",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "146",
    access_code: "level-2",
    state: "CA",
    station_name: "Mission Hills Hyundai",
    street_address: "10240 Sepulveda Blvd.",
    city: "Mission Hills",
    zip: "91345",
    latitude: "34.256393",
    longitude: "-118.467248",
    num_level_1: "2",
    num_level_2: "2",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "147",
    access_code: "level-2",
    state: "CA",
    station_name: "Sherman Oaks Audi",
    street_address: "5239 Van Nuys Blvd.",
    city: "Sherman Oaks",
    zip: "91401",
    latitude: "34.165978",
    longitude: "-118.448707",
    num_level_1: "2",
    num_level_2: "3",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "148",
    access_code: "level-2",
    state: "CA",
    station_name: "Thousand Oaks Subaru",
    street_address: "3725 Automall Dr.",
    city: "Thousand Oaks",
    zip: "91362",
    latitude: "34.161651",
    longitude: "-118.830129",
    num_level_1: "1",
    num_level_2: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "149",
    access_code: "level-2",
    state: "CA",
    station_name: "Valencia Audi",
    street_address: "23923 Creekside Rd.",
    city: "Valencia",
    zip: "91355",
    latitude: "34.41883",
    longitude: "-118.553766",
    num_level_1: "2",
    num_level_2: "2",
    num_level_3: "2",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "150",
    access_code: "level-2",
    state: "CA",
    station_name: "Van Nuys Hyundai",
    street_address: "5746 Van Nuys Blvd.",
    city: "Sherman Oaks",
    zip: "91401",
    latitude: "34.175255",
    longitude: "-118.448709",
    num_level_1: "0",
    num_level_2: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "151",
    access_code: "level-2",
    state: "CA",
    station_name: "Van Nuys Lexus",
    street_address: "5905 Van Nuys Blvd.",
    city: "Sherman Oaks",
    zip: "91401",
    latitude: "34.17782",
    longitude: "-118.448712",
    num_level_1: "3",
    num_level_2: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "152",
    access_code: "level-2",
    state: "CA",
    station_name: "Van Nuys Mercedes-Benz",
    street_address: "5400 Van Nuys, Blvd.",
    city: "Sherman Oaks",
    zip: "91401",
    latitude: "34.168738",
    longitude: "-118.44871",
    num_level_1: "6",
    num_level_2: "1",
    num_level_3: "3",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "153",
    access_code: "level-2",
    state: "CA",
    station_name: "Van Nuys Toyota",
    street_address: "5855 Van Nuys Blvd.",
    city: "Los Angeles",
    zip: "91401",
    latitude: "34.177183",
    longitude: "-118.448713",
    num_level_1: "0",
    num_level_2: "4",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "154",
    access_code: "level-2",
    state: "MT",
    station_name: "Missoula Ford",
    street_address: "3943 Brooks St.",
    city: "Missoula",
    zip: "59804",
    latitude: "46.83494",
    longitude: "-114.044175",
    num_level_1: "0",
    num_level_2: "1",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "155",
    access_code: "level-2",
    state: "NJ",
    station_name: "Eatontown Ford",
    street_address: "67 NJ-36",
    city: "Eatontown",
    zip: "07724",
    latitude: "40.29123",
    longitude: "-74.048071",
    num_level_1: "0",
    num_level_2: "1",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "156",
    access_code: "level-2",
    state: "NY",
    station_name: "Latham Ford",
    street_address: "637 Columbia St Ext.",
    city: "Latham",
    zip: "12110",
    latitude: "42.76246",
    longitude: "-73.75386",
    num_level_1: "1",
    num_level_2: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "157",
    access_code: "level-2",
    state: "OR",
    station_name: "Beaverton Buick GMC",
    street_address: "9155 SW Canyon Rd.",
    city: "Portland",
    zip: "97225",
    latitude: "45.496024",
    longitude: "-122.770969",
    num_level_1: "2",
    num_level_2: "1",
    num_level_3: "1",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "158",
    access_code: "level-2",
    state: "TX",
    station_name: "Abilene Honda",
    street_address: "1399 S. Danville Dr.",
    city: "Abilene",
    zip: "79605",
    latitude: "34.428453",
    longitude: "-99.785924",
    num_level_1: "0",
    num_level_2: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "159",
    access_code: "level-2",
    state: "TX",
    station_name: "Dallas Honda",
    street_address: "5311 Lemmon Ave.",
    city: "Dallas",
    zip: "75209",
    latitude: "32.826075",
    longitude: "-96.822146",
    num_level_1: "1",
    num_level_2: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "160",
    access_code: "level-2",
    state: "TX",
    station_name: "Katy Honda",
    street_address: "21001 Katy Fwy.",
    city: "Katy",
    zip: "77450",
    latitude: "29.784823",
    longitude: "-95.7363",
    num_level_1: "0",
    num_level_2: "1",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "161",
    access_code: "level-2",
    state: "TX",
    station_name: "San Angelo CJD",
    street_address: "4310 Sherwood Way",
    city: "San Angelo",
    zip: "76901",
    latitude: "31.437106",
    longitude: "-100.4944991",
    num_level_1: "1",
    num_level_2: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "162",
    access_code: "level-2",
    state: "CA",
    station_name: "Sterling BMW Mini",
    street_address: "3000 West Coast Hwy.",
    city: "Newport Beach",
    zip: "92663",
    latitude: "33.621047",
    longitude: "-117.925709",
    num_level_1: "0",
    num_level_2: "6",
    num_level_3: "6",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "163",
    access_code: "level-2",
    state: "VA",
    station_name: "Sterling Rolls-Royce Lamborghini McLaren",
    street_address: "21826 Pacific Blvd.",
    city: "Sterling",
    zip: "20166",
    latitude: "39.01147",
    longitude: "-77.438199",
    num_level_1: "2",
    num_level_2: "6",
    num_level_3: "2",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "164",
    access_code: "level-2",
    state: "VT",
    station_name: "Bennington Ford Hyundai",
    street_address: "897 N. Bennington Rd.",
    city: "Bennington",
    zip: "05201",
    latitude: "42.910486",
    longitude: "-73.222069",
    num_level_1: "0",
    num_level_2: "2",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "165",
    access_code: "level-2",
    state: "FL",
    station_name: "Wesley Chapel Chevrolet",
    street_address: "26922 Wesley Chapel Blvd",
    city: "Wesley Chapel",
    zip: "33544",
    latitude: "28.232881",
    longitude: "-82.371064",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "2",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "166",
    access_code: "level-2",
    state: "FL",
    station_name: "Orlando Land Rover",
    street_address: "199 S. Lake Destiny Dr",
    city: "Orlando",
    zip: "32810",
    latitude: "28.617054",
    longitude: "-81.388566",
    num_level_1: "2",
    num_level_2: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "167",
    access_code: "level-2",
    state: "NY",
    station_name: "Yorkville Honda",
    street_address: "5009 Commercial Dr.",
    city: "Yorkville",
    zip: "13495",
    latitude: "43.106191",
    longitude: "-75.296443",
    num_level_1: "0",
    num_level_2: "2",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "168",
    access_code: "level-2",
    state: "OR",
    station_name: "Bend Honda",
    street_address: "2225 NE HWY 20",
    city: "Bend",
    zip: "97701",
    latitude: "44.05494",
    longitude: "-121.269231",
    num_level_1: "0",
    num_level_2: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "169",
    access_code: "level-2",
    state: "OR",
    station_name: "Klamath Falls Toyota",
    street_address: "2121 Washburn Way",
    city: "Klamath Falls",
    zip: "97603",
    latitude: "42.207595",
    longitude: "-121.757163",
    num_level_1: "1",
    num_level_2: "0",
    num_level_3: "2",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "170",
    access_code: "level-2",
    state: "OR",
    station_name: "Medford CJD",
    street_address: "4540 Grumman Dr",
    city: "Medford",
    zip: "97504",
    latitude: "42.378089",
    longitude: "-122.858823",
    num_level_1: "1",
    num_level_2: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "171",
    access_code: "level-2",
    state: "OR",
    station_name: "Lithia Building at the Commons",
    street_address: "148 N Riverside Ave",
    station_phone: "541-776-6401",
    city: "Medford",
    zip: "97501",
    latitude: "42.328500",
    longitude: "-122.871290",
    num_level_1: "0",
    num_level_2: "2",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "172",
    access_code: "level-2",
    state: "MI",
    station_name: "BMW of Ann Arbor",
    street_address: "501 Auto Mall Drive",
    city: "Ann Arbor",
    zip: "48103",
    latitude: "42.285870",
    longitude: "-83.811440",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "1",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "173",
    access_code: "level-2",
    state: "MI",
    station_name: "Suburban Chevrolet Cadillac (Ann Arbor)",
    street_address: "3515 Jackson Road",
    city: "Ann Arbor",
    zip: "48103",
    latitude: "42.284140",
    longitude: "-83.00960",
    num_level_1: "0",
    num_level_2: "6",
    num_level_3: "2",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "174",
    access_code: "level-2",
    state: "MI",
    station_name: "Suburban CDJR of Ann Arbor",
    street_address: "3900 Jackson Road",
    city: "Ann Arbor",
    zip: "48103",
    latitude: "42.285790",
    longitude: "-83.807010",
    num_level_1: "0",
    num_level_2: "2",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "175",
    access_code: "level-2",
    state: "MI",
    station_name: "Mercedes Benz of Ann Arbor",
    street_address: "570 Auto Mall Drive",
    city: "Ann Arbor",
    zip: "48103",
    latitude: "42.287580",
    longitude: "-83.813520",
    num_level_1: "0",
    num_level_2: "2",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "176",
    access_code: "level-2",
    state: "MI",
    station_name: "Suburban Chevrolet (Clinton)",
    street_address: "1070 W US-12",
    city: "Clinton",
    zip: "49236",
    latitude: "42.071890",
    longitude: "-84.010920",
    num_level_1: "1",
    num_level_2: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "177",
    access_code: "level-2",
    state: "MI",
    station_name: "Suburban CDJF of Farmington Hills",
    street_address: "38123 W 10 Mile Road",
    city: "Farmington Hills",
    zip: "48335",
    latitude: "42.467527",
    longitude: "-83.422708",
    num_level_1: "0",
    num_level_2: "1",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "178",
    access_code: "level-2",
    state: "MI",
    station_name: "Suburban Honda of Farmington Hills",
    street_address: "2510 0 Haggerty Road",
    city: "Farmington Hills",
    zip: "48335",
    latitude: "42.476552",
    longitude: "-83.434179",
    num_level_1: "2",
    num_level_2: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "179",
    access_code: "level-2",
    state: "MI",
    station_name: "Suburban Nissan of Farmington Hills",
    street_address: "37901 Grand River Avenue",
    city: "Farmington Hills",
    zip: "48335",
    latitude: "42.445128",
    longitude: "-83.324596",
    num_level_1: "3",
    num_level_2: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "180",
    access_code: "level-2",
    state: "MI",
    station_name: "Suburban Ford of Ferndale",
    street_address: "21600 Woodward Ave",
    city: "Ferndale",
    zip: "48220",
    latitude: "42.46427",
    longitude: "-83.136849",
    num_level_1: "0",
    num_level_2: "1",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "181",
    access_code: "level-2",
    state: "MI",
    station_name: "Suburban CDJR of Garden City",
    street_address: "32850 Ford Road",
    city: "Garden City",
    zip: "48135",
    latitude: "42.32591",
    longitude: "-83.364162",
    num_level_1: "0",
    num_level_2: "1",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "182",
    access_code: "level-2",
    state: "MI",
    station_name: "Suburban Cadillac of Plymouth",
    street_address: "40475 Ann Arbor Road",
    city: "Plymouth",
    zip: "48170",
    latitude: "42.35784",
    longitude: "-83.503755",
    num_level_1: "1",
    num_level_2: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "183",
    access_code: "level-2",
    state: "MI",
    station_name: "Suburban Ford of Sterling Heights",
    street_address: "40333 Van Dyke",
    city: "Sterling Heights",
    zip: "48313",
    latitude: "42.59157",
    longitude: "-83.030573",
    num_level_1: "0",
    num_level_2: "6",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "184",
    access_code: "level-2",
    state: "MI",
    station_name: "Suburban Cadillac of Troy",
    street_address: "1810 Maplelawn Drive",
    city: "Sterling Heights",
    zip: "48084",
    latitude: "42.55172",
    longitude: "-83.174302",
    num_level_1: "1",
    num_level_2: "1",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "185",
    access_code: "level-2",
    state: "MI",
    station_name: "Suburban CDJR of Troy",
    street_address: "1790 Maplelawn Drive",
    city: "Troy",
    zip: "48084",
    latitude: "42.55173",
    longitude: "-83.173725",
    num_level_1: "0",
    num_level_2: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "186",
    access_code: "level-2",
    state: "MI",
    station_name: "Troy Exotics - Bentley",
    street_address: "1755-A Maplelawn Drive",
    city: "Troy",
    zip: "48084",
    latitude: "42.55173",
    longitude: "-83.173725",
    num_level_1: "1",
    num_level_2: "1",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "187",
    access_code: "level-2",
    state: "MI",
    station_name: "Jaguar Land Rover Troy",
    street_address: "1815 Maplelawn Drive",
    city: "Troy",
    zip: "48084",
    latitude: "42.55172",
    longitude: "-83.174302",
    num_level_1: "0",
    num_level_2: "3",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "188",
    access_code: "level-2",
    state: "MI",
    station_name: "Suburban Nissan of Troy",
    street_address: "1800 Maplelawn Drive",
    city: "Troy",
    zip: "48084",
    latitude: "42.55172",
    longitude: "-83.174302",
    num_level_1: "3",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "189",
    access_code: "level-2",
    state: "MI",
    station_name: "Suburban Volvo Cars",
    street_address: "1795 Maplelawn Drive",
    city: "Troy",
    zip: "48084",
    latitude: "42.55172",
    longitude: "-83.174302",
    num_level_1: "0",
    num_level_2: "6",
    num_level_3: "2",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "190",
    access_code: "level-2",
    state: "MI",
    station_name: "Suburban Volkswagen of Troy",
    street_address: "1804 Maplelawn Drive",
    city: "Troy",
    zip: "48084",
    latitude: "42.55172",
    longitude: "-83.174302",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "1",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "191",
    access_code: "level-2",
    state: "MI",
    station_name: "Suburban Ford of Waterford",
    street_address: "6975 Highland Road",
    city: "Waterford",
    zip: "48327",
    latitude: "42.55172",
    longitude: "-83.174302",
    num_level_1: "0",
    num_level_2: "5",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "192",
    access_code: "level-2",
    state: "CA",
    station_name: "San Francisco MINI",
    street_address: "1675 Howard St.",
    city: "San Francisco",
    zip: "94103",
    latitude: "37.77101",
    longitude: "-122.417347",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "193",
    access_code: "level-2",
    state: "AL",
    station_name: "Subaru of Mobile",
    street_address: "1431 East Interstate 65 Service Rd S.",
    city: "Mobile",
    zip: "48327",
    latitude: "30.66104",
    longitude: "-88.12546",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "194",
    access_code: "level-2",
    state: "AK",
    station_name: "Anchorage CJD",
    street_address: "9600 Old Seward Hwy",
    city: "Anchorage",
    zip: "99515",
    latitude: "61.133620",
    longitude: "-149.864800",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "195",
    access_code: "level-2",
    state: "AK",
    station_name: "Anchorage Hyundai",
    street_address: "5400 Old Seward Hwy",
    city: "Anchorage",
    zip: "99515",
    latitude: "61.17199175",
    longitude: "-149.8685514",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "196",
    access_code: "level-2",
    state: "IA",
    station_name: "Des Moines Volkswagen",
    street_address: "5200 Merle Hay Rd.",
    city: "Johnston",
    zip: "50131",
    latitude: "41.65533",
    longitude: "-93.69828",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "197",
    access_code: "level-2",
    state: "TX",
    station_name: "Rockwall Honda",
    street_address: "1550 E Interstate Hwy 30",
    city: "Rockwall",
    zip: "75087",
    latitude: "32.91421",
    longitude: "-96.4349",
    num_level_1: "2",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "198",
    access_code: "level-2",
    state: "TN",
    station_name: "Knoxville CDJR",
    street_address: "8544 Kingston Pike",
    city: "Knoxville",
    zip: "37919",
    latitude: "35.92061",
    longitude: "-84.06460",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "199",
    access_code: "level-2",
    state: "FL",
    station_name: "Sanford Chrysler Dodge Jeep Ram",
    street_address: "750 Towne Center Blvd",
    city: "Sanford",
    zip: "32771",
    latitude: "28.79895",
    longitude: "-81.33956",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "200",
    access_code: "level-2",
    state: "NJ",
    station_name: "Freehold Toyota",
    street_address: "4268 Route #9 South",
    city: "Freehold",
    zip: "07728",
    latitude: "40.27864",
    longitude: "-74.29578",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "2",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "201",
    access_code: "level-2",
    state: "MI",
    station_name: "Suburban  Volkswagen of Troy",
    street_address: "1804 Maplelawn Drive",
    city: "Troy",
    zip: "48084",
    latitude: "42.55172",
    longitude: "-83.174302",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "1",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "202",
    access_code: "level-2",
    state: "WA",
    station_name: "Michael's Subaru of Bellevue",
    street_address: "15150 SE Eastgate Way",
    city: "Bellevue",
    zip: "98007",
    latitude: "47.58150",
    longitude: "-122.13758",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "203",
    access_code: "level-2",
    state: "AZ",
    station_name: "Avondale Nissan",
    street_address: "10305 West Papago Freeway",
    city: "Avondale",
    zip: "85323",
    latitude: "33.45986",
    longitude: "-112.28419",
    num_level_1: "0",
    num_level_2: "4",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "204",
    access_code: "level-2",
    state: "TX",
    station_name: "Southwest KIA of Mesquite",
    street_address: "1919 Oates Dr.",
    city: "Mesquite",
    zip: "75150",
    latitude: "32.83894",
    longitude: "-96.63112",
    num_level_1: "1",
    num_level_2: "1",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "205",
    access_code: "level-2",
    state: "TX",
    station_name: "Southwest KIA of Round Rock",
    street_address: "600 Jeffrey Way",
    city: "Round rock",
    zip: "78665",
    latitude: "30.54096",
    longitude: "-97.69055",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "206",
    access_code: "level-2",
    state: "TX",
    station_name: "Midland CJD",
    street_address: "3801 W Wall St",
    city: "Midland",
    zip: "79703",
    latitude: "31.9761264",
    longitude: "-102.1140577",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "207",
    access_code: "level-2",
    state: "FL",
    station_name: "Wesley Chapel Mazda",
    street_address: "27000 Wesley Chapel Blvd",
    city: "Wesley Chapel",
    zip: "33544",
    latitude: "28.23415",
    longitude: "-82.36945",
    num_level_1: "1",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "208",
    access_code: "level-2",
    state: "MI",
    station_name: "Suburban Imports of Farmington Hills",
    street_address: "37911 Grand River Ave",
    city: "Farmington Hills",
    zip: "48335",
    latitude: "42;46766",
    longitude: "-83.42032",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "209",
    access_code: "level-2",
    state: "MI",
    station_name: "Porsche of Farmington Hills",
    street_address: "37911 Grand River Ave",
    city: "Farmington Hills",
    zip: "48335",
    latitude: "42.46807",
    longitude: "-83.42047",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "210",
    access_code: "level-2",
    state: "MI",
    station_name: "Suburban Volkswagen of Farmington Hills",
    street_address: "37911 Grand River Ave",
    city: "Farmington Hills",
    zip: "48335",
    latitude: "42.46806",
    longitude: "-83.42074",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "211",
    access_code: "level-2",
    state: "MI",
    station_name: "Suburban Buick GMC of Ferndale",
    street_address: "21800 Woodward Ave",
    city: "Ferndale",
    zip: "48220",
    latitude: "42.45340",
    longitude: "-83.12894",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "1",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "212",
    access_code: "level-2",
    state: "MI",
    station_name: "Troy Exotics -  Bugatti",
    street_address: "1755-C Maplelawn Drive",
    city: "Troy",
    zip: "48084",
    latitude: "42.55214",
    longitude: "-83.17015",
    num_level_1: "1",
    num_level_2: "1",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "213",
    access_code: "level-2",
    state: "MI",
    station_name: "Troy Exotics -  AstonMartin",
    street_address: "1767 Maplelawn Drive",
    city: "Troy",
    zip: "48084",
    latitude: "42.55287",
    longitude: "-83.17142",
    num_level_1: "1",
    num_level_2: "1",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "214",
    access_code: "level-2",
    state: "MI",
    station_name: "Troy Exotics -  Lambo",
    street_address: "1755-E Maplelawn Drive",
    city: "Troy",
    zip: "48084",
    latitude: "42.55221",
    longitude: "-83.17023",
    num_level_1: "1",
    num_level_2: "1",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "215",
    access_code: "level-2",
    state: "MI",
    station_name: "Troy Exotics - Maserati",
    street_address: "1755-F Maplelawn Drive",
    city: "Troy",
    zip: "48084",
    latitude: "42.55210",
    longitude: "-83.17017",
    num_level_1: "1",
    num_level_2: "1",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "216",
    access_code: "level-2",
    state: "MI",
    station_name: "Troy Exotics - McLaren",
    street_address: "1755-B Maplelawn Drive",
    city: "Troy",
    zip: "48084",
    latitude: "42.55221",
    longitude: "-83.16997",
    num_level_1: "1",
    num_level_2: "1",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "217",
    access_code: "level-2",
    state: "MI",
    station_name: "Troy Exotics - Rolls Royce",
    street_address: "1755-D Maplelawn Drive",
    city: "Troy",
    zip: "48084",
    latitude: "42.55218",
    longitude: "-83.17004",
    num_level_1: "1",
    num_level_2: "1",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "218",
    access_code: "level-2",
    state: "MI",
    station_name: "Suburban Buick GMC of Troy",
    street_address: "1850  Maple Road",
    city: "Troy",
    zip: "48084",
    latitude: "42.54881",
    longitude: "-83.17371",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "219",
    access_code: "level-2",
    state: "NJ",
    station_name: "Planet Honda",
    street_address: "2285 Route 22 Wes",
    city: "Union",
    zip: "07083",
    latitude: "40.69163",
    longitude: "-74.29081",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "220",
    access_code: "level-2",
    state: "CA",
    station_name: "BMW of Sherman Oaks (Center BMW)",
    street_address: "5201 Van Nuys Blvd",
    city: "Sherman Oaks",
    zip: "91401",
    latitude: "34.16543896213488",
    longitude: "-118.44981384652327",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "221",
    access_code: "level-2",
    state: "TX",
    station_name: "Southwest KIA of Rockwall",
    street_address: "1790 I-30 Frontage Rd",
    city: "Rockwall",
    zip: "75087",
    latitude: "32.916682516509255",
    longitude: "-96.42576167408636",
    num_level_1: "1",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "222",
    access_code: "level-2",
    state: "TX",
    station_name: "Southwest KIA of Austin",
    street_address: "13175 N Highway 183",
    city: "Austin",
    zip: "78750",
    latitude: "30.44281",
    longitude: "-97.78005",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "223",
    access_code: "level-2",
    state: "CA",
    station_name: "Rock Honda",
    street_address: "16570 S Highland Ave",
    city: "Fontana",
    zip: "92336",
    latitude: "34.13550",
    longitude: "-117.44337",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "224",
    access_code: "level-2",
    state: "FL",
    station_name: "Wesley Chapel Mazda",
    street_address: "26944 Wesley Chapel Blvd",
    city: "Wesley Chapel",
    zip: "33544",
    latitude: "28.23324",
    longitude: "-82.36868",
    num_level_1: "2",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "225",
    access_code: "level-2",
    state: "OR",
    station_name: "Medford Body Shop",
    street_address: "2665 Bullock Rd",
    city: "Medford",
    zip: "97504",
    latitude: "42.355952",
    longitude: "-122.871346",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "226",
    access_code: "level-2",
    state: "NV",
    station_name: "Reno Subaru",
    street_address: "2270 Kietzke Ln",
    city: "Reno",
    zip: "89502",
    latitude: "39.501392",
    longitude: "-119.78796",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "227",
    access_code: "level-2",
    state: "NV",
    station_name: "Reno Subaru",
    street_address: "2270 Kietzke Ln",
    city: "Reno",
    zip: "89502",
    latitude: "39.501392",
    longitude: "-119.78796",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "228",
    access_code: "level-2",
    state: "WA",
    station_name: "Spokane Subaru",
    street_address: "423 W 3rd Ave",
    city: "Spokane",
    zip: "99201",
    latitude: "47.6534",
    longitude: "-117.418723",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "229",
    access_code: "level-2",
    state: "WA",
    station_name: "Odessa Hyundai",
    street_address: "5000 John Ben Shepperd Pkwy",
    city: "Odessa",
    zip: "79762",
    latitude: "31.89939709",
    longitude: "-102.3335491",
    num_level_1: "2",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "230",
    access_code: "level-2",
    state: "TX",
    station_name: "Odessa Toyota",
    street_address: "5050 John Ben Shepperd Pkwy",
    city: "Odessa",
    zip: "79762",
    latitude: "31.89939709",
    longitude: "-102.3335491",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "231",
    access_code: "level-2",
    state: "IA",
    station_name: "Ames Honda Nissan",
    street_address: "2901 S. Duff Ave.",
    city: "Ames",
    zip: "50010",
    latitude: "41.997327",
    longitude: "-93.610192",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "232",
    access_code: "level-2",
    state: "AK",
    station_name: "Anchorage Kia",
    street_address: "4434 Old Seward Hwy",
    city: "Anchorage",
    zip: "99503",
    latitude: "61.180360",
    longitude: "-149.869340",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "1",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "233",
    access_code: "level-2",
    state: "MT",
    station_name: "Billings Toyota",
    street_address: "1532 Grand Ave",
    city: "Billings",
    zip: "59102",
    latitude: "45.783417",
    longitude: "-108.555181",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "234",
    access_code: "level-2",
    state: "OR",
    station_name: "Portland Mini",
    street_address: "4434 Old Seward Hwy",
    city: "Portloan",
    zip: "97225",
    latitude: "45.495894",
    longitude: "-122.771212",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "235",
    access_code: "level-2",
    state: "OR",
    station_name: "Salem Honda",
    street_address: "1101 Van Ness Ave NE",
    city: "Salem",
    zip: "97301",
    latitude: "44.971908",
    longitude: "-123.019448",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "236",
    access_code: "level-2",
    state: "CA",
    station_name: "Walnut Creek Subaru",
    street_address: "2646 N Main St",
    city: "Walnut Creek",
    zip: "94597",
    latitude: "44.971908",
    longitude: "-123.019448",
    num_level_1: "4",
    num_level_2: "1",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "237",
    access_code: "level-2",
    state: "HI",
    station_name: "Maui Honda",
    street_address: "110 Hana Hwy",
    city: "Kahului",
    zip: "96732",
    latitude: "20.890586",
    longitude: "-156.461168",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "238",
    access_code: "level-2",
    state: "HI",
    station_name: "Honolulu Acura",
    street_address: "3251 N Nimitz Hwy",
    city: "Honolulu",
    zip: "96819",
    latitude: "21.336054",
    longitude: "-157.912483",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "239",
    access_code: "level-2",
    state: "WY",
    station_name: "Casper Ford",
    street_address: "3333 Cy Ave",
    city: "Casper",
    zip: "82604",
    latitude: "42.82503",
    longitude: "-106.36117",
    num_level_1: "2",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "240",
    access_code: "level-2",
    state: "PA",
    station_name: "Pittsburgh Subaru",
    street_address: "9545 Perry Hwy",
    city: "Pittsburgh",
    zip: "15237",
    latitude: "40.579463",
    longitude: "-80.038283",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "241",
    access_code: "level-2",
    state: "PA",
    station_name: "Wexford Acura",
    street_address: "11410 Perry Hwy",
    city: "Wexford",
    zip: "15090",
    latitude: "40.579463",
    longitude: "-80.038283",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "242",
    access_code: "level-2",
    state: "NY",
    station_name: "Orange County Lexus",
    street_address: "3496 US 6",
    city: "Middletown",
    zip: "10940",
    latitude: "41.419395",
    longitude: "-74.42804",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "243",
    access_code: "level-2",
    state: "NJ",
    station_name: "Ramsey Lexus",
    street_address: "1000 Rte 17 N",
    city: "Ramsey",
    zip: "07446",
    latitude: "41.067139",
    longitude: "-74.13195",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "244",
    access_code: "level-2",
    state: "CA",
    station_name: "Ramsey Lexus",
    street_address: "26799 Ynez Rd",
    city: "Temecul",
    zip: "92591",
    latitude: "33.513344",
    longitude: "-117.155815",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "245",
    access_code: "level-2",
    state: "CA",
    station_name: "Tustin Acura",
    street_address: "9 Auto Center Dr",
    city: "Tustin",
    zip: "92782",
    latitude: "33.72732",
    longitude: "-117.798296",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "246",
    access_code: "level-2",
    state: "NJ",
    station_name: "Montclair Acura",
    street_address: "100 Bloomfield Ave",
    city: "Verona",
    zip: "07044",
    latitude: "40.825877",
    longitude: "-74.231256",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "247",
    access_code: "level-2",
    state: "NJ",
    station_name: "Academy Honda",
    street_address: "1101 U S 9",
    city: "Old Bridge",
    zip: "08857",
    latitude: "40.448366",
    longitude: "-74.298454",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "248",
    access_code: "level-2",
    state: "FL",
    station_name: "Audi Coral Springs",
    street_address: "5555 N State Road 7",
    city: "Coral Springs",
    zip: "33073",
    latitude: "26.297351",
    longitude: "-80.203071",
    num_level_1: "2",
    num_level_2: "4",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "249",
    access_code: "level-2",
    state: "FL",
    station_name: "Audi Fort Lauderdale",
    street_address: "1200 N Federal Hwy",
    city: "Fort Lauderdale",
    zip: "33304",
    latitude: "26.13979",
    longitude: "-80.119419",
    num_level_1: "2",
    num_level_2: "4",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "250",
    access_code: "level-2",
    state: "MI",
    station_name: "Elder Ford",
    street_address: "777 John R Rd",
    city: "Troy",
    zip: "48083",
    latitude: "42.54200543850034",
    longitude: "-83.10832745019792",
    num_level_1: "0",
    num_level_2: "3",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "251",
    access_code: "level-2",
    state: "FL",
    station_name: "Elder Ford of Tampa",
    street_address: "9560 N Florida Ave",
    city: "Tampa",
    zip: "33612",
    latitude: "28.039264982433295",
    longitude: "-82.45926396635211",
    num_level_1: "4",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "252",
    access_code: "level-2",
    state: "GA",
    station_name: "Curry Honda",
    street_address: "5525 Peachtree Industrial Blvd",
    city: "Chamblee",
    zip: "30341",
    latitude: "33.898151",
    longitude: "-84.300827",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "253",
    access_code: "level-2",
    state: "ON",
    station_name: "Pfaff Porsche",
    street_address: "105 Four Valley Dr",
    city: "Vaughan",
    zip: "ON L4K 4V8, Canada",
    latitude: "43.8163835499337",
    longitude: "-79.5431135139352",
    num_level_1: "6",
    num_level_2: "1",
    num_level_3: "4",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "254",
    access_code: "level-2",
    state: "CA",
    station_name: "Fontana Honda",
    street_address: "16570 S Highland Ave",
    city: "Vaughan",
    zip: "92336",
    latitude: "34.13552036",
    longitude: "-117.4431395",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "255",
    access_code: "level-2",
    state: "TX",
    station_name: "Southwest KIA of Frisco",
    street_address: "10220 State Highway 121",
    city: "Frisco",
    zip: "75035",
    latitude: "33.102991",
    longitude: "-96.793727",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "256",
    access_code: "level-2",
    state: "NV",
    station_name: "Centennial Hyundai",
    street_address: "6200 Centennial Center Boulevard",
    city: "Las Vegas",
    zip: "89149",
    latitude: "36.273426",
    longitude: "-115.265363",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "257",
    access_code: "level-2",
    state: "NV",
    station_name: "Hyundai of Las Vegas",
    street_address: "7150 W Sahara Ave",
    city: "Las Vegas",
    zip: "89117",
    latitude: "36.144829",
    longitude: "-115.249051",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "2",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "258",
    access_code: "level-2",
    state: "WA",
    station_name: "Michael's Toyota of Bellevue (Center)",
    street_address: "3080 148th Ave SE",
    city: "Bellevue,",
    zip: "98007",
    latitude: "47.582039",
    longitude: "-122.141289",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "259",
    access_code: "level-2",
    state: "MI",
    station_name: "Suburban Toyota of Troy",
    street_address: "2100 Maple",
    city: "Troy,",
    zip: "48084",
    latitude: "42.54851873908834",
    longitude: "-83.17821495252714",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "260",
    access_code: "level-2",
    state: "MI",
    station_name: "Suburban Subaru",
    street_address: "1821 Maplelawn Drive",
    city: "Troy,",
    zip: "48084",
    latitude: "42.55132253376281",
    longitude: "-83.17408884643301",
    num_level_1: "1",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "261",
    access_code: "level-2",
    state: "MI",
    station_name: "Suburban Mazda of Troy",
    street_address: "1795 Maplelawn Drive",
    city: "Troy,",
    zip: "48084",
    latitude: "42.552715176316745",
    longitude: "-83.17318217896813",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "262",
    access_code: "level-2",
    state: "MI",
    station_name: "Suburban Infiniti of Troy",
    street_address: "1821 Maplelawn Drive",
    city: "Troy,",
    zip: "48084",
    latitude: "42.551290919227974",
    longitude: "-83.1740459310903",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "263",
    access_code: "level-2",
    state: "MI",
    station_name: "Suburban Hyundai (Hyundai of Troy)",
    street_address: "1814 Maplelawn Drive",
    city: "Troy,",
    zip: "48084",
    latitude: "42.55201334313274",
    longitude: "-83.17546230595406",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "264",
    access_code: "level-2",
    state: "MI",
    station_name: "Suburban Infiniti of Novi",
    street_address: "24355 Haggerty Road",
    city: "Novi",
    zip: "48375",
    latitude: "42.4707825174567",
    longitude: "-83.43542056177783",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "265",
    access_code: "level-2",
    state: "MI",
    station_name: "Suburban Toyota of Farmington Hills",
    street_address: "25000 Haggerty Road",
    city: "Farmington Hills",
    zip: "48335",
    latitude: "42.475904435270917",
    longitude: "-83.43368697380659",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "266",
    access_code: "level-2",
    state: "OR",
    station_name: "Medford Ford Mazda Location 2",
    street_address: "2611 Biddle Rd",
    city: "Medford",
    zip: "97504",
    latitude: "42.355533",
    longitude: "-122.875641",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "267",
    access_code: "level-2",
    state: "CA",
    station_name: "Valencia Lexus",
    street_address: "24033 Creekside Rd",
    city: "Valencia",
    zip: "91355",
    latitude: "34.419842",
    longitude: "-118.554573",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "268",
    access_code: "level-2",
    state: "MI",
    station_name: "Suburban Porsche Farmington Hills",
    street_address: "37911 Grand River Ave",
    city: "Farmington Hills",
    zip: "48355",
    latitude: "42.46807916266984",
    longitude: "-83.42039774867078",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "269",
    access_code: "level-2",
    state: "MI",
    station_name: "Suburban Mazda of Farmington Hills",
    street_address: "37911 Grand River Ave",
    city: "Farmington Hills",
    zip: "48355",
    latitude: "42.46775330617529",
    longitude: "-83.42006191798534",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "270",
    access_code: "level-2",
    state: "MI",
    station_name: "Suburban Audi Farmington Hills",
    street_address: "37911 Grand River Ave",
    city: "Farmington Hills",
    zip: "48355",
    latitude: "42.46796794875301",
    longitude: "-83.41994594867083",
    num_level_1: "2",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "271",
    access_code: "level-2",
    state: "MI",
    station_name: "Mercedes Benz of Ann Arbor",
    street_address: "570 Auto Mall Drive",
    city: "Ann Arbor",
    zip: "48103",
    latitude: "42.287580",
    longitude: "-83.813520",
    num_level_1: "1",
    num_level_2: "3",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "272",
    access_code: "level-2",
    state: "FL",
    station_name: "Hyundai of New Port Richey",
    street_address: "3936 U S 19",
    city: "New Port Richey",
    zip: "34652",
    latitude: "28.216531",
    longitude: "-82.736692",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "1",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "273",
    access_code: "level-2",
    state: "FL",
    station_name: "Volkswagen of New Port Richey",
    street_address: "4719 U S 19",
    city: "New Port Richey",
    zip: "34652",
    latitude: "28.227469",
    longitude: "-82.732863",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "274",
    access_code: "level-2",
    state: "FL",
    station_name: "Sanford Chrysler Dodge Jeep Ram",
    street_address: "750 Towne Center Blvd",
    city: "Sanford",
    zip: "32771",
    latitude: "28.799012821170624",
    longitude: "-81.33982379322998",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "275",
    access_code: "level-2",
    state: "FL",
    station_name: "Des Moines Subaru",
    street_address: "9625 Hickman Rd",
    city: "Urbandale",
    zip: "50322",
    latitude: "41.615596",
    longitude: "-93.751395",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "276",
    access_code: "level-2",
    state: "WV",
    station_name: "Morgantown Subaru",
    street_address: "1730 Mileground Rd",
    city: "Morgantown",
    zip: "26505",
    latitude: "39.643936",
    longitude: "-79.927727",
    num_level_1: "2",
    num_level_2: "1",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "277",
    access_code: "level-2",
    state: "FL",
    station_name: "Wesley Chapel Toyota",
    street_address: "5300 Eagleston Blvd",
    city: "Wesley Chapel,",
    zip: "33544",
    latitude: "28.234945",
    longitude: "-82.353908",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "278",
    access_code: "level-2",
    state: "CA",
    station_name: "Sacramento Lexus",
    street_address: "2600 Fulton Ave",
    city: "Sacramento",
    zip: "95821",
    latitude: "38.612587",
    longitude: "-121.400751",
    num_level_1: "0",
    num_level_2: "4",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "279",
    access_code: "level-2",
    state: "TX",
    station_name: "Dallas Toyota",
    street_address: "2610 Forest Ln",
    city: "Dallas",
    zip: "75234",
    latitude: "32.907882",
    longitude: "-96.889086",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "280",
    access_code: "level-2",
    state: "TX",
    station_name: "Austin Honda",
    street_address: "3400 Steck Ave",
    city: "Austin",
    zip: "78757",
    latitude: "30.367969",
    longitude: "-97.739481",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "281",
    access_code: "level-2",
    state: "TX",
    station_name: "Houston Acura",
    street_address: "16015 Katy Fwy",
    city: "Houston",
    zip: "77094",
    latitude: "29.7836",
    longitude: "-95.665535",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "282",
    access_code: "level-2",
    state: "WA",
    station_name: "Tri-Cities Honda",
    street_address: "6901 West Clearwater Ave",
    city: "Kennywick",
    zip: "99336",
    latitude: "46.2094628",
    longitude: "-119.2140688",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "283",
    access_code: "level-2",
    state: "NV",
    station_name: "Reno Hyundai",
    street_address: "2620 Kietzke Ln",
    city: "Reno",
    zip: "99336",
    latitude: "39.4991",
    longitude: "-119.788382",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "284",
    access_code: "level-2",
    state: "MT",
    station_name: "Billings CJD",
    street_address: "2229 King Ave W",
    city: "Billings",
    zip: "59102",
    latitude: "45.755644",
    longitude: "-108.572483",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "285",
    access_code: "level-2",
    state: "ID",
    station_name: "Boise Ford Lincoln Location 2",
    street_address: "8853 W. Fairview Ave.",
    city: "Boise",
    zip: "83704",
    latitude: "43.61945",
    longitude: "-116.293187",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "286",
    access_code: "level-2",
    state: "TX",
    station_name: "Abilene Toyota",
    street_address: "4449 Southwest Dr",
    city: "Abilene",
    zip: "79606",
    latitude: "32.407781",
    longitude: "-99.781431",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "287",
    access_code: "level-2",
    state: "IA",
    station_name: "Des Moines Acura",
    street_address: "5138 Merle Hay Rd",
    city: "Johnston",
    zip: "50131",
    latitude: "41.653816",
    longitude: "-93.698744",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "288",
    access_code: "level-2",
    state: "OR",
    station_name: "Eugene CJD Fiat Location",
    street_address: "2121 Martin Luther King Jr Blvd",
    city: "Eugene",
    zip: "97401",
    latitude: "44.05958",
    longitude: "-123.0786",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "289",
    access_code: "level-2",
    state: "AK",
    station_name: "Anchorage BMW Mini Location 2",
    street_address: "730 E. 5th Ave.",
    city: "Anchorage",
    zip: "99501",
    latitude: "61.21722",
    longitude: "-149.86948",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "290",
    access_code: "level-2",
    state: "MT",
    station_name: "Missoula Toyota",
    street_address: "4001 Brooks St",
    city: "Missoula",
    zip: "59804",
    latitude: "46.83472",
    longitude: "-114.046769",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "291",
    access_code: "level-2",
    state: "TX",
    station_name: "Corpus Christi Ram Truck Center",
    street_address: "4313 S Staples St",
    city: "Corpus Christi",
    zip: "78411",
    latitude: "27.721428",
    longitude: "-97.37661",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "292",
    access_code: "level-2",
    state: "PA",
    station_name: "Baierl Chevrolet",
    street_address: "10430 Perry Hwy",
    city: "Wexford",
    zip: "15090",
    latitude: "40.60675",
    longitude: "-80.05177",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "293",
    access_code: "level-2",
    state: "TX",
    station_name: "Corpus Christi Ram Truck Center",
    street_address: "4313 S Staples St",
    city: "Corpus Christi",
    zip: "78411",
    latitude: "27.721428",
    longitude: "-97.37661",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "294",
    access_code: "level-2",
    state: "PA",
    station_name: "Baierl Chevrolet",
    street_address: "10430 Perry Hwy",
    city: "Wexford",
    zip: "15090",
    latitude: "40.60675",
    longitude: "-80.05177",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "295",
    access_code: "level-2",
    state: "PA",
    station_name: "Apollo Moon Township Subaru",
    street_address: "5450 University Blvd",
    city: "Moon Township",
    zip: "15108",
    latitude: "40.525402",
    longitude: "-80.213211",
    num_level_1: "2",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "296",
    access_code: "level-2",
    state: "TX",
    station_name: "Grapevine Honda (Open Point)",
    street_address: "2301 William D Tate Ave",
    city: "Grapevine",
    zip: "76051",
    latitude: "32.914413",
    longitude: "-97.09523",
    num_level_1: "2",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "297",
    access_code: "level-2",
    state: "CA",
    station_name: "Roseville Lexus",
    street_address: "300 Automall Dr",
    city: "Roseville",
    zip: "95661",
    latitude: "38.749096",
    longitude: "-121.256903",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "298",
    access_code: "level-2",
    state: "TX",
    station_name: "Dallas Collision Center",
    street_address: "2964 Irving Blvd",
    city: "Roseville",
    zip: "75247",
    latitude: "32.807936",
    longitude: "-96.856484",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "299",
    access_code: "level-2",
    state: "TX",
    station_name: "Clear Lake Infiniti",
    street_address: "14705 Gulf Fwy",
    city: "Houston",
    zip: "77034",
    latitude: "29.592257",
    longitude: "-95.189056",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "300",
    access_code: "level-2",
    state: "TX",
    station_name: "Southwest Infiniti",
    street_address: "10495 Southwest Fwy",
    city: "Houston",
    zip: "77074",
    latitude: "29.665293",
    longitude: "-95.550972",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "301",
    access_code: "level-2",
    state: "VA",
    station_name: "Sterling MINI",
    street_address: "21710 Auto World Cir",
    city: "Sterling",
    zip: "20166",
    latitude: "39.011919",
    longitude: "-77.439624",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "302",
    access_code: "level-2",
    state: "IA",
    station_name: "Des Moines Mazda",
    street_address: "9625 Hickman Rd",
    city: "Urbandale",
    zip: "50322",
    latitude: "41.615596",
    longitude: "-93.751395",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "303",
    access_code: "level-2",
    state: "FL",
    station_name: "Sanford Chrysler Dodge Jeep Ram (aka CDJR of Sanford & aka CJDR of Seminole County)",
    street_address: "750 Towne Center Blvd",
    city: "Sanford",
    zip: "32771",
    latitude: "28.799012821170624",
    longitude: "-81.33982379322998",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "304",
    access_code: "level-2",
    state: "FL",
    station_name: "Wesley Chapel Hyundai",
    street_address: "27000 Wesley Chapel Blvd",
    city: "Wesley Chapel",
    zip: "33544",
    latitude: "28.2341172",
    longitude: "-82.3691559",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "305",
    access_code: "level-2",
    state: "MI",
    station_name: "Suburban Collision of Farmington Hills",
    street_address: "34600 Grand River",
    city: "Farmington Hills",
    zip: "48335",
    latitude: "42.47160396749579",
    longitude: "-83.38928130965573",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "306",
    access_code: "level-2",
    state: "MI",
    station_name: "Suburban Collision of Ferndale",
    street_address: "1600 Bonner Street",
    city: "Ferndale",
    zip: "48220",
    latitude: "42.45920748510135",
    longitude: "-83.11958783054789",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "307",
    access_code: "level-2",
    state: "MI",
    station_name: "Suburban Collision of Troy",
    street_address: "1759 Maplelawn Drive",
    city: "Troy",
    zip: "48084",
    latitude: "42.551412355422315",
    longitude: "-83.17015685013249",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "308",
    access_code: "level-2",
    state: "MI",
    station_name: "Suburban Exotics",
    street_address: "1755 Maplelawn Drive",
    city: "Troy",
    zip: "48084",
    latitude: "42.552190928577",
    longitude: "-83.1700195059541",
    num_level_1: "1",
    num_level_2: "1",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "309",
    access_code: "level-2",
    state: "MS",
    station_name: "Toyota of Jackson (Herrin Gear)",
    street_address: "6100 I 55 N",
    city: "Jackson",
    zip: "39211",
    latitude: "32.393865",
    longitude: "-90.144136",
    num_level_1: "1",
    num_level_2: "1",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "310",
    access_code: "level-2",
    state: "WA",
    station_name: "Michael's Subaru of Bellevue (Center)",
    street_address: "15150 SE Eastgate Way",
    city: "Bellevue",
    zip: "98007",
    latitude: "47.58161345323351",
    longitude: "-122.13714185644106",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "311",
    access_code: "level-2",
    state: "NV",
    station_name: "Genesis of Las Vegas",
    street_address: "7200 W Sahara Ave",
    city: "Las Vegas",
    zip: "89117",
    latitude: "36.144962",
    longitude: "-115.250294",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "312",
    access_code: "level-2",
    state: "NV",
    station_name: "ABC Hyundai",
    street_address: "6825 Redwood S",
    city: "Las Vegas",
    zip: "89118",
    latitude: "36.064358",
    longitude: "-115.238794",
    num_level_1: "1",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "313",
    access_code: "level-2",
    state: "CA",
    station_name: "Acura of Sherman Oaks (Center Acura)",
    street_address: "5230 Van Nuys Blvd",
    city: "Sherman Oaks",
    zip: "91401",
    latitude: "34.165663",
    longitude: "-118.44833",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "314",
    access_code: "level-2",
    state: "TX",
    station_name: "Southwest KIA of Dallas",
    street_address: "39650 Lyndon B Johnson Fwy",
    city: "Dallas",
    zip: "75237",
    latitude: "32.643875",
    longitude: "-96.859262",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "315",
    access_code: "level-2",
    state: "TX",
    station_name: "Southwest KIA of Frisco",
    street_address: "10220 State Highway 121",
    city: "Frisco",
    zip: "75035",
    latitude: "33.102991",
    longitude: "-96.793727",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "316",
    access_code: "level-2",
    state: "FL",
    station_name: "Genesis of New Port Richey",
    street_address: "3936 US Highway 19",
    city: "New Port Richey",
    zip: "34652",
    latitude: "28.216531",
    longitude: "-82.736692",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "317",
    access_code: "level-2",
    state: "AL",
    station_name: "Subaru of Mobile (Koby Subaru)",
    street_address: "1431 East Interstate 65 Service Rd S.",
    city: "New Port Richey",
    zip: "36606",
    latitude: "30.66104",
    longitude: "-88.12546",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "318",
    access_code: "level-2",
    state: "Canada",
    station_name: "Pfaff Audi",
    street_address: "9088 Jane St",
    city: "Vaughan",
    zip: "ON L4K 2M9",
    latitude: "43.82982270481876",
    longitude: "-79.53323471025223",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "1",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "319",
    access_code: "level-2",
    state: "Canada",
    station_name: "Pfaff Autoworks & Tuning",
    street_address: "214 Courtland Ave",
    city: "Vaughan",
    zip: "ON L4K 4L3",
    latitude: "43.813877984185865",
    longitude: "-79.53562713537261",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "320",
    access_code: "level-2",
    state: "Canada",
    station_name: "Pfaff BMW",
    street_address: "4505 Dixie Rd",
    city: "Mississauga",
    zip: "ON L4W 5K3",
    latitude: "43.644738092113734",
    longitude: "-79.58671194",
    num_level_1: "1",
    num_level_2: "3",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "321",
    access_code: "level-2",
    state: "Canada",
    station_name: "Pfaff Subaru",
    street_address: "12 Wilbert St",
    city: "Guelph",
    zip: "ON N1K 0A4",
    latitude: "43.54259851050827",
    longitude: "-80.31843814408059",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "322",
    access_code: "level-2",
    state: "Canada",
    station_name: "Porsche Centre Markham",
    street_address: "8590 McCowan Rd",
    city: "Markham",
    zip: "ON L3P 3M2",
    latitude: "43.87279091158507",
    longitude: "-79.28125275094222",
    num_level_1: "3",
    num_level_2: "2",
    num_level_3: "2",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "323",
    access_code: "level-2",
    state: "Canada",
    station_name: "BMW Markham",
    street_address: "8111 Kennedy Rd",
    city: "Markham",
    zip: "ON L3R 5M2",
    latitude: "43.85385627245792",
    longitude: "-79.30554650838556",
    num_level_1: "0",
    num_level_2: "3",
    num_level_3: "1",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "324",
    access_code: "level-2",
    state: "Canada",
    station_name: "Pfaff Harley-Davidson",
    street_address: "8779 Yonge St",
    city: "Richmond Hill",
    zip: "ON L4C 6Z1",
    latitude: "43.842091404901964",
    longitude: "-79.42860640283715",
    num_level_1: "2",
    num_level_2: "0",
    num_level_3: "1",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "325",
    access_code: "level-2",
    state: "Canada",
    station_name: "McLaren Vancouver",
    street_address: "1711 W 2nd Ave",
    city: "Vancouver",
    zip: "BC V6J 1H7",
    latitude: "49.27023479730485",
    longitude: "-123.14315696168023",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "326",
    access_code: "level-2",
    state: "CA",
    station_name: "Costa Mesa CDJRF",
    street_address: "2929 Harbor Blvd",
    city: "Costa Mesa",
    zip: "92626",
    latitude: "33.67813983132012",
    longitude: "-117.919852",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "327",
    access_code: "level-2",
    state: "MI",
    station_name: "Elder Ford of Romeo",
    street_address: "63300 Van Dyke",
    city: "Washington",
    zip: "48095",
    latitude: "42.76060564381773",
    longitude: "-83.01709493300085",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "328",
    access_code: "level-2",
    state: "CA",
    station_name: "Roseville Toyota",
    street_address: "700 Automall Dr",
    city: "Roseville",
    zip: "95661",
    latitude: "38.75125092",
    longitude: "-121.25819",
    num_level_1: "3",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "329",
    access_code: "level-2",
    state: "CA",
    station_name: "Ford of Downtown LA",
    street_address: "1929 S Figueroa St",
    city: "Los Angeles",
    zip: "90007",
    latitude: "34.03416828",
    longitude: "-118.2718769",
    num_level_1: "2",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "330",
    access_code: "level-2",
    state: "PA",
    station_name: "Wexford Honda Location 2",
    street_address: "10430 Perry Hwy",
    city: "Wexford",
    zip: "15090",
    latitude: "40.6070983",
    longitude: "-80.04950107",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "331",
    access_code: "level-2",
    state: "PA",
    station_name: "Wexford Chevrolet Cadillac Location 2",
    street_address: "10430 Perry Hwy",
    city: "Wexford",
    zip: "15090",
    latitude: "40.60675",
    longitude: "-80.05177",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "332",
    access_code: "level-2",
    state: "PA",
    station_name: "Pittsburgh Subaru Location 2",
    street_address: "9545 Perry Hwy",
    city: "Pittsburgh",
    zip: "15237",
    latitude: "40.579463",
    longitude: "-80.038283",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "333",
    access_code: "level-2",
    state: "PA",
    station_name: "Wexford Acura Location 2",
    street_address: "11410 Perry Hwy",
    city: "Wexford",
    zip: "15090",
    latitude: "40.623585",
    longitude: "-80.052983",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "334",
    access_code: "level-2",
    state: "PA",
    station_name: "Wexford Chevrolet Cadillac Location 3",
    street_address: "10430 Perry Hwy",
    city: "Wexford",
    zip: "15090",
    latitude: "40.60675",
    longitude: "-80.05177",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "335",
    access_code: "level-2",
    state: "PA",
    station_name: "Cranberry Township Toyota Location 2",
    street_address: "19045 Perry Hwy",
    city: "Mars",
    zip: "16046",
    latitude: "40.67088399",
    longitude: "-80.09826842",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "336",
    access_code: "level-2",
    state: "PA",
    station_name: "Zelienople Ford Location 2",
    street_address: "540 S. Main St.",
    city: "Zelienople",
    zip: "16063",
    latitude: "40.786729",
    longitude: "-80.135193",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "337",
    access_code: "level-2",
    state: "Canada",
    station_name: "Sisley For Honda",
    street_address: "88 Steeles Ave W",
    city: "Thornhill",
    zip: "ON L4J 1A1",
    latitude: "43.79808191331719",
    longitude: "-79.42265430599123",
    num_level_1: "1",
    num_level_2: "1",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "338",
    access_code: "level-2",
    state: "FL",
    station_name: "Lehman Hyundai",
    street_address: "21400 NW 2nd Ave",
    city: "Miami Gardens",
    zip: "33169",
    latitude: "25.972126135035435",
    longitude: "-80.20560163384536",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "339",
    access_code: "level-2",
    state: "FL",
    station_name: "Lehman Subaru",
    street_address: "21300 NW 2nd Ave",
    city: "Miami Gardens",
    zip: "33169",
    latitude: "25.971218534467653",
    longitude: "-80.20548040528266",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "340",
    access_code: "level-2",
    state: "FL",
    station_name: "William Lehman Mitsubishi",
    street_address: "21000 NW 2nd Ave",
    city: "Miami",
    zip: "33169",
    latitude: "25.969871493417322",
    longitude: "-80.20574873939405",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "341",
    access_code: "level-2",
    state: "FL",
    station_name: "Lehman Buick GMC",
    street_address: "21200 NW 2nd Ave",
    city: "Miami Gardens",
    zip: "33169",
    latitude: "25.970451656107635",
    longitude: "-80.20606319891495",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "342",
    access_code: "level-2",
    state: "FL",
    station_name: "Doral Hyundai",
    street_address: "10285 NW 12th St",
    city: "Doral",
    zip: "33172",
    latitude: "25.783484855218113",
    longitude: "-80.36151479706783",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "343",
    access_code: "level-2",
    state: "FL",
    station_name: "Doral Kia",
    street_address: "10155 NW 12th St",
    city: "Doral",
    zip: "33172",
    latitude: "25.78307779880944",
    longitude: "-80.36102954864434",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "344",
    access_code: "level-2",
    state: "FL",
    station_name: "Largo Honda",
    street_address: "554 NE 1st Ave",
    city: "Florida City",
    zip: "33034",
    latitude: "25.454924306339475",
    longitude: "-80.47552728357896",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "345",
    access_code: "level-2",
    state: "NV",
    station_name: "Henderson Hyundai Superstore",
    street_address: "460 N Boulder Hwy",
    city: "Henderson",
    zip: "89015",
    latitude: "36.04508175376679",
    longitude: "-114.985725775468",
    num_level_1: "1",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "346",
    access_code: "level-2",
    state: "CA",
    station_name: "Elk Grove Ford",
    street_address: "9645 Auto Center Dr",
    city: "Elk Grove",
    zip: "95757",
    latitude: "38.40738111048901",
    longitude: "-121.39344343309328",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "347",
    access_code: "level-2",
    state: "WI",
    station_name: "Wilde Honda",
    street_address: "1603 E Moreland Blvd",
    city: "Waukesha",
    zip: "53186",
    latitude: "43.78240725013721",
    longitude: "-86.45564728030281",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "348",
    access_code: "level-2",
    state: "WI",
    station_name: "Wilde Subaru",
    street_address: "1710 WI-164",
    city: "Waukesha",
    zip: "53186",
    latitude: "43.02442880494595",
    longitude: "-88.20137693484448",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "349",
    access_code: "level-2",
    state: "WI",
    station_name: "Wilde Chrysler Dodge Jeep Ram",
    street_address: "1710 WI-164 S",
    city: "Waukesha",
    zip: "53186",
    latitude: "43.02434300956259",
    longitude: "-88.2015893329949",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "350",
    access_code: "level-2",
    state: "WI",
    station_name: "Wilde Toyota",
    street_address: "3225 S 108th St",
    city: "West Allis",
    zip: "53227",
    latitude: "42.98463339",
    longitude: "-88.04795421",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "351",
    access_code: "level-2",
    state: "WI",
    station_name: "Wilde East Towne Honda",
    street_address: "5555 High Crossing Blvd",
    city: "Madison",
    zip: "53718",
    latitude: "43.14374881",
    longitude: "-89.28108725",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "352",
    access_code: "level-2",
    state: "TX",
    station_name: "Meador CJD",
    street_address: "9501 South Fwy",
    city: "Fort Worth",
    zip: "76140",
    latitude: "32.61694801",
    longitude: "-97.31876548",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "353",
    access_code: "level-2",
    state: "CA",
    station_name: "Temecula Kia",
    street_address: "26799 Ynez Rd",
    city: "Temecula",
    zip: "92591",
    latitude: "33.513344",
    longitude: "-117.155815",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
  {
    id: "354",
    access_code: "level-2",
    state: "NY",
    station_name: "Prestige Prep Center",
    street_address: "175 Varick St Fl 3",
    city: "New York",
    zip: "10014",
    latitude: "40.727392",
    longitude: "-74.005841",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  }, 
  {
    id: "355",
    access_code: "level-2",
    state: "Canada",
    station_name: "HJ Pfaff Audi",
    street_address: "16775 Leslie St",
    city: "Newmarket",
    zip: "ON L3Y 9A1",
    latitude: "44.048675015316974",
    longitude: "-79.42647563852029",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    ev_dc_fast_num: null,
    exclude_clusters: true,
  },
];
export { DEALERS };
