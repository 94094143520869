import React, {useState, useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

import {ValidateFiveDigitCode} from "../../utils/Helpers/USPostalCodeValidator"; 
import SelectVehicleIdForIncentives from "../InputComponents/SelectVehicleIdForIncentives.js/SelectVehicleIdForIncentives";
import ToolTip from "../shared/ToolTip/ToolTip";

import getClunkerIncentiveNames from "../../functions/incentives/getClunkerIncentiveNames";
import ChooseCanTurnInClunker from "../InputComponents/ChooseCanTurnInClunker/ChooseCanTurnInClunker";

import SelectVehicleAgeFilter from "../InputComponents/SelectVehicleAgeFilter/SelectVehicleAgeFilter";
import SelectVehicleYearFilter from "../InputComponents/SelectVehicleYearFilter/SelectVehicleYearFilter";
import SlideHouseholdIncome from "../InputComponents/SlideHouseholdIncome/SlideHouseholdIncome";
import SelectHouseholdSize from "../InputComponents/SelectHouseholdSize/SelectHouseholdSize";
import ChooseTaxFilingStatus from "../InputComponents/ChooseTaxFilingStatus/ChooseTaxFilingStatus";
import ControlledInputZipcode from "../InputComponents/InputZipcode/ControlledInputZipcode";
import LoadingSpinner from "./../LoadingSpinner/LoadingSpinner";
import {FormattedMessage, useIntl} from 'react-intl';

import "./IncentivePreferences.scss";

const IncentivePreferences = ({
  incentives,
  electricVehicles,
  usedElectricVehicles,
  titleText,
  btnSubtle,
  btnText,
  zipcode
}) => {
  const intl = useIntl()
  const userPrefs = useContext(UserPrefsContext);

  useEffect(() => {
    setWorkingZipcode(zipcode);
  }, [zipcode]);

  const [workingZipcode, setWorkingZipcode] = useState(
    zipcode
  );

  const [vehicleSelected, setVehicleSelected] = useState(false);
  const vehicleMSRPRef = useRef(null);

       const handleFormSubmit = () => {
              const vehicleMSRP = vehicleMSRPRef.current.value;
              let purchasePrice = vehicleMSRP.replace(/\D/g, '');
              let purchaseValue = 0;
              if (purchasePrice !== "") {
                     purchaseValue = parseInt(purchasePrice, 10);
              }
              if (ValidateFiveDigitCode(workingZipcode)) {
                     userPrefs.syncNewData(purchaseValue);
                     userPrefs.toggleIncentivePrefsModal();
              }
       };

  const handleKeyDown = e => {
    if (e.key === "Enter" && !userPrefs.zipcodeIsUpdating) {
      handleFormSubmit();
    }
  };

  const RenderSelectVehicle = () => {
    return electricVehicles && usedElectricVehicles ? (
      <>
        <span className="h3">
          <FormattedMessage 
            id="incentives.vehicleType"
            defaultMessage="Vehicle type"
            description="Vehicle type"
          />
          <ToolTip
            message={intl.formatMessage({ id: "incentives.vehicleTypeTooltip", defaultMessage: "Certain incentives depend on which car you purchase."})}
            id="vehicle_type_tooltip"
          />
        </span>
        <SelectVehicleIdForIncentives setVehicleSelected={setVehicleSelected} electricVehicles={electricVehicles} usedElectricVehicles={usedElectricVehicles} userSetVehicleMSRP={userPrefs.get('vehiclePurchasePrice')} vehicleMSRPRef={vehicleMSRPRef} />
      </>
    ) : (
       <div className="align-center">
              <LoadingSpinner />
       </div>
    );
  };
  const RenderTradeIn = () => {
    return incentives ? (
      <>
        <span className="h5">
          <FormattedMessage 
            id="incentives.tradeIn"
            defaultMessage="Trade-In"
            description="Trade-In"
          />
          <ToolTip
            message={getClunkerIncentiveNames(incentives, intl)}
            id="trade_in_label_tooltip"
          />
        </span>
        <ChooseCanTurnInClunker label={intl.formatMessage({ id: "canTurnInClunker", defaultMessage: "Are you planning to turn in a clunker?"})} />
      </>
    ) : null;
  };

  const RenderAdditionalExtendedPreferences = () => {
    return (process.env.REACT_APP_PAGES_INCENTIVES_SHOW_EXTENDED_PERSONALIZATION) && electricVehicles ? (
      <>
       {vehicleSelected ? (
              <div className="form-col-custom">
                     <div className="col-6 no-padding-left"><SelectVehicleAgeFilter/></div>
                     <div className="col-6 no-padding-right">
                            <SelectVehicleYearFilter
                                   vehicleSelected={vehicleSelected}
                                   electricVehicles={electricVehicles}
                            />
                     </div>
              </div>
      ) : (null)}
              <span className="h5">
          <FormattedMessage 
            id="incentives.income"
            defaultMessage="Income and tax status"
            description="Income and tax status"
          />
          <ToolTip
            message={intl.formatMessage({ id: "incentives.incomeTooltip", defaultMessage: "Incentive eligibility and the amount you can receive often depends on your income. Lower  income customers are typically eligible for higher rebates."})}
            id="income_and_tax_status_label_tooltip"
          />
        </span>
        <ChooseTaxFilingStatus label={intl.formatMessage({ id: "taxFilingStatus", defaultMessage: "Tax Filing Status"})} />
        <SelectHouseholdSize label={intl.formatMessage({ id: "householdSize", defaultMessage: "Household Size"})} />
        <SlideHouseholdIncome label={intl.formatMessage({ id: "householdIncome", defaultMessage: "Household Income"})} />
        <RenderTradeIn />
      </>
      ) : (null)}
  
  const RenderButton = () => {
    let btnTextUpdating = userPrefs.zipcodeIsUpdating
      ? "Updating..."
      : intl.formatMessage({ id: "incentives.incentiveEligibility", defaultMessage: "Update Incentive Eligibility"});

    return (
      <button
        type="button"
        className="btn-ae"
        onClick={() => handleFormSubmit()}
        disabled={userPrefs.zipcodeIsUpdating}
        id="update-zipcode-button"
      >
        {btnTextUpdating}
      </button>
    );
  };

  return (
       <div>
              <button
                     onClick={() => userPrefs.toggleIncentivePrefsModal(true)}
                     className={btnSubtle ? "btn btn-sm" : "btn btn-ae"}
                     type="button"
              >
                     {btnText}
              </button>
              <div className="incentive-details-print-only">
                     <p><FormattedMessage
                     id="zipcode"
                     defaultMessage="Zip code"
                     description="Zip code"
                     />: <strong>{workingZipcode}</strong></p>
                     <p><FormattedMessage
                     id="vehicle"
                     defaultMessage="Vehicle"
                     description="Vehicle"
                     />: <strong>{userPrefs.get("vehicleIdForIncentives")}</strong></p>
                     <p><FormattedMessage
                     id="taxFilingStatus"
                     defaultMessage="Tax filing status"
                     description="Tax filing status"
                     />: <strong>{userPrefs.get("taxFilingStatus")}</strong></p>
                     <p><FormattedMessage
                     id="householdSize"
                     defaultMessage="Household Size"
                     description="Household Size"
                     />: <strong>{userPrefs.get("householdSize").toString()}</strong></p>
                     <p><FormattedMessage
                     id="income"
                     defaultMessage="Income"
                     description="Income"
                     />: <strong>{userPrefs.get("householdIncome")}</strong></p>
                     <p><FormattedMessage
                     id="clunker"
                     defaultMessage="Clunker"
                     description="Clunker"
                     />: <strong>{userPrefs.get("canTurnInClunker").toString()}</strong></p>
              </div>
              <Modal
                     isOpen={userPrefs.showIncentivePrefsModal}
                     toggle={() => userPrefs.toggleIncentivePrefsModal()}
                     unmountOnClose={true}
                     aria-label="Incentive Preferences Modal"
                     title="Incentive Preferences Modal"
              >
              <ModalHeader toggle={userPrefs.toggleIncentivePrefsModal}>
                     {titleText}
              </ModalHeader>
              <ModalBody>
                     <div className="IncentivePreferences">
                            <div className="input-well contained" onKeyDown={handleKeyDown}>
                                   <span className="h3"><FormattedMessage
                                   id="locations"
                                   defaultMessage="Locations"
                                   description="Locations"
                                   />
                                          <ToolTip
                                                 message={intl.formatMessage({ id: "location.tooltip", defaultMessage: "Many incentives depend on where you live. Enter your zipcode to help determine what incentives are available where you live."})}
                                                 id="location_tooltip"
                                          />
                                   </span>
                                   <ControlledInputZipcode
                                          zipcode={workingZipcode}
                                          handleSubmit={e => {
                                                 handleFormSubmit();
                                          }}
                                          workingZipcode={workingZipcode}
                                          setZipcode={e => {
                                                 setWorkingZipcode(e.target.value);
                                                        if(ValidateFiveDigitCode(e.target.value)){
                                                               userPrefs.set({ workingZipcode: e.target.value });
                                                        }
                                                 }
                                          }
                                          id="input-zipcode-for-rate-comp-launch"
                                          isUpdating={userPrefs.zipcodeIsUpdating}
                                          isNotFound={userPrefs.zipcodeIsNotFound}
                                          hideValidateRequest
                                   />
                                   <RenderSelectVehicle />
                                   <RenderAdditionalExtendedPreferences />
                                   <div className="form-group">
                                          <RenderButton />
                                   </div>
                            </div>
                     </div>
              </ModalBody>
              </Modal>
       </div>
  );
};

export default IncentivePreferences;

IncentivePreferences.propTypes = {
  electricVehicles: PropTypes.array,
  titleText: PropTypes.string,
  btnText: PropTypes.string,
  btnSubtle: PropTypes.bool
};
