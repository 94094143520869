
import { gql, GraphQLClient } from 'graphql-request'
const fetchGreencars = async (token) => {
    const endpoint = process.env.REACT_APP_ENVIRONMENT === "production" ? process.env.REACT_APP_GRAPHQL_PRODUCTION : process.env.REACT_APP_GRAPHQL_STAGING
    const graphQLClient = new GraphQLClient(endpoint, {
        headers: {
            "X-Api-Key": process.env.REACT_APP_ENVIRONMENT === "production" ? process.env.REACT_APP_GRAPHQL_X_API_PRODUCTION : process.env.REACT_APP_GRAPHQL_X_API_STAGING,
        },
    })

    let query = gql`
                            {
                                   listGreencarsTakes {
                                          items {
                                                 vehicle_handle
                                                 greencars_take
                                          }
                                          nextToken
                                   }
                            }`

    if (token) {
        query = gql`
                            {
                                   listGreencarsTakes(nextToken: "${token}") {
                                          items {
                                                 vehicle_handle
                                                 greencars_take
                                          }
                                          nextToken
                                   }
                            }`
    }

    const data = await graphQLClient.request(query)
    return data
}

export default fetchGreencars;
