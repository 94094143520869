import "react-app-polyfill/ie11";
import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import Root from "./client_customizations/containers/Root/Root";
import { BrowserRouter as Router } from "react-router-dom";
import {IntlProvider} from "react-intl";
// import * as serviceWorker from "./serviceWorker";
import "./utils/polyfills";
import messages_en from "./client_customizations/translations/en.js";
import messages_esp from "./client_customizations/translations/esp.js";
import ErrorBoundary from "./utils/errorBoundary";

const App = () => {
  const [language, setLanguage] = useState('EN')

  const changeLanguage = (language) => {
    setLanguage(language)
  }

  const messages = {
    'EN': messages_en,
    'ES' : messages_esp
  };

  useEffect(() => {
       const queryParams = new URLSearchParams(window.location.search);
       const lang = queryParams.get('lang');
       if (lang) {
         const upperLang = lang.toUpperCase();
         if (['EN', 'ES'].includes(upperLang)) {
           setLanguage(upperLang);
         }
       }
     }, []);

  return (
    <IntlProvider locale={language} messages={messages[language]} >
      <Router>
        <ErrorBoundary>
          <Root language={language} changeLanguage={changeLanguage}/>
        </ErrorBoundary>
      </Router>
    </IntlProvider>
  )
}

const rootElement = document.getElementById("root") 
ReactDOM.render(<App />, rootElement)

// ReactDOM.render(
//   <IntlProvider locale={language} messages={messages[language]}>
//     <Router>
//         <Root changeLanguage={changeLanguage}/>
//     </Router>
//   </IntlProvider>,
//   document.getElementById("root")
// );
