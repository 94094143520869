import React from "react";
import PropTypes from "prop-types";

import "./Main.scss";

const Main = ({ children }) => {
  return <main className="Main">{children}</main>;
};

export default Main;

Main.propTypes = {
  children: PropTypes.node.isRequired,
};
