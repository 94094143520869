import React, {useEffect} from "react";
import PropTypes from "prop-types";
import "./Incentives.scss";
import IncentiveCatalog from "./../../components/IncentiveCatalog/IncentiveCatalog";
import IncentivePreferences from "./../../components/IncentivesPreferences/IncentivePreferences";
import LoadingSpinner from "./../../components/LoadingSpinner/LoadingSpinner";
import { useIntl, FormattedMessage } from 'react-intl';


const Incentives = ({ electricVehicles, usedElectricVehicles, incentives, ip, uuid, zipcode }) => {
  useEffect(() => {
    document.title = process.env.REACT_APP_PAGES_INCENTIVES_TITLE;
  });

  const intl = useIntl()

  const renderIncentives = incentives ? (
    <IncentiveCatalog incentives={incentives} category="all" />
  ) : (
    <LoadingSpinner />
  );

  const printIncentives = () => {
    window.print();
  }


  return (
    <>
      <section className="container incentives-page">
        <div className="row">
          <div className="col-sm-12">
            <IncentivePreferences
              incentives={incentives}
              electricVehicles={electricVehicles}
              usedElectricVehicles={usedElectricVehicles}
              titleText={
                intl.formatMessage
                  ? intl.formatMessage({
                      id: "personalizeIncentives",
                      defaultMessage: "Personalize Incentives",
                    })
                  : "Personalize Incentives"
              }
              btnText={
                intl.formatMessage
                  ? intl.formatMessage({
                      id: "personalizeIncentives",
                      defaultMessage: "Personalize Incentives",
                    })
                  : "Personalize Incentives"
              }
              zipcode={zipcode}
            />
            <div className="print-button" onClick={() => printIncentives()}>
              <span><FormattedMessage 
              id="print.save"
              defaultMessage="Print/Save"
              description="Print/Save"
              /></span>
            </div>
          </div>
        </div>
        <br />
        {renderIncentives}
        <div className="incentives-disclaimer">
              <p><FormattedMessage
              id="footer.disclaimer"
              defaultMessage="Figures shown on this page are estimates based on data provided by third-parties. This material is provided for informational purposes only and should not be relied on for tax, legal, or financial advice.  Neither J.D. Power® nor GreenCars provides tax, legal or financial advice. You should independently verify the estimates provided here before engaging in any transaction."
              description="Footer Disclaimer"
              /></p>
        </div>
      </section>
    </>
  );
};

export default Incentives;

Incentives.propTypes = {
  incentives: PropTypes.array,
  electricVehicles: PropTypes.array,
  zip: PropTypes.string,
  ip: PropTypes.string,
  uuid: PropTypes.string
};
