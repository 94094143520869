export const VALID_CHARGING_STATIONS = [
  {
    id: "4",
    station_name: "Medford CJD",
    region: "USA",
    complete_address: "4540 Grumman Dr, Medford, OR 97504",
    street_address: "4540 Grumman Dr",
    city: "Medford",
    state: "OR",
    zip: "97504",
    latitude: "42.378089",
    longitude: "-122.858823",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "6",
    station_name: "Medford Toyota",
    region: "USA",
    complete_address: "1420 N Riverside Ave., Medford, OR 97501",
    street_address: "1420 N Riverside Ave.",
    city: "Medford",
    state: "OR",
    zip: "97501",
    latitude: "42.339931",
    longitude: "-122.876952",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "9",
    station_name: "Medford Honda",
    region: "USA",
    complete_address: "4095 Crater Lake Hwy, Medford, OR 97504",
    street_address: "4095 Crater Lake Hwy",
    city: "Medford",
    state: "OR",
    zip: "97504",
    latitude: "42.375901",
    longitude: "-122.855944",
    num_level_1: "1",
    num_level_2: "0",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "11",
    station_name: "Midland Chevrolet",
    region: "USA",
    complete_address: "4100 W. Wall St., Midland, TX 79703",
    street_address: "4100 W. Wall St.",
    city: "Midland",
    state: "TX",
    zip: "79703",
    latitude: "31.97275",
    longitude: "-102.120177",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "15",
    station_name: "Grants Pass CJD",
    region: "USA",
    complete_address: "1421 NE 6th St., Grants Pass, OR 97526",
    street_address: "1421 NE 6th St.",
    city: "Grants Pass",
    state: "OR",
    zip: "97526",
    latitude: "42.453621",
    longitude: "-123.321938",
    num_level_1: "1",
    num_level_2: "0",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "19",
    station_name: "Klamath Falls Toyota",
    region: "USA",
    complete_address: "2121 Washburn Way, Klamath Falls, OR 97603",
    street_address: "2121 Washburn Way",
    city: "Klamath Falls",
    state: "OR",
    zip: "97603",
    latitude: "42.207595",
    longitude: "-121.757163",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "20",
    station_name: "Klamath Falls CJD",
    region: "USA",
    complete_address: "2675 Washburn Way, Klamath Falls, OR 97603",
    street_address: "2675 Washburn Way",
    city: "Klamath Falls",
    state: "OR",
    zip: "97603",
    latitude: "42.20326",
    longitude: "-121.757157",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "23",
    station_name: "Fresno Ford Lincoln",
    region: "USA",
    complete_address: "195 E Auto Center Dr., Fresno, CA 93710",
    street_address: "195 E Auto Center Dr.",
    city: "Fresno",
    state: "CA",
    zip: "93710",
    latitude: "36.821886",
    longitude: "-119.786972",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "26",
    station_name: "Odessa Chevrolet",
    region: "USA",
    complete_address: "5020 John Ben Shepperd Pkwy, Odessa, TX 79762",
    street_address: "5020 John Ben Shepperd Pkwy",
    city: "Odessa",
    state: "TX",
    zip: "79762",
    latitude: "31.899715",
    longitude: "-102.336027",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "27",
    station_name: "Fresno Nissan",
    region: "USA",
    complete_address: "5580 N. Blackstone Ave., Fresno, CA 93710",
    street_address: "5580 N. Blackstone Ave.",
    city: "Fresno",
    state: "CA",
    zip: "93710",
    latitude: "36.81882",
    longitude: "-119.790301",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "29",
    station_name: "Lythia Hundai of Fresno",
    region: "USA",
    complete_address: "5590 N. Blackstone ave. Fresno, CA 93710",
    street_address: "5590 N. Blackstone ave. Fresno",
    city: "CA 93710",
    state: "",
    zip: "",
    latitude: "36.81877",
    longitude: "-119.78834",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "34",
    station_name: "Reno Volkswagen",
    region: "USA",
    complete_address: "1050 E. Plumb Ln. #110, Reno, NV 89502",
    street_address: "1050 E. Plumb Ln. #110",
    city: "Reno",
    state: "NV",
    zip: "89502",
    latitude: "39.505760",
    longitude: "-119.786490",
    num_level_1: "1",
    num_level_2: "0",
    num_level_3: "1",
    front_of_house: "2",
  },
  {
    id: "51",
    station_name: "Spokane Chevrolet Cadillac",
    region: "USA",
    complete_address: "101 E. Montgomery Ave, Spokane, WA 99207",
    street_address: "101 E. Montgomery Ave",
    city: "Spokane",
    state: "WA",
    zip: "99207",
    latitude: "47.678298",
    longitude: "-117.408728",
    num_level_1: "4",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "5",
  },
  {
    id: "52",
    station_name: "Spokane BMW",
    region: "USA",
    complete_address: "215 E. Montgomery Ave, Spokane, WA 99207",
    street_address: "215 E. Montgomery Ave",
    city: "Spokane",
    state: "WA",
    zip: "99207",
    latitude: "47.67883",
    longitude: "-117.40748",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "56",
    station_name: "Tri-Cities CJD",
    region: "USA",
    complete_address: "7171 W Canal Dr. Kennewick, WA 99336",
    street_address: "7171 W Canal Dr. Kennewick",
    city: "WA 99336",
    state: "",
    zip: "",
    latitude: "46.226737",
    longitude: "-119.217317",
    num_level_1: "1",
    num_level_2: "0",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "57",
    station_name: "Redding Chevrolet",
    region: "USA",
    complete_address: "200 E. Cypress Ave, Redding, CA 96002",
    street_address: "200 E. Cypress Ave",
    city: "Redding",
    state: "CA",
    zip: "96002",
    latitude: "40.571087",
    longitude: "-122.36621",
    num_level_1: "1",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "59",
    station_name: "Eugene CJD Fiat",
    region: "USA",
    complete_address: "2121 Martin Luther King Jr Blvd, Eugene, OR 97401",
    street_address: "2121 Martin Luther King Jr Blvd",
    city: "Eugene",
    state: "OR",
    zip: "97401",
    latitude: "44.05958",
    longitude: "-123.0786",
    num_level_1: "1",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "60",
    station_name: "Eugene Nissan",
    region: "USA",
    complete_address: "2060 Martin Luther King Jr. Blvd., Eugene, OR 97401",
    street_address: "2060 Martin Luther King Jr. Blvd.",
    city: "Eugene",
    state: "OR",
    zip: "97401",
    latitude: "44.05958",
    longitude: "-123.081363",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "61",
    station_name: "Roseburg Ford Lincoln",
    region: "USA",
    complete_address: "1650 NE Stephens St, Roseburg, OR 97470",
    street_address: "1650 NE Stephens St",
    city: "Roseburg",
    state: "OR",
    zip: "97470",
    latitude: "43.230005",
    longitude: "-123.350758",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "63",
    station_name: "Roseburg CJD",
    region: "USA",
    complete_address: "1600 NE Airport Rd, Roseburg, OR 97470",
    street_address: "1600 NE Airport Rd",
    city: "Roseburg",
    state: "OR",
    zip: "97470",
    latitude: "43.229156",
    longitude: "-123.354292",
    num_level_1: "1",
    num_level_2: "0",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "65",
    station_name: "Springfield Toyota",
    region: "USA",
    complete_address: "163 S 9th St., Springfield, OR 97477",
    street_address: "163 S 9th St.",
    city: "Springfield",
    state: "OR",
    zip: "97477",
    latitude: "44.045388",
    longitude: "-123.01398",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "72",
    station_name: "Boise Ford Lincoln",
    region: "USA",
    complete_address: "8853 W. Fairview Ave., Boise, ID 83704",
    street_address: "8853 W. Fairview Ave.",
    city: "Boise",
    state: "ID",
    zip: "83704",
    latitude: "43.61945",
    longitude: "-116.293187",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "99",
    station_name: "Pocatello CJD",
    region: "USA",
    complete_address: "633 Bullock St., Pocatello, ID 83201",
    street_address: "633 Bullock St.",
    city: "Pocatello",
    state: "ID",
    zip: "83201",
    latitude: "42.910361",
    longitude: "-112.460621",
    num_level_1: "1",
    num_level_2: "0",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "110",
    station_name: "Seattle BMW",
    region: "USA",
    complete_address: "1002 Airport Way S, Seattle, WA 98134",
    street_address: "1002 Airport Way S",
    city: "Seattle",
    state: "WA",
    zip: "98134",
    latitude: "47.593549",
    longitude: "-122.324768",
    num_level_1: "0",
    num_level_2: "4",
    num_level_3: "2",
    front_of_house: "6",
  },
  {
    id: "113",
    station_name: "Oregon City Subaru",
    region: "USA",
    complete_address: "1404 Main St, Oregon City, OR 97045",
    street_address: "1404 Main St",
    city: "Oregon City",
    state: "OR",
    zip: "97045",
    latitude: "45.362649",
    longitude: "-122.601308",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "114",
    station_name: "San Angelo Chevrolet",
    region: "USA",
    complete_address: "203 Bryant Blvd. N, San Angelo, TX 76903",
    street_address: "203 Bryant Blvd. N",
    city: "San Angelo",
    state: "TX",
    zip: "76903",
    latitude: "31.46376",
    longitude: "-100.445287",
    num_level_1: "2",
    num_level_2: "0",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "116",
    station_name: "San Angelo CJD",
    region: "USA",
    complete_address: "4310 Sherwood Way, San Angelo, TX 76901",
    street_address: "4310 Sherwood Way",
    city: "San Angelo",
    state: "TX",
    zip: "76901",
    latitude: "31.437106",
    longitude: "-100.494499",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "121",
    station_name: "Odessa CJD",
    region: "USA",
    complete_address: "2510 E. 8th St., Odessa, TX 79761",
    street_address: "2510 E. 8th St.",
    city: "Odessa",
    state: "TX",
    zip: "79761",
    latitude: "31.86368",
    longitude: "-102.334061",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "124",
    station_name: "Midland CJD",
    region: "USA",
    complete_address: "3801 W Wall St, Midland, TX 79703",
    street_address: "3801 W Wall St",
    city: "Midland",
    state: "TX",
    zip: "79703",
    latitude: "31.9761264",
    longitude: "-102.114058",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "127",
    station_name: "Anchorage Hyundai",
    region: "USA",
    complete_address: "5400 Old Seward Hwy, Anchorage, AK 99518",
    street_address: "5400 Old Seward Hwy",
    city: "Anchorage",
    state: "AK",
    zip: "99518",
    latitude: "61.1719917",
    longitude: "-149.868551",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "131",
    station_name: "Twin Falls Chevrolet",
    region: "USA",
    complete_address: "1654 Blue Lakes Blvd No, Twin Falls, ID 83301",
    street_address: "1654 Blue Lakes Blvd No",
    city: "Twin Falls",
    state: "ID",
    zip: "83301",
    latitude: "42.5911752",
    longitude: "-114.458938",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "134",
    station_name: "Missoula CJD",
    region: "USA",
    complete_address: "5001 Grizzly Ct., Missoula, MT 59808",
    street_address: "5001 Grizzly Ct.",
    city: "Missoula",
    state: "MT",
    zip: "59808",
    latitude: "46.917870",
    longitude: "-114.072891",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "138",
    station_name: "Billings CJD",
    region: "USA",
    complete_address: "2229 King Ave W, Billings, MT 59102",
    street_address: "2229 King Ave W",
    city: "Billings",
    state: "MT",
    zip: "59102",
    latitude: "45.755644",
    longitude: "-108.572483",
    num_level_1: "1",
    num_level_2: "0",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "140",
    station_name: "Santa Rosa CJD",
    region: "USA",
    complete_address: "2727 Dowd Dr., Santa Rosa, CA 95407",
    street_address: "2727 Dowd Dr.",
    city: "Santa Rosa",
    state: "CA",
    zip: "95407",
    latitude: "38.410812",
    longitude: "-122.719479",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "143",
    station_name: "Fairbanks Chev Buick GMC",
    region: "USA",
    complete_address: "3300 S Cushman St., Fairbanks, AK 99701",
    street_address: "3300 S Cushman St.",
    city: "Fairbanks",
    state: "AK",
    zip: "99701",
    latitude: "64.815505",
    longitude: "-147.71075",
    num_level_1: "2",
    num_level_2: "0",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "145",
    station_name: "Reno CJ",
    region: "USA",
    complete_address: "1050 E. Plumb Ln. #100, Reno, NV 89502",
    street_address: "1050 E. Plumb Ln. #100",
    city: "Reno",
    state: "NV",
    zip: "89502",
    latitude: "39.505814",
    longitude: "-119.786356",
    num_level_1: "2",
    num_level_2: "0",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "146",
    station_name: "Helena Chevrolet Buick GMC",
    region: "USA",
    complete_address: "1501 Cedar St., Helena, MT 59601",
    street_address: "1501 Cedar St.",
    city: "Helena",
    state: "MT",
    zip: "59601",
    latitude: "46.606756",
    longitude: "-112.014606",
    num_level_1: "1",
    num_level_2: "1",
    num_level_3: "1",
    front_of_house: "3",
  },
  {
    id: "148",
    station_name: "Anchorage Chevrolet",
    region: "USA",
    complete_address: "9100 Old Seward Hwy, Anchorage, AK 99515",
    street_address: "9100 Old Seward Hwy",
    city: "Anchorage",
    state: "AK",
    zip: "99515",
    latitude: "61.138047",
    longitude: "-149.864216",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "149",
    station_name: "Wasilla Chevrolet",
    region: "USA",
    complete_address: "3700 E. Parks Hwy, Wasilla, AK 99654",
    street_address: "3700 E. Parks Hwy",
    city: "Wasilla",
    state: "AK",
    zip: "99654",
    latitude: "61.571630",
    longitude: "-149.381830",
    num_level_1: "0",
    num_level_2: "3",
    num_level_3: "1",
    front_of_house: "4",
  },
  {
    id: "152",
    station_name: "Great Falls CJD",
    region: "USA",
    complete_address: "4025 10th  Ave. S, Great Falls, MT 59405",
    street_address: "4025 10th  Ave. S",
    city: "Great Falls",
    state: "MT",
    zip: "59405",
    latitude: "47.494006",
    longitude: "-111.236417",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "153",
    station_name: "Great Falls Honda",
    region: "USA",
    complete_address: "4900 10th Ave S., Great Falls, MT 59405",
    street_address: "4900 10th Ave S.",
    city: "Great Falls",
    state: "MT",
    zip: "59405",
    latitude: "47.493821",
    longitude: "-111.224989",
    num_level_1: "1",
    num_level_2: "0",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "154",
    station_name: "Anchorage BMW Mini",
    region: "USA",
    complete_address: "730 E. 5th Ave., Anchorage, AK 99501",
    street_address: "730 E. 5th Ave.",
    city: "Anchorage",
    state: "AK",
    zip: "99501",
    latitude: "61.21722",
    longitude: "-149.86948",
    num_level_1: "3",
    num_level_2: "0",
    num_level_3: "0",
    front_of_house: "3",
  },
  {
    id: "155",
    station_name: "Santa Fe CJD",
    region: "USA",
    complete_address: "7401 Cerrillos Rd., Santa Fe NM 87507",
    street_address: "7401 Cerrillos Rd.",
    city: "Santa Fe NM 87507",
    state: "",
    zip: "",
    latitude: "35.623830",
    longitude: "-106.028360",
    num_level_1: "1",
    num_level_2: "0",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "157",
    station_name: "Helena CJD",
    region: "USA",
    complete_address: "3401 US-12 E, Helena, MT 59601",
    street_address: "3401 US-12 E",
    city: "Helena",
    state: "MT",
    zip: "59601",
    latitude: "46.590260",
    longitude: "-111.965149",
    num_level_1: "1",
    num_level_2: "1",
    num_level_3: "1",
    front_of_house: "3",
  },
  {
    id: "159",
    station_name: "Eureka CJD",
    region: "USA",
    complete_address: "4320 Broadway St., Eureka, CA 95503",
    street_address: "4320 Broadway St.",
    city: "Eureka",
    state: "CA",
    zip: "95503",
    latitude: "40.766435",
    longitude: "-124.191589",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "163",
    station_name: "Abilene Honda",
    region: "USA",
    complete_address: "1399 S. Danville Dr., Abilene, TX 79605",
    street_address: "1399 S. Danville Dr.",
    city: "Abilene",
    state: "TX",
    zip: "79605",
    latitude: "34.428453",
    longitude: "-99.785924",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "169",
    station_name: "Corpus Christi CJD",
    region: "USA",
    complete_address: "4313 S. Staples St., Corpus Christi, TX 78411",
    street_address: "4313 S. Staples St.",
    city: "Corpus Christi",
    state: "TX",
    zip: "78411",
    latitude: "27.72207",
    longitude: "-97.376242",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "173",
    station_name: "Bryan CJD Fiat",
    region: "USA",
    complete_address: "301 N. Earl Rudder Fwy, Bryan, TX 77802",
    street_address: "301 N. Earl Rudder Fwy",
    city: "Bryan",
    state: "TX",
    zip: "77802",
    latitude: "30.655149",
    longitude: "-96.322064",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "176",
    station_name: "Grand Forks Ford Lincoln",
    region: "USA",
    complete_address: "2273 32nd Ave S. Grand Forks, ND 58201",
    street_address: "2273 32nd Ave S. Grand Forks",
    city: "ND 58201",
    state: "",
    zip: "",
    latitude: "47.887620",
    longitude: "-97.057600",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "178",
    station_name: "Grand Forks CJD",
    region: "USA",
    complete_address: "2373 32nd Ave. S., Grand Forks, ND 58201",
    street_address: "2373 32nd Ave. S.",
    city: "Grand Forks",
    state: "ND",
    zip: "58201",
    latitude: "47.887700",
    longitude: "-97.059200",
    num_level_1: "4",
    num_level_2: "0",
    num_level_3: "0",
    front_of_house: "4",
  },
  {
    id: "180",
    station_name: "Des Moines BMW",
    region: "USA",
    complete_address: "9631 Hickman Rd., Urbandale, IA 50322",
    street_address: "9631 Hickman Rd.",
    city: "Urbandale",
    state: "IA",
    zip: "50322",
    latitude: "41.615192",
    longitude: "-93.75331",
    num_level_1: "2",
    num_level_2: "3",
    num_level_3: "0",
    front_of_house: "5",
  },
  {
    id: "181",
    station_name: "Des Moines Mercedes Sprinter Smart",
    region: "USA",
    complete_address: "9993 Hickman Rd., Urbandale, IA 50322",
    street_address: "9993 Hickman Rd.",
    city: "Urbandale",
    state: "IA",
    zip: "50322",
    latitude: "41.61564",
    longitude: "-93.755429",
    num_level_1: "0",
    num_level_2: "3",
    num_level_3: "1",
    front_of_house: "4",
  },
  {
    id: "183",
    station_name: "Monterey BMW",
    region: "USA",
    complete_address: "1 Geary Plaza, Seaside, CA 93955",
    street_address: "1 Geary Plaza",
    city: "Seaside",
    state: "CA",
    zip: "93955",
    latitude: "36.614156",
    longitude: "-121.843944",
    num_level_1: "3",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "5",
  },
  {
    id: "184",
    station_name: "Monterey Porsche",
    region: "USA",
    complete_address: "1781 Del Monte Blvd, Seaside, CA 93955",
    street_address: "1781 Del Monte Blvd",
    city: "Seaside",
    state: "CA",
    zip: "93955",
    latitude: "36.615532",
    longitude: "-121.844273",
    num_level_1: "5",
    num_level_2: "2",
    num_level_3: "1",
    front_of_house: "8",
  },
  {
    id: "190",
    station_name: "Ames Honda Nissan",
    region: "USA",
    complete_address: "2901 S. Duff Ave. Ames, IA 50010",
    street_address: "2901 S. Duff Ave. Ames",
    city: "IA 50010",
    state: "",
    zip: "",
    latitude: "41.997327",
    longitude: "-93.610192",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "193",
    station_name: "Des Moines Audi",
    region: "USA",
    complete_address: "5190 Merle Hay Rd., Johnston, IA 50131",
    street_address: "5190 Merle Hay Rd.",
    city: "Johnston",
    state: "IA",
    zip: "50131",
    latitude: "41.654662",
    longitude: "-93.697749",
    num_level_1: "4",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "6",
  },
  {
    id: "215",
    station_name: "Anchorage Kia",
    region: "USA",
    complete_address: "4434 Old Seward Hwy, Anchorage, AK 99503",
    street_address: "4434 Old Seward Hwy",
    city: "Anchorage",
    state: "AK",
    zip: "99503",
    latitude: "61.18036",
    longitude: "-149.86934",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "218",
    station_name: "Bend Chevrolet Cadillac",
    region: "USA",
    complete_address: "345 NE 3rd St., Bend OR 97701",
    street_address: "345 NE 3rd St.",
    city: "Bend OR 97701",
    state: "",
    zip: "",
    latitude: "44.053698",
    longitude: "-121.302523",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "1",
    front_of_house: "1",
  },
  {
    id: "219",
    station_name: "Bend Honda",
    region: "USA",
    complete_address: "2225 NE HWY 20 BEND OR 97701",
    street_address: "2225 NE HWY 20 BEND OR 97701",
    city: "",
    state: "",
    zip: "",
    latitude: "44.05494",
    longitude: "-121.269231",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "223",
    station_name: "Portland Mercedes Smart",
    region: "USA",
    complete_address: "1605 SW Naito Pkwy, Portland, OR 97201",
    street_address: "1605 SW Naito Pkwy",
    city: "Portland",
    state: "OR",
    zip: "97201",
    latitude: "45.511436",
    longitude: "-122.675949",
    num_level_1: "1",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "3",
  },
  {
    id: "224",
    station_name: "Portland BMW",
    region: "USA",
    complete_address: "2001 SW Jefferson St, Portland, OR 97201",
    street_address: "2001 SW Jefferson St",
    city: "Portland",
    state: "OR",
    zip: "97201",
    latitude: "45.518858",
    longitude: "-122.695993",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "1",
    front_of_house: "1",
  },
  {
    id: "225",
    station_name: "Portland Mini",
    region: "USA",
    complete_address: "9134 SW Canyon Rd., Portland, OR 97225",
    street_address: "9134 SW Canyon Rd.",
    city: "Portland",
    state: "OR",
    zip: "97225",
    latitude: "45.495894",
    longitude: "-122.771212",
    num_level_1: "2",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "4",
  },
  {
    id: "227",
    station_name: "Klamath Falls Ford",
    region: "USA",
    complete_address: "2833 Washburn Way, Klamath Falls, OR 97603",
    street_address: "2833 Washburn Way",
    city: "Klamath Falls",
    state: "OR",
    zip: "97603",
    latitude: "42.201075",
    longitude: "-121.757153",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "228",
    station_name: "Fresno Subaru",
    region: "USA",
    complete_address: "5200 N. Blackstone Ave., Fresno, CA 93710",
    street_address: "5200 N. Blackstone Ave.",
    city: "Fresno",
    state: "CA",
    zip: "93710",
    latitude: "36.812715",
    longitude: "-119.79034",
    num_level_1: "1",
    num_level_2: "0",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "230",
    station_name: "Beaverton Mercedes",
    region: "USA",
    complete_address: "9275 SW Canyon Rd, Portland, OR 97225",
    street_address: "9275 SW Canyon Rd",
    city: "Portland",
    state: "OR",
    zip: "97225",
    latitude: "45.495432",
    longitude: "-122.772079",
    num_level_1: "0",
    num_level_2: "4",
    num_level_3: "0",
    front_of_house: "4",
  },
  {
    id: "236",
    station_name: "Killeen Chevrolet",
    region: "USA",
    complete_address: "1802 E. Central Texas Expy, Killeen, TX 76541",
    street_address: "1802 E. Central Texas Expy",
    city: "Killeen",
    state: "TX",
    zip: "76541",
    latitude: "31.092316",
    longitude: "-97.725264",
    num_level_1: "2",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "3",
  },
  {
    id: "240",
    station_name: "Salem BMW",
    region: "USA",
    complete_address: "1151 Van Ness Ave NE, Salem, OR 97301",
    street_address: "1151 Van Ness Ave NE",
    city: "Salem",
    state: "OR",
    zip: "97301",
    latitude: "44.971442",
    longitude: "-123.018337",
    num_level_1: "1",
    num_level_2: "3",
    num_level_3: "0",
    front_of_house: "4",
  },
  {
    id: "241",
    station_name: "Salem Volkswagen",
    region: "USA",
    complete_address: "3335 Del Webb Ave NE, Salem, OR 97301",
    street_address: "3335 Del Webb Ave NE",
    city: "Salem",
    state: "OR",
    zip: "97301",
    latitude: "44.97285",
    longitude: "-123.017525",
    num_level_1: "0",
    num_level_2: "3",
    num_level_3: "2",
    front_of_house: "5",
  },
  {
    id: "247",
    station_name: "Lodi Toyota",
    region: "USA",
    complete_address: "1020 S Beckman Rd., Lodi, C A95240",
    street_address: "1020 S Beckman Rd.",
    city: "Lodi",
    state: "C",
    zip: "A95240",
    latitude: "38.121221",
    longitude: "-121.256759",
    num_level_1: "2",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "3",
  },
  {
    id: "253",
    station_name: "Honolulu Buick GMC Cadillac",
    region: "USA",
    complete_address: "2945 N Nimitz Hwy, Honolulu, HI 96819",
    street_address: "2945 N Nimitz Hwy",
    city: "Honolulu",
    state: "HI",
    zip: "96819",
    latitude: "21.33504",
    longitude: "-157.90526",
    num_level_1: "1",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "254",
    station_name: "Honolulu Volkswagen",
    region: "USA",
    complete_address: "2881 N NIMITZ Hwy, Honlulu, HI 96819",
    street_address: "2881 N NIMITZ Hwy",
    city: "Honlulu",
    state: "HI",
    zip: "96819",
    latitude: "21.335159",
    longitude: "-157.903152",
    num_level_1: "1",
    num_level_2: "3",
    num_level_3: "0",
    front_of_house: "4",
  },
  {
    id: "256",
    station_name: "Corpus Christi Ford Lincoln",
    region: "USA",
    complete_address: "3680 US-77, Corpus Christi, TX 78410",
    street_address: "3680 US-77",
    city: "Corpus Christi",
    state: "TX",
    zip: "78410",
    latitude: "27.84678",
    longitude: "-97.631376",
    num_level_1: "0",
    num_level_2: "3",
    num_level_3: "0",
    front_of_house: "3",
  },
  {
    id: "257",
    station_name: "Portland Cadillac",
    region: "USA",
    complete_address: "9141 SW Canyon Rd, Portland, OR 97225",
    street_address: "9141 SW Canyon Rd",
    city: "Portland",
    state: "OR",
    zip: "97225",
    latitude: "45.496399",
    longitude: "-122.77026",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "259",
    station_name: "Clovis Nissan",
    region: "USA",
    complete_address: "370 W. Herndon Ave., Clovis, CA 93612",
    street_address: "370 W. Herndon Ave.",
    city: "Clovis",
    state: "CA",
    zip: "93612",
    latitude: "36.837511",
    longitude: "-119.716988",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "267",
    station_name: "Missoula Ford",
    region: "USA",
    complete_address: "3943 Brooks St, Missoula, MT 59804",
    street_address: "3943 Brooks St",
    city: "Missoula",
    state: "MT",
    zip: "59804",
    latitude: "46.83494",
    longitude: "-114.044175",
    num_level_1: "1",
    num_level_2: "0",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "271",
    station_name: "Great Falls Subaru",
    region: "USA",
    complete_address: "800 Central Ave., Great Falls, MT 59401",
    street_address: "800 Central Ave.",
    city: "Great Falls",
    state: "MT",
    zip: "59401",
    latitude: "47.5050301",
    longitude: "-111.2935054",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "275",
    station_name: "Spokane CJD Fiat",
    region: "USA",
    complete_address: "10701 N Newport Hwy, Spokane, WA 99218",
    street_address: "10701 N Newport Hwy",
    city: "Spokane",
    state: "WA",
    zip: "99218",
    latitude: "47.754624",
    longitude: "-117.397271",
    num_level_1: "2",
    num_level_2: "0",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "279",
    station_name: "Casper Ford",
    region: "USA",
    complete_address: "3333 Cy Ave, Casper, WY 82604",
    street_address: "3333 Cy Ave",
    city: "Casper",
    state: "WY",
    zip: "82604",
    latitude: "42.82503",
    longitude: "-106.36117",
    num_level_1: "2",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "3",
  },
  {
    id: "280",
    station_name: "Honolulu Ford",
    region: "USA",
    complete_address: "1370 N King St., Honlulu, HI 96817",
    street_address: "1370 N King St.",
    city: "Honlulu",
    state: "HI",
    zip: "96817",
    latitude: "21.32893",
    longitude: "-157.87057",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "289",
    station_name: "Wexford Chevrolet Cadillac",
    region: "USA",
    complete_address: "10430 Perry Hwy, Wexford, PA 15090",
    street_address: "10430 Perry Hwy",
    city: "Wexford",
    state: "PA",
    zip: "15090",
    latitude: "40.60675",
    longitude: "-80.05177",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "295",
    station_name: "Zelienople Ford",
    region: "USA",
    complete_address: "540 S. Main St., Zelienople, PA 16063",
    street_address: "540 S. Main St.",
    city: "Zelienople",
    state: "PA",
    zip: "16063",
    latitude: "40.786729",
    longitude: "-80.135193",
    num_level_1: "1",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "301",
    station_name: "Portland CJD",
    region: "USA",
    complete_address: "633 NE 12th Ave, Portland, OR 97232",
    street_address: "633 NE 12th Ave",
    city: "Portland",
    state: "OR",
    zip: "97232",
    latitude: "45.527435",
    longitude: "-122.653587",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "304",
    station_name: "Downtown LA Mercedes-Benz",
    region: "USA",
    complete_address: "1801 S Figueroa St., Los Angeles, CA 90015",
    street_address: "1801 S Figueroa St.",
    city: "Los Angeles",
    state: "CA",
    zip: "90015",
    latitude: "34.03543",
    longitude: "-118.271349",
    num_level_1: "3",
    num_level_2: "2",
    num_level_3: "2",
    front_of_house: "7",
  },
  {
    id: "306",
    station_name: "Downtown LA Volkswagen",
    region: "USA",
    complete_address: "1900 S. Figueroa St., Los Angeles, CA 90007",
    street_address: "1900 S. Figueroa St.",
    city: "Los Angeles",
    state: "CA",
    zip: "90007",
    latitude: "34.033916",
    longitude: "-118.272366",
    num_level_1: "2",
    num_level_2: "4",
    num_level_3: "0",
    front_of_house: "6",
  },
  {
    id: "307",
    station_name: "Downtown LA Audi",
    region: "USA",
    complete_address: "1900 S. Figueroa St., Los Angeles, CA 90007",
    street_address: "1900 S. Figueroa St.",
    city: "Los Angeles",
    state: "CA",
    zip: "90007",
    latitude: "34.033916",
    longitude: "-118.272366",
    num_level_1: "6",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "8",
  },
  {
    id: "308",
    station_name: "Downtown LA Porsche",
    region: "USA",
    complete_address: "1900 S. Figueroa St., Los Angeles, CA 90007",
    street_address: "1900 S. Figueroa St.",
    city: "Los Angeles",
    state: "CA",
    zip: "90007",
    latitude: "34.033916",
    longitude: "-118.272366",
    num_level_1: "2",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "4",
  },
  {
    id: "309",
    station_name: "Downtown LA Toyota",
    region: "USA",
    complete_address: "1901 S. Figuero St., Los Angeles, CA 90007",
    street_address: "1901 S. Figuero St.",
    city: "Los Angeles",
    state: "CA",
    zip: "90007",
    latitude: "34.033918",
    longitude: "-118.272364",
    num_level_1: "0",
    num_level_2: "3",
    num_level_3: "0",
    front_of_house: "3",
  },
  {
    id: "310",
    station_name: "Downtown LA Nissan",
    region: "USA",
    complete_address: "635 W Washington Blvd., Los Angeles, CA 90015",
    street_address: "635 W Washington Blvd.",
    city: "Los Angeles",
    state: "CA",
    zip: "90015",
    latitude: "34.03501",
    longitude: "-118.273416",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "311",
    station_name: "Carson Nissan",
    region: "USA",
    complete_address: "1505 E. 223rd St, Carson, Carson, CA 90745",
    street_address: "1505 E. 223rd St",
    city: "Carson",
    state: "Carson",
    zip: "",
    latitude: "33.82431",
    longitude: "-118.245712",
    num_level_1: "6",
    num_level_2: "2",
    num_level_3: "1",
    front_of_house: "9",
  },
  {
    id: "316",
    station_name: "Medford Ford Mazda",
    region: "USA",
    complete_address: "2611 Biddle Rd, Medford, OR 97504",
    street_address: "2611 Biddle Rd",
    city: "Medford",
    state: "OR",
    zip: "97504",
    latitude: "42.355533",
    longitude: "-122.875641",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "318",
    station_name: "Albany CJDR",
    region: "USA",
    complete_address: "926 Central Ave., Albany, NY 12205",
    street_address: "926 Central Ave.",
    city: "Albany",
    state: "NY",
    zip: "12205",
    latitude: "42.6813",
    longitude: "-73.79143",
    num_level_1: "1",
    num_level_2: "0",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "323",
    station_name: "Monroeville Ford",
    region: "USA",
    complete_address: "3696 William Penn Hwy, Monroeville, PA 15146",
    street_address: "3696 William Penn Hwy",
    city: "Monroeville",
    state: "PA",
    zip: "15146",
    latitude: "40.4339483",
    longitude: "-79.7961394",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "324",
    station_name: "South Hills Subaru",
    region: "USA",
    complete_address: "3260 Washington Rd. McMurray, PA 15317",
    street_address: "3260 Washington Rd. McMurray",
    city: "PA 15317",
    state: "",
    zip: "",
    latitude: "40.289629",
    longitude: "-80.111976",
    num_level_1: "1",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "327",
    station_name: "Apollo Moon Township VW",
    region: "USA",
    complete_address: "5252 University Blvd., Moon, PA 15108",
    street_address: "5252 University Blvd.",
    city: "Moon",
    state: "PA",
    zip: "15108",
    latitude: "40.526429",
    longitude: "-80.209258",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "330",
    station_name: "Idaho Falls Ford",
    region: "USA",
    complete_address: "980 W. Broadway St., Idaho Falls, ID 83402",
    street_address: "980 W. Broadway St.",
    city: "Idaho Falls",
    state: "ID",
    zip: "83402",
    latitude: "43.494946",
    longitude: "-112.049207",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "331",
    station_name: "Calallen CJDR",
    region: "USA",
    complete_address: "3688 I-69 Access Rd, Corpus Christi, TX 78410",
    street_address: "3688 I-69 Access Rd",
    city: "Corpus Christi",
    state: "TX",
    zip: "78410",
    latitude: "27.860932",
    longitude: "-97.627868",
    num_level_1: "2",
    num_level_2: "0",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "335",
    station_name: "Morgantown Ford Lincoln",
    region: "USA",
    complete_address: "501 Mary Jane Wood Circle, Morgantown WV 26501",
    street_address: "501 Mary Jane Wood Circle",
    city: "Morgantown WV 26501",
    state: "",
    zip: "",
    latitude: "39.629757",
    longitude: "-79.955946",
    num_level_1: "1",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "336",
    station_name: "Mission Viejo Jaguar Land Rover",
    region: "USA",
    complete_address: "28701 Marguerite Pkway, Mission Viejo, CA 92692",
    street_address: "28701 Marguerite Pkway",
    city: "Mission Viejo",
    state: "CA",
    zip: "92692",
    latitude: "33.54587",
    longitude: "-117.671463",
    num_level_1: "0",
    num_level_2: "5",
    num_level_3: "0",
    front_of_house: "5",
  },
  {
    id: "339",
    station_name: "Morgantown Chrysler Jeep Dodge Fiat",
    region: "USA",
    complete_address: "1733 Mileground Rd., Morgantown, WV 26505",
    street_address: "1733 Mileground Rd.",
    city: "Morgantown",
    state: "WV",
    zip: "26505",
    latitude: "39.644943",
    longitude: "-79.926822",
    num_level_1: "0",
    num_level_2: "4",
    num_level_3: "0",
    front_of_house: "4",
  },
  {
    id: "341",
    station_name: "Tampa Honda",
    region: "USA",
    complete_address: "11000 N. Florida Ave., Tampa, FL 33612",
    street_address: "11000 N. Florida Ave.",
    city: "Tampa",
    state: "FL",
    zip: "33612",
    latitude: "28.050269",
    longitude: "-82.459397",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "342",
    station_name: "Wesley Chapel Honda",
    region: "USA",
    complete_address: "27750 Wesley Chapel Blvd, Wesley Chapel, FL 33544",
    street_address: "27750 Wesley Chapel Blvd",
    city: "Wesley Chapel",
    state: "FL",
    zip: "33544",
    latitude: "28.237283",
    longitude: "-82.357543",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "346",
    station_name: "Grapevine Honda (Open Point)",
    region: "USA",
    complete_address: "2301 William D Tate Ave, Grapevine, TX 76051",
    street_address: "2301 William D Tate Ave",
    city: "Grapevine",
    state: "TX",
    zip: "76051",
    latitude: "32.914413",
    longitude: "-97.09523",
    num_level_1: "2",
    num_level_2: "0",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "349",
    station_name: "Dallas Honda",
    region: "USA",
    complete_address: "5311 Lemmon Ave, Dallas, TX 75209",
    street_address: "5311 Lemmon Ave",
    city: "Dallas",
    state: "TX",
    zip: "75209",
    latitude: "32.826075",
    longitude: "-96.822146",
    num_level_1: "1",
    num_level_2: "0",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "352",
    station_name: "Rockwall Honda",
    region: "USA",
    complete_address: "1550 E Interstate Hwy 30, Rockwall, TX 75087",
    street_address: "1550 E Interstate Hwy 30",
    city: "Rockwall",
    state: "TX",
    zip: "75087",
    latitude: "32.91421",
    longitude: "-96.4349",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "356",
    station_name: "Houston Honda",
    region: "USA",
    complete_address: "18787 Northwest Fwy, Houston, TX 77065",
    street_address: "18787 Northwest Fwy",
    city: "Houston",
    state: "TX",
    zip: "77065",
    latitude: "29.899808",
    longitude: "-95.598375",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "358",
    station_name: "Clear Lake Honda",
    region: "USA",
    complete_address: "2205 Gulf Fwy S., League City, TX 77573",
    street_address: "2205 Gulf Fwy S.",
    city: "League City",
    state: "TX",
    zip: "77573",
    latitude: "29.482135",
    longitude: "-95.102014",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "359",
    station_name: "Katy Honda",
    region: "USA",
    complete_address: "21001 Katy Fwy, Katy, TX 77450",
    street_address: "21001 Katy Fwy",
    city: "Katy",
    state: "TX",
    zip: "77450",
    latitude: "29.7806138",
    longitude: "-95.8125193",
    num_level_1: "1",
    num_level_2: "0",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "361",
    station_name: "Bend CDJR",
    region: "USA",
    complete_address: "1865 NE Hwy 20, Bend, OR 97701",
    street_address: "1865 NE Hwy 20",
    city: "Bend",
    state: "OR",
    zip: "97701",
    latitude: "44.055471",
    longitude: "-121.27625",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "362",
    station_name: "Bend Nissan",
    region: "USA",
    complete_address: "1835 US 20, Bend, OR 97701",
    street_address: "1835 US 20",
    city: "Bend",
    state: "OR",
    zip: "97701",
    latitude: "44.068272",
    longitude: "-121.302527",
    num_level_1: "0",
    num_level_2: "4",
    num_level_3: "1",
    front_of_house: "5",
  },
  {
    id: "363",
    station_name: "Thousand Oaks Subaru",
    region: "USA",
    complete_address: "3725 Automall Dr., Thousand Oaks, CA 91362",
    street_address: "3725 Automall Dr.",
    city: "Thousand Oaks",
    state: "CA",
    zip: "91362",
    latitude: "34.161651",
    longitude: "-118.830129",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "364",
    station_name: "San Francisco BMW",
    region: "USA",
    complete_address: "1675 Howard St., San Francisco CA 94103",
    street_address: "1675 Howard St.",
    city: "San Francisco CA 94103",
    state: "",
    zip: "",
    latitude: "37.77101",
    longitude: "-122.417347",
    num_level_1: "0",
    num_level_2: "20",
    num_level_3: "2",
    front_of_house: "22",
  },
  {
    id: "367",
    station_name: "Latham Ford",
    region: "USA",
    complete_address: "637 Columbia St Ext., Latham, NY 12110",
    street_address: "637 Columbia St Ext.",
    city: "Latham",
    state: "NY",
    zip: "12110",
    latitude: "42.76246",
    longitude: "-73.75386",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "371",
    station_name: "Van Nuys Toyota",
    region: "USA",
    complete_address: "5855 Van Nuys Blvd, Los Angeles, CA 91401",
    street_address: "5855 Van Nuys Blvd",
    city: "Los Angeles",
    state: "CA",
    zip: "91401",
    latitude: "34.177183",
    longitude: "-118.448713",
    num_level_1: "1",
    num_level_2: "0",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "373",
    station_name: "Van Nuys Lexus",
    region: "USA",
    complete_address: "5905 Van Nuys Blvd., Sherman Oaks, CA 91401",
    street_address: "5905 Van Nuys Blvd.",
    city: "Sherman Oaks",
    state: "CA",
    zip: "91401",
    latitude: "34.1635058",
    longitude: "-118.4487151",
    num_level_1: "3",
    num_level_2: "0",
    num_level_3: "0",
    front_of_house: "3",
  },
  {
    id: "374",
    station_name: "Van Nuys Mercedes-Benz",
    region: "USA",
    complete_address: "5400 Van Nuys, Blvd, Sherman Oaks, CA 91401",
    street_address: "5400 Van Nuys",
    city: "Blvd",
    state: "Sherman",
    zip: "Oaks",
    latitude: "34.168738",
    longitude: "-118.44871",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "2",
    front_of_house: "3",
  },
  {
    id: "375",
    station_name: "Van Nuys Hyundai",
    region: "USA",
    complete_address: "5746 Van Nuys Blvd., Sherman Oaks, CA 91401",
    street_address: "5746 Van Nuys Blvd.",
    city: "Sherman Oaks",
    state: "CA",
    zip: "91401",
    latitude: "34.175255",
    longitude: "-118.448709",
    num_level_1: "4",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "6",
  },
  {
    id: "376",
    station_name: "Mission Hills Hyundai",
    region: "USA",
    complete_address: "10240 Sepulveda Blvd., Mission Hills, CA 91345",
    street_address: "10240 Sepulveda Blvd.",
    city: "Mission Hills",
    state: "CA",
    zip: "91345",
    latitude: "34.256393",
    longitude: "-118.467248",
    num_level_1: "4",
    num_level_2: "4",
    num_level_3: "0",
    front_of_house: "8",
  },
  {
    id: "377",
    station_name: "Sherman Oaks Audi",
    region: "USA",
    complete_address: "5239 Van Nuys Blvd., Sherman Oaks, CA 91401",
    street_address: "5239 Van Nuys Blvd.",
    city: "Sherman Oaks",
    state: "CA",
    zip: "91401",
    latitude: "34.165978",
    longitude: "-118.448707",
    num_level_1: "3",
    num_level_2: "0",
    num_level_3: "0",
    front_of_house: "3",
  },
  {
    id: "378",
    station_name: "Phoenix Toyota (Bell Road Toyota)",
    region: "USA",
    complete_address: "2020 Bell Rd., Phoenix, AZ 85023",
    street_address: "2020 Bell Rd.",
    city: "Phoenix",
    state: "AZ",
    zip: "85023",
    latitude: "33.640176",
    longitude: "-112.102936",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "379",
    station_name: "Sterling BMW Mini",
    region: "USA",
    complete_address: "3000 West Coast Hwy, Newport Beach, CA 92663",
    street_address: "3000 West Coast Hwy",
    city: "Newport Beach",
    state: "CA",
    zip: "92663",
    latitude: "33.621047",
    longitude: "-117.925709",
    num_level_1: "2",
    num_level_2: "6",
    num_level_3: "4",
    front_of_house: "12",
  },
  {
    id: "381",
    station_name: "Sterling Rolls-Royce Lamborghini McLaren",
    region: "USA",
    complete_address: "21826 Pacific Boulevard, Sterling, VA 20166",
    street_address: "21826 Pacific Boulevard",
    city: "Sterling",
    state: "VA",
    zip: "20166",
    latitude: "39.01259",
    longitude: "-77.43621",
    num_level_1: "2",
    num_level_2: "6",
    num_level_3: "2",
    front_of_house: "10",
  },
  {
    id: "388",
    station_name:
      "Sanford Chrysler Dodge Jeep Ram (aka CDJR of Sanford & aka CJDR of Seminole County)",
    region: "USA",
    complete_address: "750 Towne Center Blvd, Sanford, FL 32771",
    street_address: "750 Towne Center Blvd",
    city: "Sanford",
    state: "FL",
    zip: "32771",
    latitude: "28.80501",
    longitude: "-81.3349844",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "389",
    station_name: "Orlando Land Rover",
    region: "USA",
    complete_address: "199 S. Lake Destiny Dr. Orlando, FL 32810",
    street_address: "199 S. Lake Destiny Dr. Orlando",
    city: "FL 32810",
    state: "",
    zip: "",
    latitude: "28.617054",
    longitude: "-81.388566",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "392",
    station_name: "Wesley Chapel Chevrolet",
    region: "USA",
    complete_address: "26922 Wesley Chapel Blvd, Wesley Chapel, FL 33544",
    street_address: "26922 Wesley Chapel Blvd",
    city: "Wesley Chapel",
    state: "FL",
    zip: "33544",
    latitude: "28.232881",
    longitude: "-82.371064",
    num_level_1: "2",
    num_level_2: "2",
    num_level_3: "1",
    front_of_house: "5",
  },
  {
    id: "393",
    station_name: "Wesley Chapel Mazda",
    region: "USA",
    complete_address:
      "26944 Wesley Chapel Blvd Suite 100, Wesley Chapel, FL 33544",
    street_address: "26944 Wesley Chapel Blvd Suite 100",
    city: "Wesley Chapel",
    state: "FL",
    zip: "33544",
    latitude: "28.2334252",
    longitude: "-82.3695135",
    num_level_1: "2",
    num_level_2: "0",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "394",
    station_name: "Wesley Chapel Hyundai",
    region: "USA",
    complete_address: "27000 Wesley Chapel Blvd, Wesley Chapel, FL 33544",
    street_address: "27000 Wesley Chapel Blvd",
    city: "Wesley Chapel",
    state: "FL",
    zip: "33544",
    latitude: "28.2341172",
    longitude: "-82.3691559",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "398",
    station_name: "BMW of Ann Arbor",
    region: "USA",
    complete_address: "501 Auto Mall Drive, Ann Arbor, MI  48103",
    street_address: "501 Auto Mall Drive",
    city: "Ann Arbor",
    state: "MI",
    zip: "",
    latitude: "42.285870",
    longitude: "-83.811440",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "399",
    station_name: "Suburban Chevrolet Cadillac (Ann Arbor)",
    region: "USA",
    complete_address: "3515 Jackson Road, Ann Arbor, MI  48103",
    street_address: "3515 Jackson Road",
    city: "Ann Arbor",
    state: "MI",
    zip: "",
    latitude: "42.284140",
    longitude: "-83.00960",
    num_level_1: "0",
    num_level_2: "6",
    num_level_3: "3",
    front_of_house: "9",
  },
  {
    id: "400",
    station_name: "Suburban CDJR of Ann Arbor",
    region: "USA",
    complete_address: "3900 Jackson Road, Ann Arbor,  MI 48103",
    street_address: "3900 Jackson Road",
    city: "Ann Arbor",
    state: "MI",
    zip: "48103",
    latitude: "42.285790",
    longitude: "-83.807010",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "401",
    station_name: "Mercedes Benz of Ann Arbor",
    region: "USA",
    complete_address: "570 Auto Mall Drive, Ann Arbor, MI  48103",
    street_address: "570 Auto Mall Drive",
    city: "Ann Arbor",
    state: "MI",
    zip: "",
    latitude: "42.287580",
    longitude: "-83.813520",
    num_level_1: "1",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "3",
  },
  {
    id: "402",
    station_name: "Suburban Chevrolet (Clinton)",
    region: "USA",
    complete_address: "1070 W US-12, Clinton, MI   49236",
    street_address: "1070 W US-12",
    city: "Clinton",
    state: "MI",
    zip: "",
    latitude: "42.071890",
    longitude: "-84.010920",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "403",
    station_name: "Suburban Audi Farmington Hills",
    region: "USA",
    complete_address: "37911 Grand River Ave, Farmington Hills, MI 48335",
    street_address: "37911 Grand River Ave",
    city: "Farmington Hills",
    state: "MI",
    zip: "48335",
    latitude: "42.46796794875301",
    longitude: "-83.41994594867083",
    num_level_1: "1",
    num_level_2: "1",
    num_level_3: "1",
    front_of_house: "3",
  },
  {
    id: "405",
    station_name: "Suburban CDJF of Farmington Hills",
    region: "USA",
    complete_address: "38123 W 10 Mile Road, Farmington Hills, MI  48335",
    street_address: "38123 W 10 Mile Road",
    city: "Farmington Hills",
    state: "MI",
    zip: "",
    latitude: "42.467527",
    longitude: "-83.422708",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "406",
    station_name: "Suburban Honda of Farmington Hills",
    region: "USA",
    complete_address: "2510 0 Haggerty Road, Farmington Hills, MI  48335",
    street_address: "2510 0 Haggerty Road",
    city: "Farmington Hills",
    state: "MI",
    zip: "",
    latitude: "42.476552",
    longitude: "-83.434179",
    num_level_1: "2",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "4",
  },
  {
    id: "407",
    station_name: "Suburban Mazda of Farmington Hills",
    region: "USA",
    complete_address: "37911 Grand River Ave, Farmington Hills, MI 48335",
    street_address: "37911 Grand River Ave",
    city: "Farmington Hills",
    state: "MI",
    zip: "48335",
    latitude: "42.46775330617529",
    longitude: "-83.42006191798534",
    num_level_1: "1",
    num_level_2: "0",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "408",
    station_name: "Suburban Nissan of Farmington Hills",
    region: "USA",
    complete_address: "37901 Grand River Avenue, Farmington Hills, MI  48335",
    street_address: "37901 Grand River Avenue",
    city: "Farmington Hills",
    state: "MI",
    zip: "",
    latitude: "42.445128",
    longitude: "-83.324596",
    num_level_1: "1",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "3",
  },
  {
    id: "409",
    station_name: "Suburban Porsche Farmington Hills",
    region: "USA",
    complete_address: "37911 Grand River Ave, Farmington Hills, MI 48335",
    street_address: "37911 Grand River Ave",
    city: "Farmington Hills",
    state: "MI",
    zip: "48335",
    latitude: "42.46807916266984",
    longitude: "-83.42039774867078",
    num_level_1: "2",
    num_level_2: "1",
    num_level_3: "1",
    front_of_house: "4",
  },
  {
    id: "412",
    station_name: "Suburban Volkswagen of Farmington Hills",
    region: "USA",
    complete_address: "37911 Grand River Ave, Farmington Hills, MI 48335",
    street_address: "37911 Grand River Ave",
    city: "Farmington Hills",
    state: "MI",
    zip: "48335",
    latitude: "42.468133186895784",
    longitude: "-83.41990098545",
    num_level_1: "1",
    num_level_2: "1",
    num_level_3: "1",
    front_of_house: "3",
  },
  {
    id: "414",
    station_name: "Suburban Ford of Ferndale",
    region: "USA",
    complete_address: "21600  Woodward Ave, Ferndale, MI 48220",
    street_address: "21600  Woodward Ave",
    city: "Ferndale",
    state: "MI",
    zip: "48220",
    latitude: "42.46427",
    longitude: "-83.136849",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "415",
    station_name: "Suburban Buick GMC of Ferndale",
    region: "USA",
    complete_address: "21800 Woodward Ave, Ferndale, MI  48220",
    street_address: "21800 Woodward Ave",
    city: "Ferndale",
    state: "MI",
    zip: "",
    latitude: "42.45350211074433",
    longitude: "-83.12837512511308",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "1",
    front_of_house: "1",
  },
  {
    id: "416",
    station_name: "Suburban CDJR of Garden City",
    region: "USA",
    complete_address: "32850 Ford Road, Garden City, MI  48135",
    street_address: "32850 Ford Road",
    city: "Garden City",
    state: "MI",
    zip: "",
    latitude: "42.32591",
    longitude: "-83.364162",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "418",
    station_name: "Suburban Cadillac of Plymouth",
    region: "USA",
    complete_address: "40475 Ann Arbor Road, Plymouth, MI  48170",
    street_address: "40475 Ann Arbor Road",
    city: "Plymouth",
    state: "MI",
    zip: "",
    latitude: "42.35784",
    longitude: "-83.503755",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "419",
    station_name: "Suburban Ford of Sterling Heights",
    region: "USA",
    complete_address: "40333 Van Dyke, Sterling Heights, MI  48313",
    street_address: "40333 Van Dyke",
    city: "Sterling Heights",
    state: "MI",
    zip: "",
    latitude: "42.59157",
    longitude: "-83.030573",
    num_level_1: "0",
    num_level_2: "6",
    num_level_3: "0",
    front_of_house: "6",
  },
  {
    id: "421",
    station_name: "Suburban Cadillac of Troy",
    region: "USA",
    complete_address: "1810 Maplelawn Drive, Troy, MI  48084",
    street_address: "1810 Maplelawn Drive",
    city: "Troy",
    state: "MI",
    zip: "",
    latitude: "42.55172",
    longitude: "-83.174302",
    num_level_1: "1",
    num_level_2: "0",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "422",
    station_name: "Suburban CDJR of Troy",
    region: "USA",
    complete_address: "1790 Maplelawn Drive, Troy, MI  48084",
    street_address: "1790 Maplelawn Drive",
    city: "Troy",
    state: "MI",
    zip: "",
    latitude: "42.55173",
    longitude: "-83.173725",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "423",
    station_name: "Suburban Exotics",
    region: "USA",
    complete_address: "1755 Maplelawn Drive, Troy, MI  48084",
    street_address: "1755 Maplelawn Drive",
    city: "Troy",
    state: "MI",
    zip: "",
    latitude: "42.5529156",
    longitude: "-83.1703081",
    num_level_1: "1",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "424",
    station_name: "Suburban Buick GMC of Troy",
    region: "USA",
    complete_address: "1850  Maple Road, Troy, MI   48084",
    street_address: "1850  Maple Road",
    city: "Troy",
    state: "MI",
    zip: "",
    latitude: "42.548745942630376",
    longitude: "-83.17347629469651",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "427",
    station_name: "Jaguar Land Rover Troy",
    region: "USA",
    complete_address: "1815 Maplelawn Drive, Troy, MI  48084",
    street_address: "1815 Maplelawn Drive",
    city: "Troy",
    state: "MI",
    zip: "",
    latitude: "42.55172",
    longitude: "-83.174302",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "429",
    station_name: "Suburban Nissan of Troy",
    region: "USA",
    complete_address: "1800 Maplelawn Drive, Troy MI 48084",
    street_address: "1800 Maplelawn Drive",
    city: "Troy MI 48084",
    state: "",
    zip: "",
    latitude: "42.55172",
    longitude: "-83.174302",
    num_level_1: "2",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "4",
  },
  {
    id: "430",
    station_name: "Suburban Subaru",
    region: "USA",
    complete_address: "1821 Maplelawn Drive Troy, MI 48084",
    street_address: "1821 Maplelawn Drive Troy",
    city: "MI 48084",
    state: "",
    zip: "",
    latitude: "42.5524235",
    longitude: "-83.1742971",
    num_level_1: "2",
    num_level_2: "0",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "432",
    station_name: "Suburban Volvo Cars",
    region: "USA",
    complete_address: "1795 Maplelawn Drive, Troy MI 48084",
    street_address: "1795 Maplelawn Drive",
    city: "Troy MI 48084",
    state: "",
    zip: "",
    latitude: "42.55172",
    longitude: "-83.174302",
    num_level_1: "0",
    num_level_2: "3",
    num_level_3: "2",
    front_of_house: "5",
  },
  {
    id: "433",
    station_name: "Suburban  Volkswagen of Troy",
    region: "USA",
    complete_address: "1804 Maplelawn Drive, Troy MI 48084",
    street_address: "1804 Maplelawn Drive",
    city: "Troy MI 48084",
    state: "",
    zip: "",
    latitude: "42.55172",
    longitude: "-83.174302",
    num_level_1: "2",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "3",
  },
  {
    id: "434",
    station_name: "Suburban Ford of Waterford",
    region: "USA",
    complete_address: "6975 Highland Road, Waterford, MI  ",
    street_address: "6975 Highland Road",
    city: "Waterford",
    state: "MI",
    zip: "",
    latitude: "42.65736",
    longitude: "-83.424153",
    num_level_1: "0",
    num_level_2: "5",
    num_level_3: "0",
    front_of_house: "5",
  },
  {
    id: "437",
    station_name: "Michael's Subaru of Bellevue (Center)",
    region: "USA",
    complete_address: "15150 SE Eastgate Way, Bellevue, WA 98007",
    street_address: "15150 SE Eastgate Way",
    city: "Bellevue",
    state: "WA",
    zip: "98007",
    latitude: "47.58161345323351",
    longitude: "-122.13714185644106",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "442",
    station_name: "Avondale Nissan",
    region: "USA",
    complete_address: "10305 West Papago Freeway, Avondale, AZ 85323",
    street_address: "10305 West Papago Freeway",
    city: "Avondale",
    state: "AZ",
    zip: "85323",
    latitude: "33.45986",
    longitude: "-112.28419",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "443",
    station_name: "Planet Honda",
    region: "USA",
    complete_address: "2285 Route 22 West, Union, NJ 07083",
    street_address: "2285 Route 22 West",
    city: "Union",
    state: "NJ",
    zip: "7083",
    latitude: "40.69172243324186",
    longitude: "-74.29043092544107",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "444",
    station_name: "Hyundai of Las Vegas",
    region: "USA",
    complete_address: "7150 W Sahara Ave, Las Vegas, NV 89117",
    street_address: "7150 W Sahara Ave",
    city: "Las Vegas",
    state: "NV",
    zip: "89117",
    latitude: "36.144829",
    longitude: "-115.249051",
    num_level_1: "1",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "447",
    station_name: "ABC Hyundai",
    region: "USA",
    complete_address: "6825 Redwood St, Las Vegas, NV 89118",
    street_address: "6825 Redwood St",
    city: "Las Vegas",
    state: "NV",
    zip: "89118",
    latitude: "36.064358",
    longitude: "-115.238794",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "448",
    station_name: "BMW of Sherman Oaks (Center BMW)",
    region: "USA",
    complete_address: "5201 Van Nuys Blvd, Sherman Oaks, CA 91401",
    street_address: "5201 Van Nuys Blvd",
    city: "Sherman Oaks",
    state: "CA",
    zip: "91401",
    latitude: "34.16543896213488",
    longitude: "-118.44981384652327",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "451",
    station_name: "Southwest KIA of Dallas",
    region: "USA",
    complete_address: "39650 Lyndon B Johnson Fwy, Dallas, TX 75237",
    street_address: "39650 Lyndon B Johnson Fwy",
    city: "Dallas",
    state: "TX",
    zip: "75237",
    latitude: "32.643875",
    longitude: "-96.859262",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "452",
    station_name: "Southwest KIA of Rockwall",
    region: "USA",
    complete_address: "1790 I-30 Frontage Rd, Rockwall, TX 75087",
    street_address: "1790 I-30 Frontage Rd",
    city: "Rockwall",
    state: "TX",
    zip: "75087",
    latitude: "32.916682516509255",
    longitude: "-96.42576167408636",
    num_level_1: "1",
    num_level_2: "0",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "453",
    station_name: "Southwest KIA of Mesquite",
    region: "USA",
    complete_address: "1919 Oates Dr, Mesquite, TX 75150",
    street_address: "1919 Oates Dr",
    city: "Mesquite",
    state: "TX",
    zip: "75150",
    latitude: "32.83904885354113",
    longitude: "-96.63112793360956",
    num_level_1: "0",
    num_level_2: "4",
    num_level_3: "0",
    front_of_house: "4",
  },
  {
    id: "455",
    station_name: "Southwest KIA of Frisco",
    region: "USA",
    complete_address: "10220 State Highway 121, Frisco, TX 75035",
    street_address: "10220 State Highway 121",
    city: "Frisco",
    state: "TX",
    zip: "75035",
    latitude: "33.102991",
    longitude: "-96.793727",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "456",
    station_name: "Southwest KIA of Austin",
    region: "USA",
    complete_address: "13175 N US Hwy 183 N, Austin, TX 78750",
    street_address: "13175 N US Hwy 183 N",
    city: "Austin",
    state: "TX",
    zip: "78750",
    latitude: "30.456344616425294",
    longitude: "-97.7624181597613",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "457",
    station_name: "Southwest KIA of Round Rock",
    region: "USA",
    complete_address: "600 Jeffrey Way, Round Rock, TX 78665",
    street_address: "600 Jeffrey Way",
    city: "Round Rock",
    state: "TX",
    zip: "78665",
    latitude: "30.540966510724704",
    longitude: "-97.69073177599603",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "1",
    front_of_house: "3",
  },
  {
    id: "461",
    station_name: "Subaru of Mobile (Koby Subaru)",
    region: "USA",
    complete_address: "1431 East Interstate 65 Service Rd S. Mobile, AL 36606",
    street_address: "1431 East Interstate 65 Service Rd S. Mobile",
    city: "AL 36606",
    state: "",
    zip: "",
    latitude: "30.66104",
    longitude: "-88.12546",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "462",
    station_name: "Fontana Honda",
    region: "USA",
    complete_address: "16570 S Highland Ave, Fontana, CA 92336",
    street_address: "16570 S Highland Ave",
    city: "Fontana",
    state: "CA",
    zip: "92336",
    latitude: "34.1355204",
    longitude: "-117.44314",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "464",
    station_name: "Pfaff Audi",
    region: "Canada",
    complete_address: "9088 Jane St, Vaughan, ON L4K 2M9, Canada",
    street_address: "9088 Jane St",
    city: "Vaughan",
    state: "ON",
    zip: "L4K",
    latitude: "43.82982270481876",
    longitude: "-79.53323471025223",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "1",
    front_of_house: "3",
  },
  {
    id: "469",
    station_name: "Pfaff BMW",
    region: "Canada",
    complete_address: "4505 Dixie Rd, Mississauga, ON L4W 5K3, Canada",
    street_address: "4505 Dixie Rd",
    city: "Mississauga",
    state: "ON",
    zip: "L4W",
    latitude: "43.644738092113734",
    longitude: "-79.5867119367471",
    num_level_1: "1",
    num_level_2: "3",
    num_level_3: "0",
    front_of_house: "4",
  },
  {
    id: "477",
    station_name: "HJ Pfaff Audi",
    region: "Canada",
    complete_address: "16775 Leslie St, Newmarket, ON L3Y 9A1, Canada",
    street_address: "16775 Leslie St",
    city: "Newmarket",
    state: "ON",
    zip: "L3Y",
    latitude: "44.048675015316974",
    longitude: "-79.42647563852029",
    num_level_1: "1",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "3",
  },
  {
    id: "478",
    station_name: "Pfaff Harley-Davidson",
    region: "Canada",
    complete_address: "8779 Yonge St, Richmond Hill, ON L4C 6Z1, Canada",
    street_address: "8779 Yonge St",
    city: "Richmond Hill",
    state: "ON",
    zip: "L4C",
    latitude: "43.842091404901964",
    longitude: "-79.42860640283715",
    num_level_1: "2",
    num_level_2: "0",
    num_level_3: "1",
    front_of_house: "3",
  },
  {
    id: "491",
    station_name: "Costa Mesa CDJRF",
    region: "USA",
    complete_address: "2929 Harbor Blvd Costa Mesa CA 92626",
    street_address: "2929 Harbor Blvd Costa Mesa CA 92626",
    city: "",
    state: "",
    zip: "",
    latitude: "33.67813983132012",
    longitude: "-117.919852",
    num_level_1: "4",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "5",
  },
  {
    id: "499",
    station_name: "Elder Ford of Tampa",
    region: "USA",
    complete_address: "9560 N Florida Ave, Tampa, FL 33612",
    street_address: "9560 N Florida Ave",
    city: "Tampa",
    state: "FL",
    zip: "33612",
    latitude: "28.039264982433295",
    longitude: " -82.45926396635211",
    num_level_1: "4",
    num_level_2: "0",
    num_level_3: "0",
    front_of_house: "4",
  },
  {
    id: "501",
    station_name: "Elder Ford",
    region: "USA",
    complete_address: "777 John R Rd, Troy, MI 48083",
    street_address: "777 John R Rd",
    city: "Troy",
    state: "MI",
    zip: "48083",
    latitude: "42.54200543850034",
    longitude: "-83.10832745019792",
    num_level_1: "0",
    num_level_2: "3",
    num_level_3: "0",
    front_of_house: "3",
  },
  {
    id: "502",
    station_name: "Elder Ford of Romeo",
    region: "USA",
    complete_address: "63300 Van Dyke, Washington, MI 48095",
    street_address: "63300 Van Dyke",
    city: "Washington",
    state: "MI",
    zip: "48095",
    latitude: "42.76060564381773",
    longitude: "-83.01709493300085",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "503",
    station_name: "Audi Fort Lauderdale",
    region: "L503",
    complete_address: "1200 N Federal Hwy, Fort Lauderdale, FL 33304",
    street_address: "1200 N Federal Hwy",
    city: "Fort Lauderdale",
    state: "FL",
    zip: "33304",
    latitude: "26.13979",
    longitude: "-80.119419",
    num_level_1: "0",
    num_level_2: "4",
    num_level_3: "0",
    front_of_house: "4",
  },
  {
    id: "504",
    station_name: "Audi Coral Springs",
    region: "L504",
    complete_address: "5555 N State Road 7, Coral Springs, FL 33073",
    street_address: "5555 N State Road 7",
    city: "Coral Springs",
    state: "FL",
    zip: "33073",
    latitude: "26.297351",
    longitude: "-80.203071",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "701",
    station_name: "Brunswick Toyota",
    region: "USA",
    complete_address: "1504 US-1, North Brunswick Township, NJ 08902",
    street_address: "1504 US-1",
    city: "North Brunswick Township",
    state: "NJ",
    zip: "8902",
    latitude: "40.456455",
    longitude: "-74.477898",
    num_level_1: "2",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "4",
  },
  {
    id: "702",
    station_name: "Millburn Audi",
    region: "USA",
    complete_address: "2211 Millburn Ave., Maplewood, NJ 07040",
    street_address: "2211 Millburn Ave.",
    city: "Maplewood",
    state: "NJ",
    zip: "7040",
    latitude: "40.72251",
    longitude: "-74.284338",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "703",
    station_name: "Paramus Honda",
    region: "USA",
    complete_address: "120 NJ-4, Paramus, NJ 07652",
    street_address: "120 NJ-4",
    city: "Paramus",
    state: "NJ",
    zip: "7652",
    latitude: "40.923212",
    longitude: "-74.081903",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "707",
    station_name: "Kay Honda",
    region: "USA",
    complete_address: "200 NJ-36, Eatontown, NJ 07724",
    street_address: "200 NJ-36",
    city: "Eatontown",
    state: "NJ",
    zip: "7724",
    latitude: "40.29134",
    longitude: "-74.0431",
    num_level_1: "2",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "4",
  },
  {
    id: "710",
    station_name: "Bloomfield BMW",
    region: "USA",
    complete_address: "425 Bloomfield Ave., Bloomfield, NJ 07003",
    street_address: "425 Bloomfield Ave.",
    city: "Bloomfield",
    state: "NJ",
    zip: "7003",
    latitude: "40.788876",
    longitude: "-74.195101",
    num_level_1: "1",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "3",
  },
  {
    id: "711",
    station_name: "Wappingers Falls Toyota",
    region: "USA",
    complete_address: "1349 U.S. 9, Wappinger Falls, NY 12590",
    street_address: "1349 U.S. 9",
    city: "Wappinger Falls",
    state: "NY",
    zip: "12590",
    latitude: "41.580468",
    longitude: "-73.90899",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "713",
    station_name: "Nanuet Honda",
    region: "USA",
    complete_address: "10 NY-304, Nanuet, NY 10954",
    street_address: "10 NY-304",
    city: "Nanuet",
    state: "NY",
    zip: "10954",
    latitude: "41.095314",
    longitude: "-73.997878",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "714",
    station_name: "Gardena Honda",
    region: "USA",
    complete_address: "15541 S Western Ave, Gardena, CA 90249",
    street_address: "15541 S Western Ave",
    city: "Gardena",
    state: "CA",
    zip: "90249",
    latitude: "33.889217",
    longitude: "-118.309171",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "715",
    station_name: "Oxnard Honda",
    region: "USA",
    complete_address: "1500 Ventura Blvd., Oxnard, CA 93036",
    street_address: "1500 Ventura Blvd.",
    city: "Oxnard",
    state: "CA",
    zip: "93036",
    latitude: "34.228086",
    longitude: "-119.15808",
    num_level_1: "0",
    num_level_2: "3",
    num_level_3: "0",
    front_of_house: "3",
  },
  {
    id: "717",
    station_name: "Oxnard Toyota",
    region: "USA",
    complete_address: "1631 Auto Center Dr, Oxnard, CA 93036",
    street_address: "1631 Auto Center Dr",
    city: "Oxnard",
    state: "CA",
    zip: "93036",
    latitude: "34.228193",
    longitude: "-119.153262",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "718",
    station_name: "Oxnard Audi",
    region: "USA",
    complete_address: "1600 Ventura Blvd., Oxnard, CA 93036",
    street_address: "1600 Ventura Blvd.",
    city: "Oxnard",
    state: "CA",
    zip: "93036",
    latitude: "34.22392",
    longitude: "-119.152402",
    num_level_1: "1",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "3",
  },
  {
    id: "722",
    station_name: "Temecula Honda",
    region: "USA",
    complete_address: "26755 Ynez Rd., Temecula, CA 92591",
    street_address: "26755 Ynez Rd.",
    city: "Temecula",
    state: "CA",
    zip: "92591",
    latitude: "33.514339",
    longitude: "-117.155213",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "723",
    station_name: "Temecula Chrysler Jeep Dodge",
    region: "USA",
    complete_address: "26845 Ynez Rd., Temecula, CA 92591",
    street_address: "26845 Ynez Rd.",
    city: "Temecula",
    state: "CA",
    zip: "92591",
    latitude: "33.513304",
    longitude: "-117.154377",
    num_level_1: "1",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "724",
    station_name: "Temecula Kia",
    region: "USA",
    complete_address: "26799 Ynez Rd, Temecula, CA 92591",
    street_address: "26799 Ynez Rd",
    city: "Temecula",
    state: "CA",
    zip: "92591",
    latitude: "33.513344",
    longitude: "-117.155815",
    num_level_1: "1",
    num_level_2: "0",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "725",
    station_name: "Torrance Toyota",
    region: "USA",
    complete_address: "2909 CA-1, Torrance, CA 90505",
    street_address: "2909 CA-1",
    city: "Torrance",
    state: "CA",
    zip: "90505",
    latitude: "33.795597",
    longitude: "-118.337954",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "727",
    station_name: "Mission Valley Honda",
    region: "USA",
    complete_address: "5812 Mission Gorge Rd, San Diego, A 92120",
    street_address: "5812 Mission Gorge Rd",
    city: "San Diego",
    state: "A",
    zip: "92120",
    latitude: "32.781274",
    longitude: "-117.101325",
    num_level_1: "0",
    num_level_2: "3",
    num_level_3: "0",
    front_of_house: "3",
  },
  {
    id: "739",
    station_name: "Riverside Subaru",
    region: "USA",
    complete_address: "8069 Indiana Ave., Riverside, CA 92504",
    street_address: "8069 Indiana Ave.",
    city: "Riverside",
    state: "CA",
    zip: "92504",
    latitude: "33.929368",
    longitude: "-117.414652",
    num_level_1: "1",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "740",
    station_name: "Thousand Oaks Ford",
    region: "USA",
    complete_address: "3810 Thousand Oaks Blvd, Westlake Village, CA 91362",
    street_address: "3810 Thousand Oaks Blvd",
    city: "Westlake Village",
    state: "CA",
    zip: "91362",
    latitude: "34.173679",
    longitude: "-118.842678",
    num_level_1: "1",
    num_level_2: "0",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "742",
    station_name: "Utica Ford",
    region: "USA",
    complete_address: "5712 Horatio St., Utica, NY 13502",
    street_address: "5712 Horatio St.",
    city: "Utica",
    state: "NY",
    zip: "13502",
    latitude: "43.138295",
    longitude: "-75.216872",
    num_level_1: "4",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "5",
  },
  {
    id: "743",
    station_name: "Utica BMW",
    region: "USA",
    complete_address: "5708 Horatio St., Utica NY 13502",
    street_address: "5708 Horatio St.",
    city: "Utica NY 13502",
    state: "",
    zip: "",
    latitude: "43.138121",
    longitude: "-75.217017",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "744",
    station_name: "Yorkville Subaru",
    region: "USA",
    complete_address: "5036 Commercial Dr., Yorkville, NY 13495",
    street_address: "5036 Commercial Dr.",
    city: "Yorkville",
    state: "NY",
    zip: "13495",
    latitude: "43.107642",
    longitude: "-75.295671",
    num_level_1: "2",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "3",
  },
  {
    id: "745",
    station_name: "Yorkville Chevrolet",
    region: "USA",
    complete_address: "5043 Commercial Dr., Yorkville, NY 13495",
    street_address: "5043 Commercial Dr.",
    city: "Yorkville",
    state: "NY",
    zip: "13495",
    latitude: "43.108778",
    longitude: "-75.29507",
    num_level_1: "1",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "747",
    station_name: "Yorkville CJD",
    region: "USA",
    complete_address: "5017 Commercial Dr., Yorkville, NY 13495",
    street_address: "5017 Commercial Dr.",
    city: "Yorkville",
    state: "NY",
    zip: "13495",
    latitude: "43.106885",
    longitude: "-75.296072",
    num_level_1: "1",
    num_level_2: "0",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "748",
    station_name: "Yorkville Honda",
    region: "USA",
    complete_address: "5009 Commercial Dr. Yorkville, NY 13495",
    street_address: "5009 Commercial Dr. Yorkville",
    city: "NY 13495",
    state: "",
    zip: "",
    latitude: "43.106191",
    longitude: "-75.296443",
    num_level_1: "0",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "751",
    station_name: "Troy Subaru",
    region: "USA",
    complete_address: "800 Hoosick Rd., Troy, NY 12180",
    street_address: "800 Hoosick Rd.",
    city: "Troy",
    state: "NY",
    zip: "12180",
    latitude: "42.748761",
    longitude: "-73.636237",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "756",
    station_name: "Calabasas Audi",
    region: "USA",
    complete_address: "24650 Calabasas Rd., Calabasas, CA 91302",
    street_address: "24650 Calabasas Rd.",
    city: "Calabasas",
    state: "CA",
    zip: "91302",
    latitude: "34.149589",
    longitude: "-118.663016",
    num_level_1: "0",
    num_level_2: "4",
    num_level_3: "1",
    front_of_house: "5",
  },
  {
    id: "762",
    station_name: "Orchard Park Honda",
    region: "USA",
    complete_address: "3590 Southwestern Blvd, Orchard Park, NY 14127",
    street_address: "3590 Southwestern Blvd",
    city: "Orchard Park",
    state: "NY",
    zip: "14127",
    latitude: "42.784023",
    longitude: "-78.765805",
    num_level_1: "0",
    num_level_2: "3",
    num_level_3: "0",
    front_of_house: "3",
  },
  {
    id: "764",
    station_name: "Eatontown Ford",
    region: "USA",
    complete_address: "67 NJ-36, Eatontown, NJ 07724",
    street_address: "67 NJ-36",
    city: "Eatontown",
    state: "NJ",
    zip: "7724",
    latitude: "40.29123",
    longitude: "-74.048071",
    num_level_1: "2",
    num_level_2: "0",
    num_level_3: "0",
    front_of_house: "2",
  },
  {
    id: "765",
    station_name: "Utica Body Shop",
    region: "USA",
    complete_address: "5718 Horatio St., Utica, NY 13502",
    street_address: "5718 Horatio St.",
    city: "Utica",
    state: "NY",
    zip: "13502",
    latitude: "43.138355",
    longitude: "-75.216822",
    num_level_1: "0",
    num_level_2: "0",
    num_level_3: "1",
    front_of_house: "1",
  },
  {
    id: "766",
    station_name: "Paramus Mercedes-Benz",
    region: "USA",
    complete_address: "755 NJ-17, Paramus, NJ 07652",
    street_address: "755 NJ-17",
    city: "Paramus",
    state: "NJ",
    zip: "7652",
    latitude: "40.97154",
    longitude: "-74.0810411",
    num_level_1: "6",
    num_level_2: "2",
    num_level_3: "1",
    front_of_house: "9",
  },
  {
    id: "767",
    station_name: "Ramsey Toyota",
    region: "USA",
    complete_address: "1096 NJ-17, Ramsey, NJ 07446",
    street_address: "1096 NJ-17",
    city: "Ramsey",
    state: "NJ",
    zip: "7446",
    latitude: "41.06757",
    longitude: "-74.133934",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "1",
  },
  {
    id: "769",
    station_name: "Ramsey BMW",
    region: "USA",
    complete_address: "985 NJ-17, Ramsey, NJ 07446",
    street_address: "985 NJ-17",
    city: "Ramsey",
    state: "NJ",
    zip: "7446",
    latitude: "41.065751",
    longitude: "-74.131758",
    num_level_1: "3",
    num_level_2: "2",
    num_level_3: "0",
    front_of_house: "5",
  },
  {
    id: "770",
    station_name: "Ramsey MINI",
    region: "USA",
    complete_address: "925 NJ-17, Ramsey, NJ 07446",
    street_address: "925 NJ-17",
    city: "Ramsey",
    state: "NJ",
    zip: "7446",
    latitude: "41.065322",
    longitude: "-74.131012",
    num_level_1: "3",
    num_level_2: "3",
    num_level_3: "0",
    front_of_house: "6",
  },
  {
    id: "779",
    station_name: "Hamilton Honda",
    region: "USA",
    complete_address: "655 US-130, Hamilton Township, NJ 08691",
    street_address: "655 US-130",
    city: "Hamilton Township",
    state: "NJ",
    zip: "8691",
    latitude: "40.191959",
    longitude: "-74.647789",
    num_level_1: "0",
    num_level_2: "1",
    num_level_3: "0",
    front_of_house: "1",
  },
];
