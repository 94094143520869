const SALES_TAX = {
  title: "Sales Tax",
  value: 8.25,
  valueAsFormatted: "8.25%",
};

const ELECTRICITY_RATE_IN_DOLLARS_PER_KWH = {
  title: "Electricity Rate",
  value: 0.107,
  valueAsFormatted: "$0.107/kWh",
};

const GASOLINE_PRICE_IN_DOLLARS_PER_GAL = {
  title: "Price of Gasoline",
  value: 2.405,
  valueAsFormatted: "$2.405/gal",
  link: "https://www.eia.gov/opendata/qb.php?category=240908&sdid=PET.EMM_EPM0_PTE_STX_DPG.M",
};

const ELECTRICITY_EMISSIONS_IN_LBS_PER_MWH = {
  title: "Electricity Emissions in CO2 lbs/MWh",
  value: 850,
  valueAsFormatted: "850 lbs/MWh",
};

const GASOLINE_EMISSIONS_IN_LBS_PER_GAL = {
  title: "Gasoline Emissions in CO2 lbs/gal",
  value: 18.9,
  valueAsFormatted: "18.9 lbs/gal",
  link: "https://www.eia.gov/tools/faqs/faq.php?id=307&t=11",
};

const EMISSIONS_PER_TREE_IN_LBS_OF_CO2 = {
  title: "Emissions Saved per Tree",
  value: 48,
  valueAsFormatted: "48 lbs of CO2 per tree per year",
  link: "https://www.arborday.org/trees/treefacts/",
};

const DOWN_PAYMENT = {
  title: "Down Payment",
  value: 0.1,
  valueAsFormatted: "10% of MSRP + Tax",
};

const AVERAGE_MSRP = {
  title: "Average MSRP",
  value: 37000,
  valueAsFormatted: "$37,000",
};

const AVERAGE_MAINTENANCE_COST_PER_MILE = {
  // ZappyRide analysis
  title: "Average Maintneance Cost Per Mile",
  value: 0.06,
  valueAsFormatted: "$0.06/mile",
};

const MAINTENANCE_COST_SCALING_FACTOR = {
  // Maintenance cost scaling factor: 20% (ZappyRide analysis) aka how much maintenance cost increases if MSRP doubles
  title: "Maintenance Cost Scaling Factor",
  value: 0.2,
  valueAsFormatted: "20%",
};

const ELECTRIC_COST_MAINTENANCE_REDUCTION_FACTOR = {
  // EV maintenance cost reduction: 33% (ZappyRide analysis) aka maintenance cost reduction for an EV of the same MSRP
  title: "EV maintenance cost reduction",
  value: 0.47,
  valueAsFormatted: "47%",
  link: "https://www.2degreesinstitute.org/reports/comparing_fuel_and_maintenance_costs_of_electric_and_gas_powered_vehicles_in_canada.pdf",
};

const AVERAGE_INSURANCE_COST_ANNUALLY = {
  title: "Average Insurance Cost In Texas",
  value: 1779,
  valueAsFormatted: "$1,779",
  link: "https://www.insure.com/car-insurance/car-insurance-rates.html",
};

const INSURANCE_COST_SCALING_FACTOR = {
  // Insurance cost scaling factor: 20% (ZappyRide analysis) aka how much insurance cost increases if MSRP doubles
  title: "Insurance Cost Scaling Factor",
  value: 0.2,
  valueAsFormatted: "20%",
};

const ELECTRIC_RANGE_ASSUMPTION = {
  title: "Range based on EPA Estimates",
  valueAsFormatted: "",
};

const LEVEL_TWO_CHARGING_SPEED = {
  title: "Level 2 Charger Speed",
  valueAsFormatted: "7.7 kW",
};
const FAST_CHARGING_SPEED = {
  title: "Fast Charging Speed",
  valueAsFormatted: "50 kW",
};
export {
  SALES_TAX,
  ELECTRICITY_RATE_IN_DOLLARS_PER_KWH,
  GASOLINE_PRICE_IN_DOLLARS_PER_GAL,
  ELECTRICITY_EMISSIONS_IN_LBS_PER_MWH,
  GASOLINE_EMISSIONS_IN_LBS_PER_GAL,
  EMISSIONS_PER_TREE_IN_LBS_OF_CO2,
  DOWN_PAYMENT,
  AVERAGE_MSRP,
  AVERAGE_MAINTENANCE_COST_PER_MILE,
  MAINTENANCE_COST_SCALING_FACTOR,
  ELECTRIC_COST_MAINTENANCE_REDUCTION_FACTOR,
  AVERAGE_INSURANCE_COST_ANNUALLY,
  INSURANCE_COST_SCALING_FACTOR,
  ELECTRIC_RANGE_ASSUMPTION,
  LEVEL_TWO_CHARGING_SPEED,
  FAST_CHARGING_SPEED,
};
