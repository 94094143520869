import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";

import { FormatAsDollars } from "../../../utils/Helpers/Format";

import Range from "../../shared/InputElements/Range";

const descriptionFn = val => {
  return val === 350000 ? "$350,000+" : FormatAsDollars(val);
};
const SlideHouseholdIncome = ({
  id = "household-income-slider",
  label = "Household income",
  description = descriptionFn,
  ...rest
}) => {
  const userPrefs = useContext(UserPrefsContext);

  return (
    <Range
      id={id}
      value={userPrefs.get("householdIncome")}
      description={description}
      label={label}
      rangeMin={5000}
      rangeMax={350000}
      rangeStep={5000}
      handler={e => userPrefs.set({ householdIncome: e.target.value })}
      {...rest}
    />
  );
};

export default SlideHouseholdIncome;

SlideHouseholdIncome.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.func
};
